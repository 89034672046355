import { InvoiceTotals } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { StyleSheet, Text } from "react-native";
import { common } from "../../../styles/common";
import { borderColorVariants, fontColorVariants } from "../../../styles/constants";
import { formatCurrency, t } from "../../../utils/i18n";

const styles = StyleSheet.create({
  column: {
    flexBasis: "calc(50% - 12px)",
    gap: 6,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    width: "100%",
    gap: 12,
  },
  resume: {
    fontSize: 14,
    fontWeight: "400",
    color: fontColorVariants.neutral500,
  },
  total: {
    borderTopWidth: 1,
    borderTopColor: borderColorVariants.gray400,
    marginTop: 6,
    paddingTop: 6,
  },
  totalText: {
    fontSize: 24,
    fontWeight: "700",
    color: fontColorVariants.neutral600,
  },
  totalToPayText: {
    fontSize: 16,
    fontWeight: "700",
    color: fontColorVariants.neutral600,
  },
});

type Props = {
  totals?: InvoiceTotals;
};

export const InvoiceTotal = ({ totals }: Props) => {
  const {
    vats,
    recargos,
    irpfs,
    totalDiscount,
    subtotal,
    total,
    totalVat,
    retention,
    totalTaxBase,
    totalSuplido,
    totalToPay,
  } = totals ?? {};

  return (
    <Box alignItems="end" style={styles.column}>
      <Line label={t("invoices.subtotal")} value={subtotal} />

      {totalDiscount != null && totalDiscount != 0 && (
        <Line label={t("invoices.totalDiscount")} value={-totalDiscount} />
      )}

      <Line label={t("invoices.taxBase")} value={totalTaxBase} />

      {vats?.map(vat => (
        <Line
          key={vat.label}
          label={t("invoices.ivaDetail", {
            label: vat.label,
            taxBase: formatCurrency(vat.taxBase as number, "EUR"),
          })}
          value={vat.amount}
        />
      ))}

      {vats?.length === 0 && <Line label={t("invoices.iva")} value={totalVat} />}

      {recargos?.map(vat => (
        <Line
          key={vat.label}
          label={t("invoices.recargoDetail", {
            label: vat.label,
            taxBase: formatCurrency(vat.taxBase as number, "EUR"),
          })}
          value={vat.amount}
        />
      ))}

      {irpfs?.map(irpf => (
        <Line
          key={irpf.label}
          label={t("invoices.irpfDetail", {
            label: irpf.label,
            taxBase: formatCurrency(irpf.taxBase as number, "EUR"),
          })}
          value={-Number(irpf.amount)}
        />
      ))}

      <Line label={t("invoices.totalSuplido")} value={totalSuplido} />

      <Box direction="row" style={[styles.gap, styles.total]}>
        <Text style={styles.totalText}>{t("invoices.total")}</Text>

        <Text style={[styles.totalText, common.textRight]}>
          {formatCurrency(Number(total ?? 0), "EUR")}
        </Text>
      </Box>

      {retention && (
        <>
          <Line
            label={t("invoices.retentionDetail", { percentage: retention.label })}
            value={retention.amount}
          />

          <Box direction="row" style={styles.gap}>
            <Text style={styles.totalToPayText}>{t("invoices.totalToPay")}</Text>

            <Text style={[styles.totalToPayText, common.textRight]}>
              {formatCurrency(Number(totalToPay ?? 0), "EUR")}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

const Line = ({ label, value }: { label: string; value: unknown }) =>
  value != null && (
    <Box direction="row" style={styles.gap}>
      <Text style={styles.resume}>{label}</Text>
      <Text style={[styles.resume, common.textRight]}>{formatCurrency(Number(value), "EUR")}</Text>
    </Box>
  );
