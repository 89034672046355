import { Button } from "@components/forms/Button";
import { OperationInfo, UpdateCustomerDocument, UpdateSupplierDocument } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { IndividualCountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import {
  validateEmail,
  validateNumericNullableRequired,
  validateOptional,
  validateRequired,
} from "../../../utils/validations";
import { Contact, ContactObject, FormContactState } from "../types";
import { Addresses } from "./Addresses";
import { Editor } from "./Editor";

const styles = StyleSheet.create({
  gap: {
    gap: 8,
  },
});

type RoutesInvoices = "InvoicesSalesList" | "InvoicesPurchaseList";

type Props = {
  type: string;
  contact: ContactObject;
  reload: () => void;
  onRefreshRequest: () => void;
};

export const DetailEditor = ({ type, contact, reload, onRefreshRequest }: Props) => {
  const [showModalInvoices, setShowModalInvoices] = useState<string | null>(null);
  const [routeInvoices, setRouteInvoices] = useState<RoutesInvoices>("InvoicesSalesList");

  const contactInfo = match(type)
    .returnType<Contact>()
    .with("CUSTOMER", () => contact.customer)
    .with("SUPPLIER", () => contact.supplier)
    .run();

  const baseFormDefinition = {
    type: { initialValue: "customer" },
    name: {
      initialValue: contactInfo?.name ?? "",
      validate: validateRequired,
      sanitize: (value: string) => value.trim(),
    },
    nif: { initialValue: contactInfo?.nif ?? "" },
    isRoi: { initialValue: contactInfo?.isRoi ?? false },
    country: {
      initialValue: (contactInfo?.country as IndividualCountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    locality: { initialValue: contactInfo?.locality ?? "" },
    postalCode: { initialValue: contactInfo?.postalCode ?? "" },
    phone: { initialValue: contactInfo?.phone ?? "" },
    email: {
      initialValue: contactInfo?.email ?? "",
      validate: async (value: string) => {
        if (value) {
          return await validateEmail(value);
        }
      },
    },
    address: { initialValue: contactInfo?.address ?? "" },
    paymentMethod: { initialValue: "" },
    iban: { initialValue: "", validate: validateOptional(validateIban) },
    sequential: {
      initialValue: contactInfo?.sequential ?? undefined,
      validate: validateOptional(validateNumericNullableRequired),
    },
  };

  const formDefinition = match(type)
    .with("CUSTOMER", () => ({ ...baseFormDefinition }))
    .with("SUPPLIER", () => ({
      ...baseFormDefinition,
      paymentMethod: { initialValue: contact.supplier?.paymentMethod ?? "" },
      iban: {
        initialValue: contact.supplier?.iban ?? "",
        validate: validateOptional(validateIban),
      },
    }))
    .run();

  const {
    Field,
    submitForm,
    setFieldValue,
    getFieldState,
    FieldsListener,
    setFieldError,
    listenFields,
  } = useForm<FormContactState>(formDefinition);

  const [fieldsUpdated, setFieldsUpdated] = useState(false);

  useEffect(() => {
    const listener = listenFields(["nif", "name", "address"], () => {
      setFieldsUpdated(true);
    });

    return () => {
      listener();
    };
  }, [listenFields]);

  const [, crmCustomer] = useUrqlMutation(UpdateCustomerDocument);
  const [, crmSupplier] = useUrqlMutation(UpdateSupplierDocument);

  const handlerErrors = (data: OperationInfo) => {
    for (const error of data.messages) {
      setFieldError(error?.field as keyof FormContactState, error?.message ?? "");
    }
    return data;
  };

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const onPressUpdate = () => {
    submitForm(values => {
      const commonInput = {
        id: contactInfo?.id,
        name: values.name ?? "",
        nif: values.nif,
        isRoi: values.isRoi,
        address: values.address,
        locality: values.locality,
        postalCode: values.postalCode,
        country: values.country ?? contactInfo?.country ?? "ESP",
        phone: values.phone,
        email: values.email,
        sequential: values.sequential,
      };

      match(type.toLowerCase())
        .with("customer", () => {
          crmCustomer({
            input: { ...commonInput },
          })
            .mapOk(data => {
              match(data.updateCustomer)
                .with({ __typename: "OperationInfo" }, handlerErrors)
                .with({ __typename: "CustomerUpdateResponse" }, ({ customer, salesInvoices }) => {
                  if (fieldsUpdated && customer.nif != null && salesInvoices > 0) {
                    setRouteInvoices("InvoicesSalesList");
                    setShowModalInvoices(customer.nif);
                    setFieldsUpdated(false);
                    return;
                  }
                  onRefreshRequest();
                });
            })
            .tapError(tapError);
        })
        .with("supplier", () => {
          crmSupplier({
            input: {
              ...commonInput,
              iban: values.iban,
              paymentMethod: values.paymentMethod,
            },
          })
            .mapOk(data => {
              match(data.updateSupplier)
                .with({ __typename: "OperationInfo" }, handlerErrors)
                .with(
                  { __typename: "SupplierUpdateResponse" },
                  ({ supplier, purchaseInvoices }) => {
                    if (fieldsUpdated && supplier.nif != null && purchaseInvoices > 0) {
                      setRouteInvoices("InvoicesPurchaseList");
                      setShowModalInvoices(supplier.nif);
                      setFieldsUpdated(false);
                      return;
                    }
                    onRefreshRequest();
                  },
                );
            })
            .tapError(tapError);
        });
    });
  };

  const handleViewInvoices = () => {
    Router.push(routeInvoices, { search: showModalInvoices ?? "" });
  };

  const handleCloseModal = () => {
    setShowModalInvoices(null);
    onRefreshRequest();
  };

  const isCustomer = contactInfo?.__typename === "Customer";

  return (
    <ResponsiveContainer breakpoint={breakpoints.small}>
      {({ large }) => (
        <>
          <LakeModal visible={showModalInvoices != null}>
            <Text>{t("contact.modalUpdateInvoices")}</Text>
            <Space height={24} />

            <Box direction="row" justifyContent="end" style={styles.gap}>
              <Button onPress={handleCloseModal} mode="secondary">
                {t("common.closeButton")}
              </Button>

              <Button onPress={handleViewInvoices}>{t("contact.viewInvoices")}</Button>
            </Box>
          </LakeModal>

          {match(type.toLowerCase())
            .with("customer", () => (
              <>
                <Editor
                  contactType={type}
                  contactInfo={contactInfo}
                  large={large}
                  Field={Field}
                  setFieldValue={setFieldValue}
                  getFieldState={getFieldState}
                  FieldsListener={FieldsListener}
                  onSave={onPressUpdate}
                  showAddress={false}
                >
                  <>
                    {isCustomer && (
                      <Addresses customer={contactInfo} type="fiscal" reload={reload} />
                    )}

                    <Space height={16} />

                    {isCustomer && (
                      <Addresses customer={contactInfo} type="delivery" reload={reload} />
                    )}

                    <Space height={16} />
                  </>
                </Editor>
              </>
            ))
            .with("supplier", () => (
              <Editor
                contactType={type}
                contactInfo={contactInfo}
                large={large}
                Field={Field}
                setFieldValue={setFieldValue}
                getFieldState={getFieldState}
                FieldsListener={FieldsListener}
                onSave={onPressUpdate}
              />
            ))
            .otherwise(() => null)}
        </>
      )}
    </ResponsiveContainer>
  );
};
