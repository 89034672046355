import { DecimalInput } from "@components/DecimalInput";
import { Tile } from "@components/Tile";
import { Button } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import {
  Company,
  CompanyDocument,
  CreateEmployeeDocument,
  EarnExtraEmployee,
  EmployeeRelay,
  EmployeesStaticDocument,
  OperationInfo,
  Static,
  UpdateEmployeeDocument,
} from "@graphql/crm";
import { Lazy } from "@swan-io/boxed";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { UploadArea } from "@swan-io/shared-business/src/components/UploadArea";
import {
  IndividualCountryCCA3,
  allCountries,
} from "@swan-io/shared-business/src/constants/countries";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useCallback, useEffect, useState } from "react";
import ReactCrop, { PercentCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { CombinedError } from "urql";
import { useNestedForm } from "../../../components/NestedForm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import {
  extractQueryData,
  validateNumericNullableRequired,
  validateOptional,
  validateRequired,
} from "../../../utils/validations";
import { EmployeeState } from "../types";
import { EarnExtra } from "./EarnExtra";

const styles = StyleSheet.create({
  grid: {
    display: "grid",
    alignItems: "flex-start",
    gridTemplateColumns: "1fr 1fr",
    gap: 12,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "75%",
    alignSelf: "flex-end",
    gap: 48,
  },
  newItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  tileContainer: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: backgroundColorVariants.neutral100,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    boxShadow: "none",
    gap: 8,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  tabs: {
    zIndex: 1,
  },
  cropContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  photoPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});

const MAX_SIZE_MB = 0.3;

const countryItems = Lazy(() => allCountries.filter(cca3 => cca3 !== "USA"));

type Props = {
  data?: EmployeeRelay;
  onPressClose?: () => void;
  showCancel: boolean;
};

export const EmployeeForm = ({ data, onPressClose, showCancel }: Props) => {
  const earnExtraInit = (type: string) => {
    if (data?.earnExtra === undefined) {
      return [];
    }
    return data.earnExtra
      ?.filter(row => row.type === type)
      .map(row => {
        const { __typename, ...rest } = row;
        return rest;
      });
  };

  const {
    Field,
    FieldsListener,
    submitForm,
    resetForm,
    getFieldState,
    setFieldValue,
    setFieldError,
    listenFields,
  } = useForm<EmployeeState>({
    name: { initialValue: data?.name ?? "", validate: validateRequired },
    country: {
      initialValue: (data?.country as IndividualCountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    nif: { initialValue: data?.nif ?? "" },
    address: { initialValue: data?.address ?? "" },
    postalCode: { initialValue: data?.postalCode ?? "" },
    phone: { initialValue: data?.phone ?? "" },
    email: { initialValue: data?.email ?? "" },
    paymentMethod: { initialValue: data?.paymentMethod ?? "" },
    iban: { initialValue: data?.iban ?? "", validate: validateOptional(validateIban) },
    contract: { initialValue: data?.contract ?? "" },
    contractType: { initialValue: data?.contractType ?? "" },
    contractMode: { initialValue: data?.contractMode ?? "" },
    category: { initialValue: data?.category ?? "" },
    startDate: {
      initialValue: data?.startDate != null ? encodeDateISO(data.startDate) : getToday(),
      validate: validateRequired,
    },
    endDate: { initialValue: data?.endDate != null ? encodeDateISO(data.endDate) : undefined },
    variationDate: {
      initialValue: data?.variationDate != null ? encodeDateISO(data.variationDate) : undefined,
    },
    numSs: { initialValue: data?.numSs ?? "" },
    percentIrpf: { initialValue: (data?.percentIrpf as string) ?? "0" },
    baseSalary: { initialValue: (data?.baseSalary as string) ?? "0" },
    payments: { initialValue: (data?.payments as string) ?? "" },
    earnExtraSupplements: {
      initialValue: earnExtraInit("salary_complement") as EarnExtraEmployee[],
    },
    earnExtraOtherNonSalaryCompensations: {
      initialValue: earnExtraInit("other_non_salary_compensations") as EarnExtraEmployee[],
    },
    earnExtraInKindSalary: {
      initialValue: earnExtraInit("in_kind_salary") as EarnExtraEmployee[],
    },
    photoFile: { initialValue: null },
    role: { initialValue: data?.role ?? "" },
    sequential: {
      initialValue: data?.sequential ?? undefined,
      validate: validateOptional(validateNumericNullableRequired),
    },
  });

  const [contractsFiltered, setContracts] = useState<Static[]>([]);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [crop, setCrop] = useState<PercentCrop>();
  const [showCrop, setShowCrop] = useState(false);

  const composeEarnExtra = () => {
    const get = (type: string) => {
      const value = getFieldState(type as keyof EmployeeState).value as EarnExtraEmployee[];
      return (
        value?.map(earnExtra => {
          const { id, ...rest } = earnExtra;
          return rest;
        }) ?? []
      );
    };
    return get("earnExtraSupplements")
      .concat(get("earnExtraOtherNonSalaryCompensations"))
      .concat(get("earnExtraInKindSalary"));
  };

  const getCroppedImg = useCallback(async (imageUrl: string, crop: PercentCrop) => {
    return new Promise<File>((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const cropX = (crop.x / 100) * image.naturalWidth;
        const cropY = (crop.y / 100) * image.naturalHeight;
        const cropWidth = (crop.width / 100) * image.naturalWidth;
        const cropHeight = (crop.height / 100) * image.naturalHeight;

        const size = Math.min(cropWidth, cropHeight);
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          reject(new Error("No 2d context"));
          return;
        }

        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
        ctx.clip();

        ctx.drawImage(
          image,
          cropX + (cropWidth - size) / 2,
          cropY + (cropHeight - size) / 2,
          size,
          size,
          0,
          0,
          size,
          size,
        );

        canvas.toBlob(
          blob => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            const file = new File([blob], "cropped-image.png", { type: "image/png" });
            resolve(file);
          },
          "image/png",
          1,
        );
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      image.src = imageUrl;
    });
  }, []);

  const handleCropConfirm = useCallback(async () => {
    if (crop && logoPreview != null) {
      try {
        const croppedImageFile = await getCroppedImg(logoPreview, crop);
        setFieldValue("photoFile", croppedImageFile);
        const newPreviewUrl = URL.createObjectURL(croppedImageFile);
        setLogoPreview(newPreviewUrl);
        setShowCrop(false);
      } catch (e) {
        showToast({ variant: "error", title: "Error cropping image", autoClose: true });
      }
    }
  }, [crop, logoPreview, getCroppedImg, setFieldValue]);

  const composeEarnExtraUpdate = () => {
    const get = (type: string) => {
      const value = getFieldState(type as keyof EmployeeState).value as EarnExtraEmployee[];
      return (
        value?.map(earnExtra => {
          if (earnExtra.id.length === 36) {
            const { id, ...rest } = earnExtra;
            return rest;
          }
          return earnExtra;
        }) ?? []
      );
    };
    return get("earnExtraSupplements")
      .concat(get("earnExtraOtherNonSalaryCompensations"))
      .concat(get("earnExtraInKindSalary"));
  };

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const [, create] = useUrqlMutation(CreateEmployeeDocument);
  const [, update] = useUrqlMutation(UpdateEmployeeDocument);

  const { data: companyQuery } = useUrqlQuery({ query: CompanyDocument }, []);
  const company = extractQueryData(companyQuery, "value.value.company") as Company;

  const { data: query } = useUrqlQuery({ query: EmployeesStaticDocument }, []);

  const paymentMethods = extractQueryData(
    query,
    "value.value.employeesStatic.paymentMethods",
  ) as Static[];
  const contracts = extractQueryData(query, "value.value.employeesStatic.contracts") as Static[];
  const contractTypes = extractQueryData(
    query,
    "value.value.employeesStatic.contractTypes",
  ) as Static[];
  const contractModes = extractQueryData(
    query,
    "value.value.employeesStatic.contractModes",
  ) as Static[];
  const categories = extractQueryData(query, "value.value.employeesStatic.categories") as Static[];
  const payments = extractQueryData(query, "value.value.employeesStatic.payments") as Static[];
  const sequentialSize = company?.sequentialSize ?? 0;

  useEffect(() => {
    listenFields(["contractMode", "contractType"], () => {
      setFieldValue("contract", "");
      const contactsFiltered = contracts?.filter(c =>
        c.value.startsWith(
          `${getFieldState("contractMode").value}-${getFieldState("contractType").value}`,
        ),
      );
      setContracts(contactsFiltered);
    });
  }, [listenFields, contracts]);

  const otherwise = () => {
    onPressClose?.();
  };

  const tapError = (error: Error | CombinedError) => {
    showToast({ variant: "error", title: error.message, autoClose: true });
  };

  const onSave = () => {
    submitForm(values => {
      const input = {
        companyId: parseInt(company?.id),
        name: values.name ?? "",
        country: values.country ?? "",
        nif: values.nif,
        address: values.address,
        postalCode: values.postalCode,
        phone: values.phone,
        email: values.email,
        paymentMethod: values.paymentMethod,
        contract: values.contract,
        contractType: values.contractType,
        contractMode: values.contractMode,
        categoryId: values.category,
        startDate: values.startDate != null ? encodeDate(values.startDate) || null : null,
        endDate: values.endDate != null ? encodeDate(values.endDate) || null : null,
        variationDate:
          values.variationDate != null ? encodeDate(values.variationDate) || null : null,
        numSs: values.numSs,
        percentIrpf: values.percentIrpf ?? 0,
        baseSalary: values.baseSalary ?? 0,
        payments: values.payments,
        iban: values.iban,
        photoFile: values.photoFile,
        sequential: values.sequential,
      };
      if (data === undefined) {
        create({
          input: {
            ...input,
            earnExtra: composeEarnExtra(),
          },
        })
          .mapOk(data => {
            match(data.createEmployee)
              .with({ __typename: "OperationInfo" }, () => {
                for (const error of (data.createEmployee as OperationInfo).messages) {
                  if (error.field != null) {
                    setFieldError(error?.field as keyof EmployeeState, error?.message ?? "");
                  } else {
                    showToast({
                      variant: "error",
                      title: error.message,
                      autoClose: true,
                    });
                  }
                }
              })
              .otherwise(otherwise);
          })
          .tapError(tapError);
      } else {
        update({
          input: {
            id: data.id as string,
            ...input,
            earnExtra: composeEarnExtraUpdate(),
          },
        })
          .mapOk(data => {
            match(data.updateEmployee)
              .with({ __typename: "OperationInfo" }, () => {
                for (const error of (data.updateEmployee as OperationInfo).messages) {
                  if (error.field != null) {
                    setFieldError(error?.field as keyof EmployeeState, error?.message ?? "");
                  } else {
                    showToast({
                      variant: "error",
                      title: error.message,
                      autoClose: true,
                    });
                  }
                }
              })
              .otherwise(otherwise);
          })
          .tapError(tapError);
      }
    });
  };

  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [activeTabId, setActiveTabId] = useState("generalData");
  const [submit, setSubmit] = useState(false);
  const { onSubmit, setMainForm } = useNestedForm();

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  const onClick = () => {
    setActiveTabId("generalData");
    setSubmit(true);
    setMainForm(() => onSave);
  };

  const getNewEarnExtra = (type: string) => ({
    id: crypto.randomUUID(),
    label: "",
    amount: "0",
    type,
    impactEarning: false,
    impactIrpf: false,
  });

  const handleNewEarnExtra = (type: string) => {
    const newEarnExtra = getNewEarnExtra(type);
    let fieldName = "earnExtraSupplements" as keyof EmployeeState;
    if (type === "other_non_salary_compensations") {
      fieldName = "earnExtraOtherNonSalaryCompensations";
    } else if (type === "in_kind_salary") {
      fieldName = "earnExtraInKindSalary";
    }
    let { value } = getFieldState(fieldName);
    if (value === undefined) {
      value = [];
    }
    setFieldValue(fieldName, [
      ...(value as EarnExtraEmployee[]),
      newEarnExtra as EarnExtraEmployee,
    ]);
  };

  return (
    <>
      <View style={styles.tabs}>
        <TabView
          tabs={[
            {
              id: "generalData",
              label: t("hr.generalData"),
            },
            {
              id: "salarySupplements",
              label: t("hr.salarySupplements"),
            },
            {
              id: "inKindSalary",
              label: t("hr.inKindSalary"),
            },
            {
              id: "otherNonSalaryCompensations",
              label: t("hr.otherNonSalaryCompensations"),
            },
          ]}
          otherLabel=""
          activeTabId={activeTabId}
          onChange={setActiveTabId}
        />
      </View>

      <Space height={16} />

      {match(activeTabId)
        .with("generalData", () => (
          <>
            <Box style={styles.grid}>
              <Field name="photoFile">
                {({ onChange, error }) => (
                  <>
                    <UploadArea
                      accept={["image/png", "image/jpeg", "image/jpg"]}
                      onDropAccepted={files => {
                        const file = files[0];
                        onChange(file);
                        if (file && file.type.startsWith("image/")) {
                          const newPreviewUrl = URL.createObjectURL(file);
                          if (logoPreview != null) {
                            URL.revokeObjectURL(logoPreview);
                          }
                          setLogoPreview(newPreviewUrl);
                          setShowCrop(true);
                        }
                      }}
                      onDropRejected={files => {
                        if (files.length > 0 && files[0]?.file) {
                          const message = match(files[0].errors?.[0]?.code)
                            .with("file-invalid-type", () =>
                              t("upload.invalidType", { supportedTypes: "PNG, JPG" }),
                            )
                            .with("file-too-large", () =>
                              t("upload.hugeFile", {
                                maxSizeMB: MAX_SIZE_MB,
                              }),
                            )
                            .otherwise(() => files[0]?.errors?.[0]?.message);
                          showToast({
                            variant: "error",
                            title: message as string,
                            autoClose: true,
                          });
                        }
                      }}
                      icon="arrow-upload-regular"
                      description={t("upload.description", {
                        supportedTypes: "PNG, JPG",
                        maxSizeMB: MAX_SIZE_MB,
                      })}
                      maxSize={MAX_SIZE_MB * 1000 * 1000}
                      error={error}
                    />

                    {(logoPreview != null || data?.photo != null) && (
                      <Box style={styles.photoPreview}>
                        <AutoWidthImage
                          sourceUri={(logoPreview ?? data?.photo) as string}
                          height={100}
                          width={100}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="name">
                {Input({
                  label: t("hr.name"),
                  required: true,
                })}
              </Field>

              <Field name="nif">
                {Input({
                  label: t("contact.nif"),
                })}
              </Field>
            </Box>

            <FieldsListener names={["country"]}>
              {({ country }) => (
                <Field name="address">
                  {({ value, onChange, error }) => (
                    <LakeLabel
                      label={t("contact.address")}
                      render={id => (
                        <PlacekitAddressSearchInput
                          shouldDisplaySuggestions={true}
                          id={id}
                          apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                          country={country.value}
                          value={value}
                          onValueChange={onChange}
                          onSuggestion={suggestion => {
                            setFieldValue("address", suggestion.completeAddress);
                          }}
                          language="es"
                          placeholder={t("contact.address.placeHolder")}
                          emptyResultText={t("common.empty")}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>
              )}
            </FieldsListener>

            <Box style={styles.grid}>
              <Field name="postalCode">
                {Input({
                  label: t("contact.postalCode"),
                })}
              </Field>

              <Field name="country">
                {({ value, onChange, ref, error }) => (
                  <LakeLabel
                    label={t("common.country")}
                    render={id => (
                      <CountryPicker
                        id={id}
                        ref={ref}
                        value={value}
                        error={error}
                        countries={countryItems.get()}
                        onValueChange={onChange}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>

            <Box style={styles.grid}>
              <Field name="phone">
                {Input({
                  label: t("common.phone"),
                })}
              </Field>

              <Field name="email">
                {Input({
                  label: t("common.email"),
                })}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="paymentMethod">
                {({ value, onChange, error, ref }) => (
                  <LakeLabel
                    label={t("contact.paymentMethod")}
                    render={id => (
                      <LakeSelect
                        id={id}
                        ref={ref}
                        value={value}
                        hideErrors={error === undefined}
                        error={error}
                        items={paymentMethods ?? []}
                        onValueChange={value => {
                          onChange(value);
                          setPaymentMethodType(value);
                        }}
                      />
                    )}
                  />
                )}
              </Field>

              <LakeLabel
                label={t("contact.iban")}
                render={id => (
                  <Field name="iban">
                    {({ value, onChange, onBlur, error, validating, ref }) => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={printIbanFormat(value ?? "")}
                        validating={validating}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        disabled={!["transferencia", "domiciliacion"].includes(paymentMethodType)}
                      />
                    )}
                  </Field>
                )}
              />
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="startDate">
                {({ value, onChange, error }) => (
                  <DatePicker
                    label={t("hr.start_date") + "*"}
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    error={error}
                  />
                )}
              </Field>

              <Field name="endDate">
                {({ value, onChange, error }) => (
                  <DatePicker
                    label={t("hr.end_date")}
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    error={error}
                  />
                )}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="numSs">
                {Input({
                  label: t("hr.numSs"),
                })}
              </Field>

              <Field name="category">
                {Select({
                  label: t("hr.category"),
                  items: categories,
                  hasEmptyRow: true,
                })}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="contractMode">
                {Select({
                  label: t("hr.contractMode"),
                  items: contractModes,
                  hasEmptyRow: true,
                })}
              </Field>

              <Field name="contractType">
                {Select({
                  label: t("hr.contractType"),
                  items: contractTypes,
                  hasEmptyRow: true,
                  disabled: getFieldState("contractMode").value === "",
                })}
              </Field>
            </Box>

            <Space height={8} />

            <Box>
              <Field name="contract">
                {Select({
                  label: t("hr.contract"),
                  items: contractsFiltered,
                  hasEmptyRow: true,
                  disabled: getFieldState("contractType").value === "",
                })}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="percentIrpf">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("hr.percentIrpf")}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={value}
                        error={error}
                        onChangeDecimal={onChange}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="baseSalary">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("hr.baseSalaryMonthly")}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={value}
                        error={error}
                        onChangeDecimal={onChange}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="payments">
                {Select({
                  label: t("hr.payments"),
                  items: payments,
                  hasEmptyRow: true,
                })}
              </Field>
            </Box>

            <Space height={8} />

            <Box style={styles.grid}>
              <Field name="sequential">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={t("common.subaccount")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value?.toString().padStart(sequentialSize, "0") ?? ""}
                        onChangeText={value =>
                          isNaN(parseInt(value)) ? onChange(undefined) : onChange(parseInt(value))
                        }
                        inputMode="numeric"
                        error={error}
                        hideErrors={error == undefined}
                        disabled={data?.sequential != null && data?.sequential > 0}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>
          </>
        ))
        .with("salarySupplements", () => (
          <>
            <Tile style={styles.tileContainer}>
              <LakeText>{t("hr.salarySupplementsDescription")}</LakeText>
            </Tile>

            <Space height={16} />

            <Field name="earnExtraSupplements">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "salary_complement")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraSupplements");
                          setFieldValue(
                            "earnExtraSupplements",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraSupplements");
                          setFieldValue(
                            "earnExtraSupplements",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraEmployee[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            <Pressable
              style={styles.newItem}
              onPress={() => handleNewEarnExtra("salary_complement")}
            >
              <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
              <Text>{t("invoices.addNewItem")}</Text>
            </Pressable>
          </>
        ))
        .with("inKindSalary", () => (
          <>
            <Tile style={styles.tileContainer}>
              <LakeText>{t("hr.inKindSalary")}</LakeText>
            </Tile>

            <Space height={16} />

            <Field name="earnExtraInKindSalary">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "in_kind_salary")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraInKindSalary");
                          setFieldValue(
                            "earnExtraInKindSalary",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraInKindSalary");
                          setFieldValue(
                            "earnExtraInKindSalary",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraEmployee[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            <Pressable style={styles.newItem} onPress={() => handleNewEarnExtra("in_kind_salary")}>
              <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
              <Text>{t("invoices.addNewItem")}</Text>
            </Pressable>
          </>
        ))
        .with("otherNonSalaryCompensations", () => (
          <>
            <Tile style={styles.tileContainer}>
              <LakeText>{t("hr.otherNonSalaryCompensations")}</LakeText>
            </Tile>

            <Space height={16} />

            <Field name="earnExtraOtherNonSalaryCompensations">
              {({ value, error }) => (
                <Box>
                  {value
                    ?.filter(row => row.type === "other_non_salary_compensations")
                    .map(earnExtra => (
                      <EarnExtra
                        key={earnExtra.id}
                        earnExtra={earnExtra}
                        onDelete={() => {
                          const { value } = getFieldState("earnExtraOtherNonSalaryCompensations");
                          setFieldValue(
                            "earnExtraOtherNonSalaryCompensations",
                            value?.filter(c => c.id !== earnExtra.id),
                          );
                        }}
                        onChange={earnExtra => {
                          const { value } = getFieldState("earnExtraOtherNonSalaryCompensations");
                          setFieldValue(
                            "earnExtraOtherNonSalaryCompensations",
                            value?.map(c =>
                              c.id === earnExtra.id ? { ...c, ...earnExtra } : c,
                            ) as EarnExtraEmployee[],
                          );
                        }}
                      />
                    ))}

                  <Text style={styles.red}>{error}</Text>
                </Box>
              )}
            </Field>

            <Pressable
              style={styles.newItem}
              onPress={() => handleNewEarnExtra("other_non_salary_compensations")}
            >
              <Icon name="add-circle-regular" size={20} color={fontColorVariants.neutral800} />
              <Text>{t("invoices.addNewItem")}</Text>
            </Pressable>
          </>
        ))
        .otherwise(() => null)}

      <Space height={24} />

      <Box style={showCancel === true && styles.gap} alignItems="end">
        {showCancel === true ? (
          <Button onPress={onPressClose} mode="secondary">
            {t("common.cancel")}
          </Button>
        ) : null}

        <Button onPress={onClick} icon={<SaveIcon />}>
          {t("common.save")}
        </Button>
      </Box>

      <LakeModal visible={showCrop} onPressClose={onPressClose} maxWidth={660}>
        <Box style={styles.cropContainer}>
          <ReactCrop
            crop={crop}
            onChange={(newCrop, percentCrop) => {
              setCrop(percentCrop);
            }}
            aspect={1}
            circularCrop={true}
          >
            <img src={logoPreview as string} />
          </ReactCrop>

          <Space height={16} />

          <Box direction="row">
            <Button
              onPress={() => {
                void handleCropConfirm();
              }}
            >
              {t("common.confirm")}
            </Button>
          </Box>
        </Box>
      </LakeModal>
    </>
  );
};
