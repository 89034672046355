import Chart, { ChartDataset } from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import { useEffect, useRef, useState } from "react";
import { t } from "../../utils/i18n";

type Props = {
  labels: string[];
  datasets: ChartDataset[];
  stacked?: boolean;
  showAverage?: boolean;
};

export const Bars = ({
  labels = [],
  datasets = [],
  stacked = false,
  showAverage = false,
}: Props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    Chart.register(annotationPlugin);
    const chart = new Chart(chartRef.current, {
      type: "bar",
      data: {
        labels,
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked,
            ticks: {
              color: "white",
              font: {
                weight: 600,
              },
            },
            grid: {
              color: "#48484A",
            },
          },
          y: {
            stacked,
            grid: {
              color: "#48484A",
            },
          },
        },
      },
    });
    setChart(chart);
  }, []);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }
    if (chart != null && labels.length > 0) {
      const average = [];
      for (let i = 0; i < labels.length; i++) {
        const value1 = (datasets?.[0]?.data[i] as number) ?? 0;
        const value2 = (datasets?.[1]?.data[i] as number) ?? 0;
        average.push((value1 + value2) / 2);
      }

      const data0 = datasets?.[0]?.data ?? [];
      const data1 = datasets?.[1]?.data ?? [];

      const data: ChartDataset[] = [];
      if (data0.length > 0 && data1.length > 0) {
        data.push({
          type: "line",
          label: t("common.average"),
          data: average,
          borderColor: "white",
          pointBorderWidth: 5,
        });
      }
      if (data0.length > 0) {
        data.push({
          label: t("common.income"),
          data: data0,
          backgroundColor: getGradients()[0],
        });
      }
      if (data1.length > 0) {
        data.push({
          label: t("common.expenses"),
          data: data1,
          backgroundColor: getGradients()[1],
        });
      }

      chart.data = {
        labels,
        datasets: data,
      };
      chart.options.plugins = {
        legend: {
          align: "end",
          labels: {
            boxWidth: 12,
            boxHeight: 12,
            color: "#fff",
          },
        },
        annotation: showAverage
          ? {
              annotations: {
                line1: {
                  type: "line",
                  yMin: averageSum(datasets),
                  yMax: averageSum(datasets),
                  borderColor: "#30D158",
                  borderWidth: 1,
                  borderDash: [5, 5],
                },
              },
            }
          : undefined,
      };
      chart.update();
    }
  }, [labels, datasets]);

  const getGradients = () => {
    if (chartRef.current == null) {
      return [];
    }
    const ctx = chartRef.current.getContext("2d");
    if (ctx == null) {
      return [];
    }

    const height = ctx.canvas.height;

    const gradient1 = ctx.createLinearGradient(0, 0, 0, height);
    gradient1.addColorStop(0, "#95CE37");
    gradient1.addColorStop(0.5, "#819D52");
    gradient1.addColorStop(1, "#000");

    const gradient2 = ctx.createLinearGradient(0, 0, 0, height);
    gradient2.addColorStop(0.1, "#FF0F64"); // neccesary for legend
    gradient2.addColorStop(0.1, "#FF5A0F");
    gradient2.addColorStop(0.5, "#FF0F64");
    gradient2.addColorStop(1, "#000");

    return [gradient1, gradient2];
  };

  const getAverage = (datasets: ChartDataset[]) => {
    const average = [];
    for (let i = 0; i < labels.length; i++) {
      const value1 = (datasets?.[0]?.data[i] as number) ?? 0;
      const value2 = (datasets?.[1]?.data[i] as number) ?? 0;
      average.push((value1 + value2) / 2);
    }
    return average;
  };

  const averageSum = (datasets: ChartDataset[]) => {
    const average = getAverage(datasets);
    return average.reduce((acc, value) => acc + value, 0) / average.length;
  };

  return <canvas ref={chartRef}></canvas>;
};
