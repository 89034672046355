import { useNestedForm } from "@components/NestedForm";
import { Button } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import { CompanyRepresentative } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { StyleSheet } from "react-native";
import { useForm } from "react-ux-form";
import { borderColorVariants } from "../../../styles/constants";
import { encodeDateISO } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { validateRequired } from "../../../utils/validations";

const styles = StyleSheet.create({
  row: {
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
    paddingBottom: 8,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr .5fr",
    gap: 24,
  },
});

type Props = {
  value?: CompanyRepresentative;
  onChange: (value: CompanyRepresentative) => void;
  onDelete: () => void;
};

type CompanyRepresentative2 = CompanyRepresentative & {
  notary: string;
  powerDate?: string;
  protocol: string;
};

export const Representative = ({ value, onChange, onDelete }: Props) => {
  const { Field, submitForm, listenFields } = useForm<CompanyRepresentative2>({
    __typename: { initialValue: "CompanyRepresentative" },
    id: { initialValue: value?.id ?? crypto.randomUUID() },
    name: { initialValue: value?.name ?? "", validate: validateRequired },
    nif: { initialValue: value?.nif ?? "", validate: validateRequired },
    notary: { initialValue: value?.notary ?? "" },
    powerDate: {
      initialValue: value?.powerDate != null ? encodeDateISO(value?.powerDate) : undefined,
    },
    protocol: { initialValue: value?.protocol ?? "" },
  });

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as CompanyRepresentative),
        () => {
          addError("error");
        },
      );
    });

    const removeListener = listenFields(["name", "nif", "notary", "powerDate", "protocol"], () =>
      submitForm(values => onChange(values as CompanyRepresentative)),
    );

    return () => removeListener();
  }, []);

  return (
    <Box style={styles.row}>
      <Field name="id">{() => <></>}</Field>

      <Box alignItems="start" style={styles.gap}>
        <Field name="name">
          {Input({
            label: t("company.fullName"),
          })}
        </Field>

        <Field name="nif">
          {Input({
            label: t("contact.nif"),
          })}
        </Field>
      </Box>

      <Space height={8} />

      <Field name="notary">
        {Input({
          label: t("company.notary"),
        })}
      </Field>

      <Space height={8} />

      <Box direction="row" alignItems="start" style={styles.gap}>
        <Field name="protocol">
          {Input({
            label: t("company.protocol"),
          })}
        </Field>

        <Field name="powerDate">
          {({ value, onChange, error }) => (
            <DatePicker
              label={t("company.powerDate")}
              format={locale.dateFormat}
              firstWeekDay={locale.firstWeekday}
              value={value}
              onChange={onChange}
              error={error}
            />
          )}
        </Field>
      </Box>

      <Box alignItems="end">
        <Space height={8} />

        <Button onPress={onDelete} mode="tertiary" icon={<FaRegTrashCan />} reverse={true}>
          {t("common.delete")}
        </Button>
      </Box>
    </Box>
  );
};
