import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { StaticCell } from "@components/forms/StaticCell";
import { AccountingStaticDocument, EntryRelay } from "@graphql/crm";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { ReactNode } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeDateISO } from "../../../utils/date";
import { formatNumeric, t } from "../../../utils/i18n";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";
import { extractAccountingStatic } from "../../invoices/utils";
import { EntryRelayCustom } from "../types";

const styles = StyleSheet.create({
  header: {
    color: fontColorVariants.white,
    paddingRight: 0,
  },
  headerDark: {
    color: fontColorVariants.white,
    backgroundColor: backgroundColorVariants.gray600,
    paddingVertical: 16,
  },
  cellDark: {
    backgroundColor: backgroundColorVariants.gray100,
    paddingVertical: 10,
  },
});

type Props = {
  data: EntryRelay[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
};
type ExtraInfo = undefined;

export const DetailsList = ({
  data,
  loading,
  onEndReached,
  activeRowId,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  const accountingStatics = useUrqlQuery({ query: AccountingStaticDocument }, []);
  const type = extractAccountingStatic(accountingStatics.data, "type");

  const format = (value: number) => {
    return value == 0 ? " " : formatNumeric(value);
  };

  const entryLines = data.flatMap(entry =>
    entry.entrylines.map(line => ({
      ...line,
      __typename: "EntryRelayCustom",
      entryNumber: entry.number,
      type: entry.type,
      date: entry.date,
    })),
  );

  const columns: ColumnConfig<EntryRelayCustom, ExtraInfo>[] = [
    {
      id: "entry",
      width: 50,
      title: t("accounting.entry"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} align="center" style={styles.header} />
      ),
      renderCell: ({ item }) => (
        <DefaultCell data={item.entryNumber.toString() ?? ""} align="center" />
      ),
    },
    {
      id: "line",
      width: 50,
      title: t("accounting.line"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} align="center" style={styles.header} />
      ),
      renderCell: ({ item }) => <DefaultCell data={item.number.toString() ?? ""} align="center" />,
    },
    {
      id: "date",
      width: 100,
      title: t("accounting.date"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={`${encodeDateISO(item.date ?? "")}`} />,
    },
    {
      id: "type",
      width: "grow",
      title: t("accounting.type"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <StaticCell data={item.type} items={type} />,
    },
    {
      id: "description",
      width: "grow",
      title: t("accounting.description"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.description ?? ""} />,
    },
    {
      id: "document",
      width: 100,
      title: t("accounting.document"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.objectId ?? ""} />,
    },
    {
      id: "account",
      width: 80,
      title: t("accounting.account"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.account.number.toString()} />,
    },
    {
      id: "sequential",
      width: "grow",
      title: t("common.subaccount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.sequentialShow} />,
    },
    {
      id: "account_name",
      width: "grow",
      title: t("accounting.account_name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.account.description ?? ""} />,
    },
    {
      id: "debit",
      width: 110,
      title: t("accounting.debit"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} align="center" style={styles.headerDark} />
      ),
      renderCell: ({ item }) => (
        <DefaultCell data={format(item.debit as number)} align="right" style={styles.cellDark} />
      ),
    },
    {
      id: "credit",
      width: 110,
      title: t("accounting.credit"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} align="center" style={styles.headerDark} />
      ),
      renderCell: ({ item }) => (
        <DefaultCell data={format(item.credit as number)} align="right" style={styles.cellDark} />
      ),
    },
  ];

  const smallColumns: ColumnConfig<EntryRelayCustom, ExtraInfo>[] = columns.filter(c =>
    ["date", "entry", "line", "description", "debit", "credit"].includes(c.id),
  );

  return (
    <>
      <PlainListView
        withoutScroll={true}
        data={entryLines as EntryRelayCustom[]}
        keyExtractor={item => item.id as string}
        headerHeight={36}
        rowHeight={0}
        headerStyle={styles.header as ViewStyle}
        headerBackgroundColor="#8F8E91"
        groupHeaderHeight={36}
        extraInfo={undefined}
        columns={columns}
        smallColumns={smallColumns}
        activeRowId={activeRowId}
        onActiveRowChange={onActiveRowChange}
        loading={loading}
        onEndReached={onEndReached}
        renderEmptyList={renderEmptyList}
        breakpoint={breakpoints.large}
        rowStyle={item => {
          const nextLine = entryLines.find(
            line => line.entryNumber === item.entryNumber && line.number === item.number + 1,
          );
          const borderBottom = nextLine?.type !== item.type;
          return {
            borderBottomWidth: borderBottom ? 12 : 0,
            borderBottomColor: borderColorVariants.white,
            backgroundColor: backgroundColorVariants.gray50,
            paddingRight: 0,
            maxHeight: 63,
          };
        }}
      />
    </>
  );
};
