import { DecimalInput } from "@components/DecimalInput";
import {
  PurchaseInvoiceRelay,
  SalesInvoiceCreateStaticDocument,
  SalesInvoiceRelayQuery,
} from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { useEffect } from "react";
import { StyleSheet } from "react-native";
import { FieldState, useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { tapError } from "../../../utils/validations";
import { CreateInvoiceState } from "../types";
import { extractSalesStatic } from "../utils";

const styles = StyleSheet.create({
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
});

type Props = {
  invoice?: SalesInvoiceRelayQuery | PurchaseInvoiceRelay;
  isDisabled?: (field: string) => boolean;
  onChange?: (invoice: {
    retentionPercentage?: FieldState<string | undefined>;
    retentionMode?: FieldState<string | undefined>;
  }) => void;
};

export const Retention = ({ invoice, isDisabled, onChange }: Props) => {
  const { Field, getFieldState, listenFields } = useForm<Partial<CreateInvoiceState>>({
    retentionPercentage: { initialValue: invoice?.retentionPercentage as string },
    retentionMode: { initialValue: invoice?.retentionMode ?? "tax_base" },
  });

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);
  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);
  const retentionModes = extractSalesStatic(data, "retentionModes");

  useEffect(() => {
    const removeListener = listenFields(["retentionPercentage", "retentionMode"], values => {
      onChange?.(values);
    });
    return () => removeListener();
  }, [listenFields]);

  const retentionPercentage = getFieldState("retentionPercentage").value;
  const hasRetention = isNotNullishOrEmpty(retentionPercentage) && retentionPercentage != "0.00";

  return (
    <>
      <Field name="retentionPercentage">
        {({ value, onChange, error }) => (
          <LakeLabel
            label={t("invoices.retentionPercentage")}
            style={styles.column}
            render={id => (
              <DecimalInput
                id={id}
                value={value?.toString()}
                hideErrors={error === undefined}
                error={error}
                onChangeDecimal={onChange}
                disabled={isDisabled?.("retentionPercentage")}
              />
            )}
          />
        )}
      </Field>

      {hasRetention && (
        <Field name="retentionMode">
          {({ onChange, value }) => (
            <RadioGroup
              items={retentionModes}
              value={value}
              onValueChange={onChange}
              direction="row"
              hideErrors={true}
              disabled={isDisabled?.("retentionMode")}
            />
          )}
        </Field>
      )}
    </>
  );
};
