import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import { FormEnter } from "@components/forms/FormEnter";
import { ChargeSalesInvoiceDocument, OperationInfo } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { fontColorVariants } from "../../../styles/constants";
import { encodeDate, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { handlerFieldErrors, validateRequired } from "../../../utils/validations";
import { PaymentMethodsForm } from "../../company/components/PaymentMethodsForm";
import { useModal } from "../../context/ModalContext";
import { useUser } from "../../context/UserContext";

const styles = StyleSheet.create({
  red: {
    color: fontColorVariants.destructive500,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
});

type Props = {
  visible: boolean;
  onPressClose: () => void;
  invoiceId: string;
  chargeDate: string;
};

type ChangeStatusForm = {
  paymentDate: string;
  invoiceId: string;
  paymentMethod: string;
  commission: string;
};

export const SalesUpdateStatusModal = ({ visible, onPressClose, invoiceId, chargeDate }: Props) => {
  const { company } = useUser();
  const { setModal, closeModal } = useModal();

  const [open, setOpen] = useState(visible);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const [, chargeSalesInvoice] = useUrqlMutation(ChargeSalesInvoiceDocument);

  const { Field, submitForm, setFieldError, setFieldValue, resetForm } = useForm<ChangeStatusForm>({
    paymentDate: { initialValue: chargeDate || getToday(), validate: validateRequired },
    invoiceId: { initialValue: invoiceId, validate: validateRequired },
    paymentMethod: { initialValue: "", validate: validateRequired },
    commission: { initialValue: "0.00" },
  });

  useEffect(() => {
    if (chargeDate !== "") {
      setFieldValue("paymentDate", chargeDate);
    }
  }, [chargeDate]);

  const handleSubmit = () => {
    submitForm(values => {
      chargeSalesInvoice({
        input: {
          paymentDate: values.paymentDate !== undefined ? encodeDate(values.paymentDate) : "",
          id: invoiceId,
          paymentMethodId: values.paymentMethod ?? "",
          commission: values.commission ?? "0",
        },
      })
        .mapOk(data => {
          match(data.chargeSalesInvoice)
            .with({ __typename: "OperationInfo" }, () =>
              handlerFieldErrors<ChangeStatusForm>(
                data.chargeSalesInvoice as OperationInfo,
                setFieldError,
              ),
            )
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("invoices.charge.success"),
                autoClose: true,
              });
              resetForm();
              onPressClose();
            });
        })
        .mapError(() => {
          showToast({ variant: "error", title: t("invoices.charge.error"), autoClose: true });
          resetForm();
          onPressClose();
        });
    });
  };

  const paymentMethods = useMemo(
    () => company?.paymentMethods.filter(pm => !pm.archived) ?? [],
    [company],
  );

  return (
    <LakeModal visible={open} onPressClose={onPressClose} title={t("invoices.charge")}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {() => (
          <FormEnter onSubmit={handleSubmit}>
            <Box
              onTouchEnd={e => {
                e.stopPropagation();
              }}
            >
              <Field name="paymentDate">
                {({ value, onChange, error }) => (
                  <DatePicker
                    format={locale.dateFormat}
                    firstWeekDay={locale.firstWeekday}
                    value={value}
                    onChange={onChange}
                    label={`${t("invoices.paymentDate")}*`}
                    error={error}
                  />
                )}
              </Field>

              <Field name="paymentMethod">
                {({ value, onChange, error, ref }) => (
                  <LakeLabel
                    label={t("common.collectionMethod")}
                    extra={() => <Text style={styles.red}>*</Text>}
                    render={() => (
                      <LakeSelect
                        ref={ref}
                        items={paymentMethods.map(pm => ({ name: pm.name, value: pm.id })) ?? []}
                        value={value}
                        onValueChange={onChange}
                        error={error}
                        hideErrors={error == undefined}
                      />
                    )}
                    style={styles.actions}
                    actions={
                      <Box direction="row" justifyContent="spaceBetween">
                        <Pressable
                          style={styles.action}
                          onPress={() => {
                            setOpen(false);
                            setModal({
                              visible: true,
                              title: t("company.newPaymentMethod"),
                              children: (
                                <PaymentMethodsForm
                                  onCallback={value => {
                                    closeModal();
                                    setOpen(true);
                                    onChange(value.id);
                                  }}
                                />
                              ),
                              maxWidth: 650,
                              onPressClose: () => {
                                closeModal();
                                setOpen(true);
                              },
                            });
                          }}
                        >
                          <Icon name="add-filled" size={12} color={fontColorVariants.neutral500} />
                          <Text style={styles.actionText}>{t("company.newPaymentMethod")}</Text>
                        </Pressable>
                      </Box>
                    }
                  />
                )}
              </Field>

              <Space height={12} />

              <Field name="commission">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("common.bankCommission")}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={value}
                        onChangeDecimal={nextValue => onChange(nextValue)}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>

            <Space height={24} />

            <Box alignItems="end">
              <Button onPress={handleSubmit} icon={<SaveIcon />} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </FormEnter>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
