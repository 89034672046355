import { NavigationItem } from "@components/NavigationItem";
import { NavigationSubitem } from "@components/NavigationSubitem";
import { Space } from "@swan-io/lake/src/components/Space";
import { FaArrowUp } from "react-icons/fa6";
import { View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";

export const Navigation = () => {
  const route = Router.useRoute(["AccountingLedgerList"]);

  return (
    <View role="navigation">
      <NavigationItem to={Router.AccountingLedgerList()} label={t("accounting.ledger")} />

      {match(route)
        .with({ name: "AccountingLedgerList" }, () => (
          <>
            <NavigationSubitem
              to={Router.AccountingLedgerList({ import: "" })}
              renderIcon={<FaArrowUp />}
              label={t("common.import")}
            />

            <Space height={12} />
          </>
        ))
        .otherwise(() => null)}
    </View>
  );
};
