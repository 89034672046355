import { LakeModalProps } from "@swan-io/shared-business/src/components/LakeModal";
import { ReactNode, createContext, useContext, useState } from "react";

type ModalContextType = {
  props: LakeModalProps;
  setModal: (props: LakeModalProps) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [props, setModal] = useState<LakeModalProps>({ visible: false, children: null });

  const closeModal = () => setModal({ ...props, visible: false });

  return (
    <ModalContext.Provider value={{ props, setModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
