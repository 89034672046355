import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import {
  CompanyAddress,
  CompanyStaticDocument,
  CreateCompanyAddressDocument,
  OperationInfo,
  Static,
  UpdateCompanyAddressDocument,
} from "@graphql/crm";
import { Lazy } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { CountryCCA3, allCountries } from "@swan-io/shared-business/src/constants/countries";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import {
  extractQueryData,
  handlerFieldErrors,
  tapError,
  validateRequired,
} from "../../../utils/validations";
import { useUser } from "../../context/UserContext";
import { CompanyAddressState } from "../types";

const styles = StyleSheet.create({
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
    marginBottom: 16,
  },
});

const countryItems = Lazy(() => allCountries.filter(cca3 => cca3 !== "USA"));

type Props = {
  address?: CompanyAddress;
};

export const CompanyAddressForm = ({ address }: Props) => {
  const { reloadCompany } = useUser();

  const { Field, setFieldValue, setFieldError, submitForm } = useForm<CompanyAddressState>({
    address: { initialValue: address?.address ?? "", validate: validateRequired },
    locality: { initialValue: address?.locality ?? "", validate: validateRequired },
    postalCode: { initialValue: address?.postalCode ?? "", validate: validateRequired },
    country: {
      initialValue: (address?.country as CountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    type: { initialValue: address?.type ?? "fiscal", validate: validateRequired },
    default: { initialValue: address?.default ?? false },
  });

  const { data: staticData } = useUrqlQuery({ query: CompanyStaticDocument }, []);

  const addressTypes = extractQueryData(
    staticData,
    "value.value.companyStatic.addressTypes",
  ) as Static[];

  const [, create] = useUrqlMutation(CreateCompanyAddressDocument);
  const [, update] = useUrqlMutation(UpdateCompanyAddressDocument);

  const onSave = () => {
    submitForm(values => {
      if (address == null) {
        create({
          input: {
            ...values,
            country: values.country as string,
            type: values.type as string,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.createCompanyAddress)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CompanyAddressState>(
                  data.createCompanyAddress as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                reloadCompany();
                Router.push("CompanyAddresses");
              });
          });
      } else {
        update({
          input: {
            id: address?.id.toString(),
            ...values,
            type: address?.type,
            country: values.country as string,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.updateCompanyAddress)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CompanyAddressState>(
                  data.updateCompanyAddress as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                reloadCompany();
                Router.push("CompanyAddresses");
              });
          });
      }
    });
  };

  return (
    <>
      <Box direction="row" alignItems="start" style={styles.row}>
        <Field name="type">
          {({ onChange, value, error }) => (
            <RadioGroup
              direction="row"
              items={addressTypes ?? []}
              value={value}
              onValueChange={onChange}
              error={error}
              hideErrors={error == undefined}
            />
          )}
        </Field>
      </Box>

      <Field name="address">
        {({ value, onChange, error }) => (
          <LakeLabel
            label={t("contact.address")}
            render={id => (
              <PlacekitAddressSearchInput
                shouldDisplaySuggestions={true}
                id={id}
                apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                country={"ESP"}
                value={value}
                onValueChange={onChange}
                onSuggestion={suggestion => {
                  setFieldValue("address", suggestion.completeAddress);
                }}
                language="es"
                placeholder={t("contact.address.placeHolder")}
                emptyResultText={t("common.empty")}
                error={error}
              />
            )}
          />
        )}
      </Field>

      <Box direction="row" alignItems="start" style={styles.row}>
        <Field name="locality">
          {Input({
            label: t("company.locality"),
          })}
        </Field>

        <Field name="postalCode">
          {Input({
            label: t("contact.postalCode"),
          })}
        </Field>
      </Box>

      <Box direction="row" alignItems="start" style={styles.row}>
        <Field name="country">
          {({ value, onChange, ref, error }) => (
            <LakeLabel
              label={t("common.country")}
              render={id => (
                <CountryPicker
                  id={id}
                  ref={ref}
                  value={value}
                  error={error}
                  countries={countryItems.get()}
                  onValueChange={onChange}
                  hideErrors={error == undefined}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Box direction="row" alignItems="center" justifyContent="end">
        <Field name="default">
          {({ value, onChange, error }) => (
            <Pressable onPress={() => onChange(!value)}>
              <Box direction="row" alignItems="center">
                <LakeCheckbox value={value} />
                <Space width={8} />
                <Text>{t("company.mainAddress")}</Text>
              </Box>

              {error != null && <Text>{error}</Text>}
            </Pressable>
          )}
        </Field>

        <Space width={16} />
        <Button onPress={onSave}>{t("common.save")}</Button>
      </Box>
    </>
  );
};
