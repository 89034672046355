import { RightPanel } from "@components/RightPanel";
import { UploadShowLogoCompany } from "@components/UploadShowLogoCompany";
import { Button, ButtonGroup } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import {
  CalculateInvoiceDocument,
  ConceptType,
  CreateSalesInvoiceDocument,
  CustomerRelay,
  CustomerRelayEdge,
  CustomersDocument,
  GetSimplifiedCurrentSeriesDocument,
  InvoiceTotals,
  Issuer,
  NerSalesInvoice,
  SalesConceptInput,
  SalesInvoiceCreateStaticDocument,
  SalesInvoiceRelayQuery,
  Series,
  Status,
  UpdateSalesInvoiceDocument,
  UserSeriesDocument,
} from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { RadioGroup } from "@swan-io/lake/src/components/RadioGroup";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { useNestedForm } from "../../../components/NestedForm";
import { common } from "../../../styles/common";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeDate, encodeDateISO, getToday, isDateInCurrentQuarter } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { substitueValues } from "../../../utils/invoices";
import { Router } from "../../../utils/routes";
import { checkRule, useSubscription } from "../../../utils/subscription";
import {
  extractQueryData,
  handlerErrors,
  passValidatorTrue,
  statusConfig,
  tapError,
  validateHasItems,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { CustomerCreate } from "../../contacts/components/CustomerCreate";
import { useUser } from "../../context/UserContext";
import { useInvoiceHistory } from "../hooks/useInvoiceHistory";
import { Concept, CreateInvoiceState, CreateSalesConceptState } from "../types";
import { extractSalesStatic } from "../utils";
import { ExpirationDate } from "./ExpirationDate";
import { InvoiceTotal } from "./InvoiceTotal";
import { Retention } from "./Retention";
import { SalesConceptItem } from "./SalesConceptItem";
import { SalesSuplidoConceptItem } from "./SalesSuplidoConceptItem";
import { SeriesModal } from "./SeriesModal";
import { Tabs } from "./Tabs";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "96dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  paymentMethod: {
    display: "grid",
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 24,
  },
  columnMin: {
    width: 300,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
  gap: {
    gap: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  uploadArea: {
    "--spacing-32": 16,
    display: "flex",
    gap: 24,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
  newItem: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 24,
    boxShadow: "none",
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  typeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

type Props = {
  invoice?: SalesInvoiceRelayQuery;
  ner?: boolean;
  invoiceNer?: NerSalesInvoice;
  onRefreshRequest?: () => void;
  typeCode?: string;
};

type StatusConfigValue = {
  disabled?: string[];
};

export const InvoiceEditor = ({
  invoice,
  onRefreshRequest,
  ner = false,
  invoiceNer,
  typeCode,
}: Props) => {
  const route = Router.useRoute(["InvoicesSalesList", "InvoicesDocumentsList"]);
  const { previousId } = route?.params ?? {};

  const isSimplified = typeCode == "F2";

  const { onSubmit, setMainForm } = useNestedForm();

  const showDraftButton =
    invoice === undefined || invoice?.id === null || invoice.status === "DRAFT";

  const [saveDraft, setSaveDraft] = useState(showDraftButton);

  const getNewConcept = (type = "normal") => ({
    id: crypto.randomUUID(),
    description: "",
    price: 0,
    quantity: 1,
    expenseType: "corriente",
    irpfId: "",
    vatId: "",
    recargoPercentageId: "",
    type,
  });

  const { data: currentSeriesData } = useUrqlQuery(
    { query: GetSimplifiedCurrentSeriesDocument, pause: !isSimplified },
    [],
  );

  const currentSimplifiedSeries = extractQueryData(
    currentSeriesData,
    "value.value.getSimplifiedCurrentSeries.series",
  ) as string;

  const conceptForm = () => {
    if (invoice === undefined) {
      return [getNewConcept()];
    }
  };

  const [submit, setSubmit] = useState(false);

  const { Field, setFieldValue, getFieldState, submitForm, listenFields } =
    useForm<CreateInvoiceState>({
      issuerId: {
        initialValue: invoice?.issuer?.id !== undefined ? Number(invoice?.issuer.id) : 0,
      },
      customerId: {
        initialValue:
          invoice?.customer?.id !== undefined ? Number(invoice?.customer.id) : undefined,
        validate: passValidatorTrue(validateNumericNullableRequired, saveDraft),
      },
      customerAddress: { initialValue: invoice?.customerAddress ?? "" },
      customerLocality: { initialValue: invoice?.customerLocality ?? "" },
      customerPostalCode: { initialValue: invoice?.customerPostalCode ?? "" },
      customerCountry: { initialValue: invoice?.customerCountry as CountryCCA3 },
      seriesId: {
        initialValue: invoice?.series?.pk !== undefined ? Number(invoice?.series?.pk) : undefined,
        validate: passValidatorTrue(validateNumericNullableRequired, saveDraft),
      },
      seriesCurrent: { initialValue: invoice?.seriesCurrent ?? "" },
      issueDate: {
        initialValue: invoice?.issueDate != null ? encodeDateISO(invoice?.issueDate) : getToday(),
        validate: passValidatorTrue(validateRequired, saveDraft),
      },
      expirationDate: {
        initialValue: invoice?.expirationDate != null ? encodeDateISO(invoice?.expirationDate) : "",
        validate: passValidatorTrue(validateRequired, saveDraft),
        strategy: "onSubmit",
      },
      concepts: {
        initialValue: conceptForm() as Concept[],
        validate: passValidatorTrue(validateHasItems, saveDraft),
      },
      status: { initialValue: invoice?.status ?? "DRAFT" },
      type: { initialValue: (invoice?.type as string) ?? null, validate: validateRequired },
      paymentMethod: { initialValue: invoice?.paymentMethod ?? "" },
      iban: { initialValue: invoice?.iban ?? "", validate: validateIban },
      notes: { initialValue: invoice?.notes ?? "" },
      retentionPercentage: { initialValue: invoice?.retentionPercentage as string },
      retentionMode: { initialValue: invoice?.retentionMode ?? "tax_base" },
    });

  const [, calculateInvoice] = useUrqlMutation(CalculateInvoiceDocument);
  const [open, setOpen] = useState(false);
  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [totals, setTotals] = useState<InvoiceTotals>();
  const [currentId, setCurrentId] = useState<string>();
  const [formState, setFormState] = useState<StatusConfigValue>({});

  const callCalculateInvoice = () => {
    const { value } = getFieldState("concepts");
    calculateInvoice({
      input: {
        lineItems: value.map(concept => ({
          description: concept.description,
          quantity: concept.quantity != null ? (concept.quantity > 0 ? concept.quantity : 1) : 1,
          unitPrice: concept.unitPrice === "" ? undefined : concept.unitPrice,
          subtotal: concept.subtotal === "" ? undefined : concept.subtotal,
          discountPercentage: concept.discountPercentage,
          discountAmount: concept.discountAmount,
          vatRate: isNotNullish(concept.vatId) ? String(concept.vatId) : null,
          irpfPercentage: isNotNullish(concept.irpfId) ? String(concept.irpfId) : null,
          total: concept.price,
          type: concept.type.toUpperCase() as ConceptType,
          recargoPercentage: isNotNullishOrEmpty(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : null,
        })),
        retentionPercentage: getFieldState("retentionPercentage").value,
        retentionMode: getFieldState("retentionMode").value,
      },
    }).mapOk(data => {
      const lineItems = extractQueryData(data.calculateInvoice, "lineItems") as Concept[];

      let isCalculated = false;
      value.forEach((concept, index) => {
        const lineItem = lineItems[index];
        if (lineItem) {
          concept.total = lineItem.total as string;
          concept.discountAmount = lineItem.discountAmount;
          concept.irpfAmount = lineItem.irpfAmount as string;
          concept.vatAmount = lineItem.vatAmount as string;
          concept.taxBase = lineItem.taxBase as string;
          concept.price = Number(lineItem.total);
          concept.recargoAmount = Number(lineItem.recargoAmount);

          if (concept.unitPrice != lineItem.unitPrice) {
            concept.unitPrice = lineItem.unitPrice as string;
            isCalculated = true;
          }
          if (concept.subtotal != lineItem.subtotal) {
            concept.subtotal = lineItem.subtotal as string;
            isCalculated = true;
          }
        }
      });
      if (isCalculated) {
        setFieldValue("concepts", [...value]);
      }

      const totals = extractQueryData(data.calculateInvoice, "totals") as InvoiceTotals;
      setTotals(totals);
    });
  };

  const refresh = useDebounce<void>(() => callCalculateInvoice(), 500);

  useEffect(() => {
    const removeResultsListener = listenFields(
      ["concepts", "retentionPercentage", "retentionMode"],
      () => refresh(),
    );

    return () => removeResultsListener();
  }, [listenFields, refresh]);

  useEffect(() => {
    if (invoiceNer !== undefined) {
      reloadCustomers();
      const totals = structuredClone(invoiceNer.totals);
      const concepts: CreateSalesConceptState[] = [];
      invoiceNer?.lineItems.forEach(concept => {
        concepts.push({
          id: concept.id ?? "",
          productId: concept.product?.pk !== undefined ? Number(concept.product?.pk) : -1,
          description: concept.description ?? "",
          unitType: concept.unitType ?? "",
          unitPrice: concept.unitPrice as string,
          price: concept.price as number,
          vatId: isNotNullish(concept.vatId) ? String(concept.vatId) : "",
          quantity: concept.quantity,
          irpfId: isNotNullish(concept.irpfId) ? String(concept.irpfId) : "",
          discountAmount: concept.discountAmount as number,
          discountPercentage: concept.discountPercentage as number,
          expenseType: concept.expenseType ?? "",
          subtotal: concept.subtotal as string,
          type: concept.type,
          recargoPercentageId: isNotNullish(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : "",
          category: (concept.category ?? "") as string,
          subcategory: (concept.subcategory ?? "") as string,
          subsubcategory: concept.subsubcategory ?? "",
          propertySituation: concept.propertySituation ?? undefined,
          cadastralReference: concept.cadastralReference ?? undefined,
        });
      });
      setFieldValue(
        "customerId",
        Boolean(invoiceNer.customer) ? Number(invoiceNer.customer?.id) : undefined,
      );
      setFieldValue("issueDate", invoiceNer.invoice.issueDate);
      setFieldValue("expirationDate", invoiceNer.invoice.expirationDate);
      setFieldValue("concepts", concepts as unknown as Concept[]);
      setFieldValue("paymentMethod", invoiceNer.invoice.paymentMethod as string);
      setFieldValue("iban", invoiceNer.invoice.iban as string);
      setFieldValue("notes", invoiceNer.invoice.notes as string);
      setFieldValue("status", invoiceNer.invoice.status);
      setTotals(totals);
      invoiceNer.invoice.type = invoice?.type ?? "";
    }
  }, [invoiceNer]);

  useEffect(() => {
    setOpen(!getFieldState("type").value);
  }, [getFieldState("type").value]);

  useEffect(() => {
    setFieldValue("seriesCurrent", currentSimplifiedSeries ?? invoice?.seriesCurrent);
  }, [currentSimplifiedSeries, invoice]);

  const [showSerieCreate, setShowSerieCreate] = useState(false);

  const [showCustomerCreate, setShowCustomerCreate] = useState(false);
  const [customer, setCustomer] = useState<CustomerRelay | undefined>();

  const { data: series, reload: reloadSeries } = useUrqlQuery({ query: UserSeriesDocument }, []);
  const userSeries = extractQueryData(series, "value.value.userSeries") as Series[];

  const { data: customersQuery, reload: reloadCustomers } = useUrqlQuery(
    { query: CustomersDocument, variables: { first: 100, filters: {} } },
    [],
  );
  const customers = extractQueryData(
    customersQuery,
    "value.value.customers.edges",
  ) as CustomerRelayEdge[];

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);

  const paymentMethods = extractSalesStatic(data, "paymentMethods");
  const expirationDate = extractSalesStatic(data, "expirationDate");
  const conceptType = extractSalesStatic(data, "conceptType");
  const statusType = extractSalesStatic(data, "statusType");

  const history = useInvoiceHistory({ salesInvoiceId: invoice?.id as string });

  const issuer = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.salesInvoiceCreateStatic.issuer)
    .getWithDefault({} as Issuer);

  const logoUrl = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.salesInvoiceCreateStatic.logoUrl)
    .getWithDefault("");

  const isDisabled = (fieldName: string): boolean => {
    if (
      invoice?.issueDate != null &&
      !["PROFORMA", "QUOTE", "DELIVERY", "DRAFT"].includes(invoice?.status)
    ) {
      if (!isDateInCurrentQuarter(invoice.issueDate, true)) {
        return true;
      }
    }
    return Boolean(formState.disabled?.includes(fieldName));
  };

  useEffect(() => {
    if (invoice !== undefined) {
      reloadCustomers();
      const concepts: CreateSalesConceptState[] = [];
      invoice?.concepts.forEach(concept => {
        concepts.push({
          id: concept.id ?? "",
          productId: concept.product?.pk !== undefined ? Number(concept.product?.pk) : -1,
          description: concept.description ?? "",
          unitType: concept.unitType ?? "",
          unitPrice: concept.unitPrice as string,
          price: Number(concept.price),
          vatId: isNotNullish(concept.vatId) ? String(concept.vatId) : "",
          quantity: concept.quantity,
          irpfId: isNotNullish(concept.irpfId) ? String(concept.irpfId) : "",
          discountAmount: concept.discountAmount as number,
          discountPercentage: concept.discountPercentage as number,
          expenseType: concept.expenseType ?? "",
          subtotal: concept.subtotal as string,
          type: concept.type,
          recargoPercentageId: isNotNullish(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : "",
          category: String(concept.category) ?? undefined,
          subcategory: String(concept.subcategory) ?? undefined,
          subsubcategory: concept.subsubcategory ?? undefined,
          propertySituation: concept.propertySituation ?? undefined,
          cadastralReference: concept.cadastralReference ?? undefined,
        });
      });
      setFieldValue(
        "customerId",
        Boolean(invoice.customer) ? Number(invoice.customer?.id) : undefined,
      );
      if (previousId == null) {
        setFieldValue("seriesCurrent", invoice.seriesCurrent as string);
      }
      setFieldValue("issueDate", encodeDateISO(invoice.issueDate as string));
      setFieldValue("expirationDate", encodeDateISO(invoice.expirationDate as string));
      setFieldValue("concepts", concepts);
      setFieldValue("paymentMethod", invoice.paymentMethod as string);
      setFieldValue("iban", invoice.iban as string);
      setFieldValue("notes", invoice.notes as string);
      setFieldValue("status", invoice.status ?? "DRAFT");
      if (invoice.status != null) {
        const constraints = statusConfig[invoice.status as Status] ?? {};
        setFormState(constraints);
      }
      callCalculateInvoice();
    }
  }, [invoice]);

  const { showUpgrade } = useSubscription();

  const { subscription } = useUser();

  useEffect(() => {
    if (invoice?.id !== undefined && !ner) {
      callCalculateInvoice();
    }
  }, []);

  const handleNewConcept = (type: string) => {
    if (type === "suplido" && !checkRule(subscription, "has_suplidos")) {
      showUpgrade({});
    } else {
      const newConcept = getNewConcept(type);

      const { value } = getFieldState("concepts");
      setCurrentId(newConcept.id);
      setFieldValue("concepts", [...value, newConcept]);
    }
  };

  const [, createInvoice] = useUrqlMutation(CreateSalesInvoiceDocument);
  const [, updateInvoice] = useUrqlMutation(UpdateSalesInvoiceDocument);

  const handleSubmit = () => {
    if (invoice && previousId != null) {
      invoice.id = "audio";
    }

    submitForm(values => {
      const input = {
        ...values,
        status: values.status as Status,
        issuerId: Number(issuer.id),
        seriesId: values.seriesId as number,
        issueDate:
          values.issueDate !== undefined && values.issueDate !== ""
            ? encodeDate(values.issueDate)
            : undefined,
        expirationDate:
          values.expirationDate !== undefined && values.expirationDate !== ""
            ? encodeDate(values.expirationDate)
            : undefined,
        totalDiscount: totals?.totalDiscount ?? 0,
        totalVat: totals?.totalVat ?? 0,
        totalTaxBase: totals?.totalTaxBase ?? 0,
        totalIrpf: totals?.totalIrpf ?? 0,
        subtotal: totals?.subtotal ?? 0,
        total: totals?.total ?? 0,
        retentionAmount: totals?.retention?.amount,
        proformaInvoiceId: Number(previousId),
        typeCode,
      };
      if (
        invoice !== undefined &&
        invoice.id !== "audio" &&
        invoice.status !== "QUOTE" &&
        invoice.status !== "DELIVERY"
      ) {
        Object.assign(input, { id: invoice.id });
        const payload = {
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              if (concept.productId === -1) {
                concept.productId = undefined;
              }
              const { category, subcategory, ...cleanConcept } = concept;
              if (concept.id.length === 36) {
                const { id, ...rest } = cleanConcept;
                return rest;
              }
              return { ...cleanConcept, total: concept.total, price: concept.price };
            }) as SalesConceptInput[],
          },
        };
        updateInvoice(payload)
          .mapOk(data => {
            match(data.updateSalesInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                handleBack(input.status);
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      } else {
        createInvoice({
          input: {
            ...input,
            concepts: values.concepts?.map(concept => {
              const { id, category, subcategory, ...rest } = concept;
              if (rest.unitPrice === "") {
                rest.unitPrice = undefined;
              }
              if (rest.subtotal === "") {
                rest.subtotal = undefined;
              }
              if (rest.productId === -1) {
                rest.productId = undefined;
              }
              return rest;
            }) as SalesConceptInput[],
          },
        })
          .mapOk(data => {
            match(data.createSalesInvoice)
              .with({ __typename: "OperationInfo" }, error => {
                if (error.messages?.[0]?.field === "issuerAddress") {
                  showToast({
                    variant: "error",
                    title: error.messages?.[0]?.message,
                    description: `<a href="${Router.CompanyAddresses()}" style="color:var(--color-negative-700)">${t("invoices.issuerAddressError")}</a>`,
                  });
                  return;
                }
                handlerErrors(error);
              })
              .otherwise(() => {
                onRefreshRequest?.();
                handleBack(input.status);
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      }
    });
  };

  const handleBack = (status?: Status) => {
    const value = status ?? invoice?.status;
    if (value === "DRAFT") {
      Router.push("InvoicesDocumentsList");
    } else {
      Router.push("InvoicesSalesList");
    }
  };

  const onClick = (draft: boolean) => {
    setFieldValue("status", draft ? "DRAFT" : "PENDING");
    setSaveDraft(draft);
    setSubmit(true);
    setMainForm(() => handleSubmit);
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  return (
    <View>
      <Field name="type">{() => null}</Field>
      <Field name="retentionPercentage">{() => null}</Field>
      <Field name="retentionMode">{() => null}</Field>
      <Tabs history={history} status={statusType} onPress={() => setCurrentId("")} />
      <Space height={12} />

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <View style={styles.contain}>
            <View>
              <Box
                direction={large ? "row" : "column"}
                alignItems={large ? "end" : "stretch"}
                justifyContent="spaceBetween"
                style={styles.gap}
              >
                <View style={styles.columnMin}>
                  <View style={styles.uploadArea}>
                    <UploadShowLogoCompany logoUrl={logoUrl} />
                  </View>

                  <Space height={48} />

                  <Text style={styles.title}>
                    {isSimplified ? t("invoices.simplifiedInvoice") : t("invoice")}
                  </Text>

                  <Field name="status">{() => null}</Field>

                  {getFieldState("seriesCurrent").value == null ? (
                    <>
                      <Field name="seriesId">
                        {({ value, onChange, error }) => (
                          <LakeLabel
                            label={t("invoices.invoiceNumber")}
                            render={() => (
                              <LakeSelect
                                disabled={isDisabled("seriesId")}
                                items={
                                  userSeries?.map(item => ({
                                    name: substitueValues(item),
                                    value: parseInt(item.id),
                                  })) ?? []
                                }
                                value={value}
                                onValueChange={onChange}
                                hideErrors={error === undefined}
                                error={error}
                              />
                            )}
                            style={styles.actions}
                            actions={
                              !isDisabled("seriesId") && (
                                <Pressable
                                  style={styles.action}
                                  onPress={() => setShowSerieCreate(true)}
                                >
                                  <Icon
                                    name="add-filled"
                                    size={12}
                                    color={fontColorVariants.neutral500}
                                  />

                                  <Text style={styles.actionText}>{t("invoices.newSerie")}</Text>
                                </Pressable>
                              )
                            }
                          />
                        )}
                      </Field>

                      <SeriesModal
                        visible={showSerieCreate}
                        onPressClose={() => {
                          reloadSeries();
                          setShowSerieCreate(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Field name="seriesCurrent">
                        {({ value }) => (
                          <LakeLabel
                            label={t("invoices.invoiceNumber")}
                            render={() => (
                              <LakeTextInput
                                style={styles.actions}
                                value={value}
                                disabled={true}
                                hideErrors={true}
                              />
                            )}
                            style={styles.actions}
                          />
                        )}
                      </Field>

                      <Space height={20} />
                    </>
                  )}
                </View>

                <View style={large && styles.column}>
                  <Box
                    direction="row"
                    justifyContent="spaceBetween"
                    style={[styles.gap, common.wrap]}
                  >
                    <View style={styles.column}>
                      <Field name="issueDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={value => {
                              onChange(value);
                              setFieldValue("expirationDate", "");
                            }}
                            label={t("invoices.issueDate") + "*"}
                            error={error}
                            disabled={isDisabled("issueDate")}
                          />
                        )}
                      </Field>
                    </View>

                    <View style={styles.column}>
                      <Field name="expirationDate">
                        {({ value, onChange, error }) => (
                          <ExpirationDate
                            label={t("invoices.dueDate") + "*"}
                            items={expirationDate}
                            forceCalendar={invoice?.expirationDate != null}
                            issueDate={getFieldState("issueDate").value}
                            value={value}
                            onChange={onChange}
                            error={error}
                            disabled={isDisabled("expirationDate")}
                          />
                        )}
                      </Field>
                    </View>
                  </Box>

                  {isSimplified ? (
                    <Space height={96} />
                  ) : (
                    <>
                      <Text style={styles.subtitle}>{t("invoices.clientData")}</Text>

                      <Field name="customerId">
                        {({ value, onChange, error }) => (
                          <LakeLabel
                            label={t("invoices.customer")}
                            render={() => (
                              <LakeSelect
                                items={
                                  customers?.map(item => ({
                                    name: `${item.node.name} ${item.node.nif != null ? ` (${item.node.nif})` : ""}`,
                                    value: parseInt(item.node.id as string),
                                  })) ?? []
                                }
                                value={value}
                                onValueChange={onChange}
                                hideErrors={error === undefined}
                                error={error}
                                disabled={isDisabled("customerId")}
                              />
                            )}
                            style={styles.actions}
                            actions={
                              <Box direction="row" justifyContent="spaceBetween">
                                <Pressable
                                  style={styles.action}
                                  onPress={() => {
                                    setCustomer(undefined);
                                    setShowCustomerCreate(true);
                                  }}
                                >
                                  <Icon
                                    name="add-filled"
                                    size={12}
                                    color={fontColorVariants.neutral500}
                                  />

                                  <Text style={styles.actionText}>{t("invoices.newCustomer")}</Text>
                                </Pressable>

                                {value != null && (
                                  <Pressable
                                    style={styles.action}
                                    onPress={() => {
                                      setCustomer(
                                        customers?.find(c => c.node.id === value.toString())?.node,
                                      );
                                      setShowCustomerCreate(true);
                                    }}
                                  >
                                    <Icon
                                      name="edit-regular"
                                      size={12}
                                      color={fontColorVariants.neutral500}
                                    />

                                    <Text style={styles.actionText}>
                                      {t("invoices.editCustomer")}
                                    </Text>
                                  </Pressable>
                                )}
                              </Box>
                            }
                          />
                        )}
                      </Field>

                      {showCustomerCreate && (
                        <CustomerCreate
                          visible={showCustomerCreate}
                          customer={customer}
                          onClose={() => {
                            reloadCustomers();
                            setShowCustomerCreate(false);
                          }}
                        />
                      )}

                      {(isDisabled("customerId") || isDisabled("seriesId")) && <Space height={8} />}
                    </>
                  )}
                </View>
              </Box>

              <Space height={32} />

              <Field name="concepts">
                {({ value, error }) => (
                  <Box style={styles.gap}>
                    {value?.map(concept =>
                      concept.type === "suplido" ? (
                        <SalesSuplidoConceptItem
                          key={concept.id}
                          isActive={currentId === concept.id}
                          setCurrentId={() => setCurrentId(concept.id)}
                          concept={concept}
                          deleteDisabled={value.length === 1 || isDisabled("concepts")}
                          validate={saveDraft}
                          onChange={concept => {
                              const { value } = getFieldState("concepts");
                              setFieldValue(
                                "concepts",
                                value.map(c =>
                                  c.id === concept.id ? { ...c, ...concept } : c,
                                ),
                              );
                          }}
                          onDelete={() => {
                              const { value } = getFieldState("concepts");
                              setFieldValue(
                                "concepts",
                                value.filter(c => c.id !== concept.id),
                              );
                          }}
                          disabled={isDisabled("concepts")}
                        />
                      ) : (
                        <SalesConceptItem
                          key={concept.id}
                          isActive={currentId === concept.id}
                          setCurrentId={() => setCurrentId(concept.id)}
                          concept={concept}
                          deleteDisabled={value.length === 1 || isDisabled("concepts")}
                          validate={saveDraft}
                          onChange={concept => {
                            const { value } = getFieldState("concepts");
                            setFieldValue(
                              "concepts",
                              value.map(c =>
                                c.id === concept.id ? { ...c, ...concept } : c,
                              ),
                            );
                          }}
                          onDelete={() => {
                              const { value } = getFieldState("concepts");
                              setFieldValue(
                                "concepts",
                                value.filter(c => c.id !== concept.id),
                              );
                          }}
                          disabled={isDisabled("concepts")}
                          invoiceType={getFieldState("type").value}
                          recargo={concept.type === "recargo_equivalencia"}
                        />
                      ),
                    )}

                    {!isDisabled("concepts") && (
                      <LakeSelect
                        placeholder={t("invoices.addNewItem")}
                        hideErrors={true}
                        items={conceptType}
                        onValueChange={handleNewConcept}
                        disabled={isDisabled("concepts")}
                        style={styles.newItem}
                      />
                    )}

                    <Text style={styles.red}>{error}</Text>
                  </Box>
                )}
              </Field>

              <Field name="notes">
                {Input({
                  label: t("invoices.notes"),
                  disabled: isDisabled("notes"),
                })}
              </Field>

              <Space height={24} />

              <Box
                direction="row"
                justifyContent="spaceBetween"
                alignItems="end"
                style={styles.gap}
              >
                <Box direction="column" style={[styles.column, styles.gap, styles.paymentMethod]}>
                  <Retention
                    invoice={invoice}
                    onChange={values => {
                      setFieldValue("retentionPercentage", values.retentionPercentage?.value);
                      setFieldValue("retentionMode", values.retentionMode?.value);
                    }}
                    isDisabled={isDisabled}
                  />

                  <Field name="paymentMethod">
                    {({ value, onChange, error, ref }) => (
                      <LakeLabel
                        label={t("contact.paymentMethod")}
                        render={id => (
                          <LakeSelect
                            id={id}
                            ref={ref}
                            value={value}
                            hideErrors={error === undefined}
                            error={error}
                            items={paymentMethods}
                            disabled={isDisabled("paymentMethod")}
                            onValueChange={value => {
                              onChange(value);
                              setPaymentMethodType(value);
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>

                  {["transferencia", "domiciliacion"].includes(paymentMethodType) && (
                    <LakeLabel
                      label={t("contact.iban")}
                      render={id => (
                        <Field name="iban">
                          {({ value, onChange, onBlur, error, validating, ref }) => (
                            <LakeTextInput
                              id={id}
                              ref={ref}
                              value={printIbanFormat(value ?? "")}
                              validating={validating}
                              hideErrors={error === undefined}
                              error={error}
                              onChangeText={onChange}
                              onBlur={onBlur}
                              disabled={isDisabled("iban")}
                            />
                          )}
                        </Field>
                      )}
                    />
                  )}
                </Box>

                <InvoiceTotal totals={totals} />
              </Box>
            </View>
          </View>
        )}
      </ResponsiveContainer>

      <Box direction="row" justifyContent="end">
        <Button mode="tertiary" size="large" disabled={false} onPress={handleBack}>
          {t("common.cancel")}
        </Button>

        <ButtonGroup transparent={false}>
          {showDraftButton && (
            <Button
              style="group"
              size="large"
              disabled={false}
              icon={<SaveIcon />}
              reverse={true}
              onPress={() => onClick(true)}
            >
              {t("invoices.draft")}
            </Button>
          )}

          <Button
            style="group"
            size="large"
            disabled={false}
            icon={<SaveIcon />}
            reverse={true}
            onPress={() => onClick(false)}
          >
            {showDraftButton === true ? t("invoices.issueInvoice") : t("common.save")}
          </Button>
        </ButtonGroup>
      </Box>

      <RightPanel visible={open} overlay={true}>
        <View style={styles.rightpanel}>
          <View style={styles.typeContainer}>
            <LakeLabel
              label={t("invoice.type")}
              render={() => (
                <Field name="type">
                  {({ value, onChange }) => (
                    <RadioGroup
                      items={[
                        { name: t("invoice.current"), value: "current" },
                        { name: t("invoice.nonCurrent"), value: "non_current" },
                      ]}
                      value={value}
                      direction="row"
                      onValueChange={value => {
                        setOpen(false);
                        onChange(value);
                      }}
                    />
                  )}
                </Field>
              )}
            />
          </View>
        </View>
      </RightPanel>
    </View>
  );
};
