import {
  AccountingStatic,
  PurchaseCreateStatic,
  SalesCreateStatic,
  Static,
  Tax,
} from "@graphql/crm";
import { AsyncData, Option, Result } from "@swan-io/boxed";
import { Data } from "@urql/exchange-graphcache";
import { CombinedError } from "urql";

export const extractSalesStatic = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof SalesCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.salesInvoiceCreateStatic))
    .map(value => value[staticType as keyof typeof value] as Static[])
    .getWithDefault([]);

export const extractSalesTax = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof SalesCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.salesInvoiceCreateStatic))
    .map(value => value[staticType as keyof typeof value] as Tax[])
    .getWithDefault([]);

export const salesTaxToItems = (data: Tax[]) => {
  return data.map(row => ({ label: row.description, value: row.id.toString() }));
};

export const extractPurchaseStatic = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof PurchaseCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.purchaseInvoiceCreateStatic))
    .map(value => value[staticType as keyof typeof value] as Static[])
    .getWithDefault([]);

export const extractPurchaseTax = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof PurchaseCreateStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.purchaseInvoiceCreateStatic))
    .map(value => value[staticType as keyof typeof value] as Tax[])
    .getWithDefault([]);

export const base64ToFile = async (base64: string, filename: string) => {
  const response = await fetch(base64);
  const blob = await response.blob();
  return new File([blob], filename, { type: blob.type });
};

export const extractAccountingStatic = (
  data: AsyncData<Result<Data, CombinedError>>,
  staticType: keyof AccountingStatic,
) =>
  data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.accountingStatic))
    .map(value => value[staticType as keyof typeof value] as Static[])
    .getWithDefault([]);
