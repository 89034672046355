import { Calendar } from "@components/Calendar";
import { ErrorView } from "@components/ErrorView";
import { Heading } from "@components/Heading";
import { InputRounded } from "@components/forms/Input";
import {
  AccountingEntriesDocument,
  AccountingStaticDocument,
  EntryRelay,
  Static,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useUrqlPaginatedQuery, useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";
import { COLORS } from "../../../values/colors";
import { DetailsList } from "../components/AccountingList";
import { AccountingListFiltersState, ListFilter } from "../components/AccountingListFilters";
import { ImportWizard } from "../components/ImportWizard";

const styles = StyleSheet.create({
  list: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
});

const NUM_TO_RENDER = 5;

export const Ledger = () => {
  const route = Router.useRoute(["AccountingLedgerList"]);

  const params = match(route)
    .with({ name: "AccountingLedgerList" }, ({ params }) => params)
    .otherwise(() => {});

  const { from, to } = useDateFilter();

  const filters: AccountingListFiltersState = useMemo(() => {
    return {
      search: params?.search,
      type: params?.type,
    };
  }, [params]);

  const { data, nextData, reload, setAfter } = useUrqlPaginatedQuery(
    {
      query: AccountingEntriesDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          search: params?.search,
          date: { gte: from, lte: to },
          type: { inList: params?.type },
        },
      },
    },
    [filters],
  );

  const { data: staticData } = useUrqlQuery({ query: AccountingStaticDocument }, []);
  const types = extractQueryData(staticData, "value.value.accountingStatic.type") as Static[];

  const entries = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ accountingEntries }) => Option.fromNullable(accountingEntries))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  return (
    <View style={commonStyles.fill} role="main">
      <Box direction="row" alignItems="center" justifyContent="spaceBetween" style={styles.header}>
        <InputRounded>
          <LakeSearchField
            placeholder={t("common.search")}
            initialValue={""}
            onChangeText={search => Router.push("AccountingLedgerList", { search })}
          />
        </InputRounded>

        <Box direction="row" justifyContent="end" alignItems="end">
          <Calendar />
          <Space width={8} />

          <ListFilter
            filters={filters}
            types={types}
            onChange={filters => Router.push("AccountingLedgerList", filters)}
          />
        </Box>
      </Box>

      <Heading title={t("accounting.ledger")} />

      <Box style={styles.list}>
        {data.match({
          NotAsked: () => null,
          Loading: () => (
            <PlainListViewPlaceholder
              count={NUM_TO_RENDER}
              rowVerticalSpacing={0}
              headerHeight={48}
              rowHeight={48}
            />
          ),
          Done: result =>
            result.match({
              Error: error => <ErrorView error={error} />,
              Ok: data => (
                <DetailsList
                  data={entries as EntryRelay[]}
                  onActiveRowChange={() => {}}
                  onRefreshRequest={reload}
                  onEndReached={() => {
                    if (data?.accountingEntries.pageInfo.hasNextPage === true) {
                      setAfter(data?.accountingEntries?.pageInfo.endCursor ?? undefined);
                    }
                  }}
                  loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER * 2 }}
                  renderEmptyList={() => (
                    <FixedListViewEmpty
                      icon="lake-transfer"
                      borderedIcon={true}
                      title={t("common.empty")}
                    />
                  )}
                />
              ),
            }),
        })}
      </Box>

      <LakeModal
        title={t("accounting.import.title")}
        visible={params?.import != null}
        maxWidth={1000}
        onPressClose={() => {
          Router.push("AccountingLedgerList");
          reload();
        }}
      >
        <ImportWizard
          onPressClose={() => {
            Router.push("AccountingLedgerList");
            reload();
          }}
        />
      </LakeModal>
    </View>
  );
};
