import {
  CreateSupplierDocument,
  OperationInfo,
  SupplierRelay,
  UpdateSupplierDocument,
} from "@graphql/crm";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { CountryCCA3 } from "@swan-io/shared-business/src/constants/countries";
import { validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { subscriptionError, useSubscription } from "../../../utils/subscription";
import {
  handlerFieldErrors,
  tapError,
  validateEmail,
  validateOptional,
  validateRequired,
} from "../../../utils/validations";
import { FormContactState } from "../types";
import { Editor } from "./Editor";

type Props = {
  visible?: boolean;
  supplier?: SupplierRelay;
  onClose?: () => void;
};

export const SupplierCreate = ({ visible = false, supplier, onClose }: Props) => {
  const {
    Field,
    submitForm,
    setFieldValue,
    getFieldState,
    FieldsListener,
    resetForm,
    setFieldError,
  } = useForm<FormContactState>({
    type: { initialValue: "supplier" },
    name: {
      initialValue: supplier?.name ?? "",
      validate: validateRequired,
      sanitize: (value: string) => value.trim(),
    },
    nif: { initialValue: supplier?.nif ?? "" },
    isRoi: { initialValue: supplier?.isRoi ?? false },
    country: {
      initialValue: (supplier?.country as CountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    locality: { initialValue: supplier?.locality ?? "" },
    postalCode: { initialValue: supplier?.postalCode ?? "" },
    phone: { initialValue: supplier?.phone ?? "" },
    email: {
      initialValue: supplier?.email ?? "",
      validate: async value => {
        if (value) {
          return await validateEmail(value);
        }
      },
    },
    address: { initialValue: supplier?.address ?? "" },
    paymentMethod: { initialValue: supplier?.paymentMethod ?? "" },
    iban: { initialValue: supplier?.iban ?? "", validate: validateOptional(validateIban) },
    sequential: { initialValue: supplier?.sequential ?? undefined },
  });

  const { showUpgrade } = useSubscription();

  const [, createSupplier] = useUrqlMutation(CreateSupplierDocument);
  const [, updateSupplier] = useUrqlMutation(UpdateSupplierDocument);

  const onPressCreate = () => {
    submitForm(values => {
      const commonInput = {
        name: values.name ?? "",
        nif: values.nif,
        isRoi: values.isRoi,
        address: values.address,
        locality: values.locality,
        postalCode: values.postalCode,
        country: values.country ?? "ESP",
        phone: values.phone,
        email: values.email,
        paymentMethod: values.paymentMethod,
        iban: values.iban,
        sequential: values.sequential,
      };

      if (supplier) {
        updateSupplier({
          input: {
            ...commonInput,
            id: supplier.id as string,
          },
        })
          .mapOk(data => {
            match(data.updateSupplier)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<FormContactState>(
                  data.updateSupplier as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                resetForm();
                onClose?.();
              });
          })
          .tapError(tapError);
        return;
      }

      createSupplier({
        input: commonInput,
      })
        .mapOk(data => {
          match(data.createSupplier)
            .with({ __typename: "OperationInfo" }, row =>
              match(row.messages[0])
                .with({ field: "numSuppliers" }, () => {
                  showUpgrade({
                    title: t("plan.limitTitle"),
                    description: subscriptionError(row),
                  });
                })
                .otherwise(() =>
                  handlerFieldErrors<FormContactState>(
                    data.createSupplier as OperationInfo,
                    setFieldError,
                  ),
                ),
            )
            .otherwise(() => {
              resetForm();
              onClose?.();
            });
        })
        .tapError(tapError);
    });
  };

  return (
    <LakeModal
      visible={visible}
      title={supplier != null ? t("invoices.editSupplier") : t("invoices.newSupplier")}
      maxWidth={740}
      onPressClose={onClose}
    >
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <Editor
            contactType="supplier"
            contactInfo={supplier}
            large={large}
            Field={Field}
            setFieldValue={setFieldValue}
            getFieldState={getFieldState}
            FieldsListener={FieldsListener}
            onSave={onPressCreate}
          />
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
