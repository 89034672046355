import { Button } from "@components/forms/Button";
import Checkbox from "@components/forms/Checkbox";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import { CreateSeriesDocument, Series, SeriesFormatsDocument } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useState } from "react";
import { Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { getYear } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { substitueValues } from "../../../utils/invoices";
import { extractQueryData, handlerErrors, validateRequired } from "../../../utils/validations";
import { styles } from "../styles/invoices";
import { SeriesCreateForm, SeriesFormat } from "../types";
import { FormatPreview } from "./FormatPreview";

type Props = {
  visible?: boolean;
  onPressClose?: () => void;
};

export const SeriesModal = ({ visible = false, onPressClose }: Props) => {
  const { Field, submitForm, setFieldValue, resetForm } = useForm<SeriesCreateForm>({
    format: { initialValue: "", validate: validateRequired },
    prefix: { initialValue: "" },
    currentSequence: { initialValue: "1", validate: validateRequired },
    reset: { initialValue: false },
    year: { initialValue: String(getYear()) },
  });
  const [showPrefix, setShowPrefix] = useState(false);
  const [format, setFormat] = useState("");
  const [prefix, setPrefix] = useState("");
  const [year, setYear] = useState(String(getYear()));
  const [showYear, setShowYear] = useState(false);
  const [currentSequence, setCurrentSequence] = useState("0001");
  const { data } = useUrqlQuery({ query: SeriesFormatsDocument }, []);
  const [, series] = useUrqlMutation(CreateSeriesDocument);

  const seriesYears = Array.from({ length: 60 }, (_, i) => {
    const year = 2000 + i;
    const yearString = year.toString();
    return { label: yearString, value: yearString, name: yearString };
  });

  const seriesFormats = extractQueryData(data, "value.value.seriesFormats") as SeriesFormat[];

  const handleSubmit = () => {
    submitForm(values => {
      series({
        input: {
          currentSequence: Number(values.currentSequence),
          format: values.format ?? "",
          prefix: values.prefix ?? "",
          reset: values.reset ?? false,
          year: Number(values.year),
        },
      })
        .mapOk(data => {
          match(data.createSeries)
            .with({ __typename: "OperationInfo" }, handlerErrors)
            .otherwise(() => {
              resetForm();
              onPressClose?.();
            });
        })
        .mapError(() => {
          showToast({ variant: "error", title: "safaf", autoClose: true });
        });
    });
  };

  const formatOnChange = (value: string) => {
    setFieldValue("format", value);
    setShowPrefix(value.includes("PREFIX"));
    setShowYear(value.includes("YEAR"));
    setFormat(value);
  };

  const currentSequenceOnChange = (value: string) => {
    const validatedValue = value.replace(/[^0-9]/g, "");
    setFieldValue("currentSequence", validatedValue);
    setCurrentSequence(Number(validatedValue).toString().padStart(4, "0"));
  };

  const prefixOnChange = (value: string) => {
    setFieldValue("prefix", value);
    setPrefix(value);
  };

  const yearOnChange = (value: string) => {
    setFieldValue("year", value);
    setYear(value);
  };

  return (
    <LakeModal visible={visible} title={t("series.new")} maxWidth={740} onPressClose={onPressClose}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {({ large }) => (
          <>
            <Box style={large && styles.verticalBox}>
              <Text>{t("series.choice")}</Text>

              <FormatPreview
                preview={substitueValues({
                  format,
                  prefix,
                  year: parseInt(year),
                  currentSequence: parseInt(currentSequence),
                } as Series)}
              />
            </Box>

            <Box direction={large ? "row" : "column"} style={large && styles.box}>
              <View style={styles.field}>
                <Field name="format">
                  {Select({
                    label: t("series.format"),
                    items: seriesFormats,
                    customOnChange: value => formatOnChange(value),
                    help: t("series.format.help"),
                  })}
                </Field>
              </View>

              <View style={styles.field}>
                <Field name="currentSequence">
                  {Input({
                    label: t("series.sequence"),
                    help: t("series.sequence.help"),
                    customOnChange: value => currentSequenceOnChange(value),
                    maxLength: 4,
                  })}
                </Field>
              </View>
            </Box>

            <Box direction={large ? "row" : "column"} style={large && styles.box}>
              <View style={styles.field}>
                {showPrefix && (
                  <Field name="prefix">
                    {Input({
                      label: t("series.prefix"),
                      help: t("series.prefix.help"),
                      customOnChange: value => prefixOnChange(value),
                    })}
                  </Field>
                )}
              </View>

              <View style={styles.field}>
                {showYear && (
                  <Field name="year">
                    {Select({
                      label: t("series.year"),
                      items: seriesYears,
                      customOnChange: value => yearOnChange(value),
                      help: t("series.year.help"),
                    })}
                  </Field>
                )}
              </View>
            </Box>

            <Box direction={large ? "row" : "column"} style={large && styles.box}>
              <Field name="reset">
                {Checkbox({
                  children: <Text>{t("series.reset")}</Text>,
                })}
              </Field>
            </Box>

            <Box alignItems="end">
              <Button onPress={handleSubmit} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
