import { Suspendable } from "@components/Suspendable";
import { FocusTrap, FocusTrapRef } from "@swan-io/lake/src/components/FocusTrap";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Portal } from "@swan-io/lake/src/components/Portal";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Context, ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { TransitionView } from "@swan-io/lake/src/components/TransitionView";
import { backgroundColor, breakpoints } from "@swan-io/lake/src/constants/design";
import { useBodyClassName } from "@swan-io/lake/src/hooks/useBodyClassName";
import { ReactNode, forwardRef, useEffect, useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { borderColorVariants } from "../styles/constants";

const BACKGROUND_COLOR = "rgba(0, 0, 0, 0.6)";

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
  nonFill: {
    left: "auto",
    width: 400,
  },
  inert: {
    pointerEvents: "none",
  },
  fill: {
    ...StyleSheet.absoluteFillObject,
  },
  overlayEnter: {
    animationKeyframes: {
      "0%": { opacity: 0 },
    },
    animationDuration: "200ms",
    animationTimingFunction: "ease-in-out",
  },
  overlayLeave: {
    animationKeyframes: {
      "100%": { opacity: 0 },
    },
    animationDuration: "200ms",
    animationTimingFunction: "ease-in-out",
  },
  containerEnter: {
    animationKeyframes: {
      "0%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  containerLeave: {
    animationKeyframes: {
      "100%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: BACKGROUND_COLOR,
  },
  container: {
    ...StyleSheet.absoluteFillObject,
    left: "auto",
    width: 400,
    transform: "translateZ(0px)",
    flexDirection: "row",
    alignItems: "stretch",
    backgroundColor: backgroundColor.default,
    borderLeftWidth: 1,
    borderLeftColor: borderColorVariants.gray100,
  },
  fillMax: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    width: "100%",
  },
  fillMaxLarge: { maxWidth: 400 },
  contentsEnter: {
    animationKeyframes: {
      "0%": { transform: "translateZ(0px) translateX(25px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsLeave: {
    animationKeyframes: {
      "100%": { transform: "translateZ(0px) translateX(25px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsContainer: {
    ...StyleSheet.absoluteFillObject,
    transform: "translateZ(0px)",
    maxWidth: "100%",
    flexGrow: 1,
    overflowY: "overlay",
  },
  contents: {
    ...StyleSheet.absoluteFillObject,
    maxWidth: "100%",
    flexGrow: 1,
  },
});

type Props = {
  visible: boolean;
  overlay?: boolean;
  children: ReactNode | ((context: Context) => ReactNode);
  onPressClose?: () => void;
  style?: ViewStyle;
};

const rootNode = document.querySelector("#right-panel") as Element;

export const RightPanel = forwardRef<FocusTrapRef, Props>(
  ({ visible, overlay = false, children, onPressClose, style }: Props, ref) => {
    const [delayedVisible, setDelayedVisible] = useState(visible);

    useEffect(() => {
      setDelayedVisible(visible);
    }, [visible]);

    useBodyClassName("RightPanelOpen", { enabled: visible });

    return (
      <Portal container={rootNode}>
        <View style={[styles.root, !overlay && styles.nonFill, !visible && styles.inert]}>
          <ResponsiveContainer
            style={[styles.root, !overlay && styles.nonFill]}
            breakpoint={breakpoints.small}
          >
            {({ large }) => (
              <>
                {overlay && (
                  <TransitionView
                    style={styles.fill}
                    enter={styles.overlayEnter}
                    leave={styles.overlayLeave}
                  >
                    {visible ? (
                      onPressClose != null ? (
                        <Pressable style={styles.overlay} onPress={onPressClose} />
                      ) : (
                        <View style={styles.overlay} />
                      )
                    ) : null}
                  </TransitionView>
                )}

                <Suspendable fallback={<LoadingView color={backgroundColor.accented} delay={0} />}>
                  <TransitionView
                    style={[styles.fillMax, large && styles.fillMaxLarge]}
                    enter={styles.containerEnter}
                    leave={styles.containerLeave}
                  >
                    {delayedVisible ? (
                      <FocusTrap
                        onEscapeKey={onPressClose}
                        focusLock={true}
                        autoFocus={false}
                        returnFocus={true}
                        ref={ref}
                        style={[styles.container, style]}
                      >
                        <TransitionView
                          style={styles.contentsContainer}
                          enter={styles.contentsEnter}
                          leave={styles.contentsLeave}
                        >
                          <View style={styles.contents}>
                            <View style={styles.contentsContainer}>
                              {typeof children == "function" ? (
                                <ResponsiveContainer
                                  style={styles.root}
                                  breakpoint={breakpoints.small}
                                >
                                  {children}
                                </ResponsiveContainer>
                              ) : (
                                children
                              )}
                            </View>
                          </View>
                        </TransitionView>
                      </FocusTrap>
                    ) : null}
                  </TransitionView>
                </Suspendable>
              </>
            )}
          </ResponsiveContainer>
        </View>
      </Portal>
    );
  },
);
