import { ErrorView } from "@components/ErrorView";
import { Loader } from "@components/Loader";
import { MainSidebar } from "@components/MainSidebar";
import { Modal } from "@components/Modal";
import { ProjectRootRedirect } from "@components/ProjectRootRedirect";
import { SubscriptionModal } from "@components/SubscriptionModal";
import { ToastStack } from "@components/ToastStack";
import { Unpaid } from "@components/Unpaid";
import { Box } from "@swan-io/lake/src/components/Box";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Suspense } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { Provider } from "urql";
import { Area as AccountingArea } from "./features/accounting/screens/Area";
import { Company } from "./features/company/screens/Company";
import { Area as CrmArea } from "./features/contacts/screens/Area";
import { LoadingProvider } from "./features/context/LoadingContext";
import { ModalProvider } from "./features/context/ModalContext";
import { UserProvider } from "./features/context/UserContext";
import { Area as HrArea } from "./features/hr/screens/Area";
import { Payroll } from "./features/hr/screens/Payroll";
import { PurchaseCreate } from "./features/invoices/components/PurchaseCreate";
import { RectificationCreate } from "./features/invoices/components/RectificationCreate";
import { SalesCreate } from "./features/invoices/components/SalesCreate";
import { TicketCreate } from "./features/invoices/components/TicketCreate";
import { ProformaCreate } from "./features/invoices/components/proforma/ProformaCreate";
import { Area as InvoicesArea } from "./features/invoices/screens/Area";
import { Projects } from "./features/projects/screens/Projects";
import { Area as ReportsArea } from "./features/reports/screens/Area";
import { ActivatePage } from "./pages/ActivatePage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage";
import { DesignSystemPage } from "./pages/DesignSystemPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { OnboardingPage } from "./pages/OnboardingPage";
import { PlansPage } from "./pages/PlansPage";
import { RecoveryPage } from "./pages/RecoveryPage";
import { SigninPage } from "./pages/SigninPage";
import { SignupPage } from "./pages/SignupPage";
import { Summary } from "./pages/Summary";
import { User } from "./pages/UserPage";
import { common } from "./styles/common";
import { Router } from "./utils/routes";
import { crmClient, isUnauthorizedError } from "./utils/urql";
import "@assets/css/normalize-inputs.css"

const styles = StyleSheet.create({
  base: {
    backgroundColor: colors.gray[50],
    flexGrow: 1,
  },
});

export const App = () => {
  const route = Router.useRoute([
    "ProjectRootRedirect",
    "DesignSystemPage",
    "SigninPage",
    "SignupPage",
    "RecoveryPage",
    "ChangePassword",
    "ActivateRoot",
    "ActivateEmail",
    "PlansPage",
    "OnboardingPage",
    "SummaryArea",
    "UserArea",
    "CompanyArea",
    "CrmArea",
    "InvoicesArea",
    "InvoicesSalesCreate",
    "InvoicesSalesSimplifiedCreate",
    "InvoicesSalesProformaCreate",
    "InvoicesSalesProformaDetail",
    "InvoicesSalesQuoteCreate",
    "InvoicesSalesQuoteDetail",
    "InvoicesSalesDeliveryCreate",
    "InvoicesSalesDeliveryDetail",
    "InvoicesSalesRectify",
    "InvoicesSalesRectifyDetail",
    "InvoicesPurchaseCreate",
    "InvoicesPurchaseSimplifiedCreate",
    "InvoicesTicketCreate",
    "ReportsArea",
    "HrArea",
    "HrPayrollsCreate",
    "HrPayrollsUpdate",
    "AccountingArea",
    "ProjectsRoot",
  ]);

  return (
    <LoadingProvider>
      <ErrorBoundary
        fallback={({ error }) =>
          isUnauthorizedError(error) ? <></> : <ErrorView error={error} style={styles.base} />
        }
      >
        <Loader />

        <Provider value={crmClient}>
          <Suspense fallback={<LoadingView color={colors.gray[400]} style={styles.base} />}>
            <UserProvider>
              <ModalProvider>
                <Unpaid />
                <Modal />

                <Box direction="row" style={common.h100}>
                  <MainSidebar />

                  {match(route)
                    .with({ name: "ProjectRootRedirect" }, () => <ProjectRootRedirect />)
                    .with({ name: "DesignSystemPage" }, () => <DesignSystemPage />)
                    .with({ name: "SigninPage" }, ({ params: { sessionExpired } }) => (
                      <SigninPage sessionExpired={isNotNullishOrEmpty(sessionExpired)} />
                    ))
                    .with({ name: "SignupPage" }, () => <SignupPage />)
                    .with({ name: "ActivateRoot" }, () => <ActivatePage />)
                    .with({ name: "ActivateEmail" }, ({ params: { uid, token } }) => (
                      <ActivatePage uid={uid} token={token} />
                    ))
                    .with({ name: "RecoveryPage" }, () => <RecoveryPage />)
                    .with({ name: "ChangePassword" }, ({ params }) => (
                      <ChangePasswordPage params={params} />
                    ))
                    .with({ name: "PlansPage" }, () => <PlansPage />)
                    .with({ name: "OnboardingPage" }, () => <OnboardingPage />)
                    .with({ name: "SummaryArea" }, () => <Summary />)
                    .with({ name: "UserArea" }, () => <User />)
                    .with({ name: "CompanyArea" }, () => <Company />)
                    .with({ name: "CrmArea" }, () => <CrmArea />)
                    .with({ name: "InvoicesArea" }, () => <InvoicesArea />)
                    .with({ name: "InvoicesSalesCreate" }, () => <SalesCreate visible={true} />)
                    .with({ name: "InvoicesSalesSimplifiedCreate" }, () => (
                      <SalesCreate visible={true} typeCode="F2" />
                    ))
                    .with(
                      { name: "InvoicesSalesProformaCreate" },
                      { name: "InvoicesSalesProformaDetail" },
                      { name: "InvoicesSalesQuoteCreate" },
                      { name: "InvoicesSalesQuoteDetail" },
                      { name: "InvoicesSalesDeliveryCreate" },
                      { name: "InvoicesSalesDeliveryDetail" },
                      () => <ProformaCreate />,
                    )
                    .with(
                      { name: "InvoicesSalesRectify" },
                      { name: "InvoicesSalesRectifyDetail" },
                      () => <RectificationCreate visible={true} />,
                    )
                    .with({ name: "InvoicesPurchaseCreate" }, () => (
                      <PurchaseCreate visible={true} />
                    ))
                    .with({ name: "InvoicesPurchaseSimplifiedCreate" }, () => (
                      <PurchaseCreate visible={true} typeCode="F2" />
                    ))
                    .with({ name: "InvoicesTicketCreate" }, () => <TicketCreate visible={true} />)
                    .with({ name: "ReportsArea" }, () => <ReportsArea />)
                    .with({ name: "HrArea" }, () => <HrArea />)
                    .with({ name: "HrPayrollsCreate" }, ({ params: { employeeId } }) => (
                      <Payroll employeeId={employeeId} />
                    ))
                    .with({ name: "HrPayrollsUpdate" }, ({ params: { payrollId } }) => (
                      <Payroll payrollId={payrollId} />
                    ))
                    .with({ name: "AccountingArea" }, () => <AccountingArea />)
                    .with({ name: "ProjectsRoot" }, () => <Projects />)
                    .with(P.nullish, () => <NotFoundPage style={styles.base} />)
                    .exhaustive()}
                </Box>
              </ModalProvider>
            </UserProvider>
          </Suspense>
        </Provider>

        <ToastStack />
        <SubscriptionModal />
      </ErrorBoundary>
    </LoadingProvider>
  );
};
