import { Button } from "@components/forms/Button";
import { FormEnter } from "@components/forms/FormEnter";
import Input from "@components/forms/Input";
import {
  ContactStatic,
  ContactsStaticDocument,
  CustomerRelay,
  Static,
  SupplierRelay,
} from "@graphql/crm";
import { Lazy } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { allCountries } from "@swan-io/shared-business/src/constants/countries";
import { printIbanFormat } from "@swan-io/shared-business/src/utils/validation";
import { useState } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { Form } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { Contact, FormContactState } from "../types";

const styles = StyleSheet.create({
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "flex-start",
    gap: 32,
  },
  row3: {
    gridTemplateColumns: "1fr .5fr 1fr",
  },
  separator: {
    marginVertical: 16,
  },
  row_roi: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
  },
});

type Props = {
  contactType: string;
  contactInfo?: Contact | CustomerRelay | SupplierRelay;
  Field: Form<FormContactState>["Field"];
  setFieldValue: Form<FormContactState>["setFieldValue"];
  getFieldState: Form<FormContactState>["getFieldState"];
  FieldsListener: Form<FormContactState>["FieldsListener"];
  large: boolean;
  onSave: () => void;
  showAddress?: boolean;
  children?: React.ReactNode;
};

const countryItems = Lazy(() => allCountries.filter(cca3 => cca3 !== "USA"));

export const Editor = ({
  contactType,
  contactInfo,
  Field,
  setFieldValue,
  getFieldState,
  FieldsListener,
  large,
  onSave,
  showAddress = true,
  children,
}: Props) => {
  const { data } = useUrqlQuery({ query: ContactsStaticDocument }, []);

  const extractStatic = (staticType: keyof ContactStatic) =>
    data
      .toOption()
      .flatMap(result => result.toOption())
      .map(types => types.contactsStatic[staticType] as Static[])
      .getWithDefault([]);

  const paymentMethodItems = extractStatic("paymentMethods");
  const [paymentMethodType, setPaymentMethodType] = useState(
    getFieldState?.("paymentMethod").value ?? "",
  );

  return (
    <FormEnter onSubmit={onSave}>
      <Box direction={large ? "row" : "column"} style={large && styles.row}>
        <Field name="name">
          {Input({
            label: t("contact.name"),
          })}
        </Field>

        <Box direction="row" alignItems="end" style={styles.row_roi}>
          <Field name="nif">
            {Input({
              label: t("contact.nif"),
            })}
          </Field>

          <Field name="isRoi">
            {({ value, onChange, error }) => (
              <Pressable onPress={() => onChange(value !== undefined ? false : true)}>
                <Box direction="row" alignItems="center">
                  <LakeCheckbox value={value ?? false} />
                  <Space width={8} />
                  <Text>{t("common.roi")}</Text>
                </Box>

                {error != null && <Text>{error}</Text>}
              </Pressable>
            )}
          </Field>
        </Box>
      </Box>

      {large && <Space height={16} />}

      <Box direction={large ? "row" : "column"} style={large && styles.row}>
        <Field name="phone">
          {Input({
            label: t("common.phone"),
          })}
        </Field>

        <Field name="email">
          {Input({
            label: t("common.email"),
          })}
        </Field>
      </Box>

      {large && <Space height={16} />}

      <Box direction={large ? "row" : "column"} style={large && styles.row}>
        <Field name="sequential">
          {({ value, error, onChange }) => (
            <LakeLabel
              label={t("common.subaccount")}
              render={id => (
                <LakeTextInput
                  id={id}
                  value={value?.toString() ?? ""}
                  onChangeText={value =>
                    isNaN(parseInt(value)) ? onChange(undefined) : onChange(parseInt(value))
                  }
                  inputMode="numeric"
                  error={error}
                  hideErrors={error == undefined}
                  disabled={contactInfo?.sequential != null && contactInfo?.sequential > 0}
                />
              )}
            />
          )}
        </Field>
      </Box>

      {showAddress && (
        <>
          {large && <Separator style={styles.separator} />}

          <FieldsListener names={["country"]}>
            {({ country }) => (
              <Field name="address">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("contact.address")}
                    render={id => (
                      <PlacekitAddressSearchInput
                        shouldDisplaySuggestions={true}
                        id={id}
                        apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                        country={country.value}
                        value={value}
                        onValueChange={onChange}
                        onSuggestion={suggestion => {
                          setFieldValue("address", suggestion.completeAddress);
                        }}
                        language="es"
                        placeholder={t("contact.address.placeHolder")}
                        emptyResultText={t("common.empty")}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>
            )}
          </FieldsListener>

          <Box direction={large ? "row" : "column"} style={[large && styles.row, styles.row3]}>
            <Field name="locality">
              {Input({
                label: t("contact.locality"),
              })}
            </Field>

            <Field name="postalCode">
              {Input({
                label: t("contact.postalCode"),
                maxLength: 5,
              })}
            </Field>

            <Field name="country">
              {({ value, onChange, ref, error }) => (
                <LakeLabel
                  label={t("common.country")}
                  render={id => (
                    <CountryPicker
                      id={id}
                      ref={ref}
                      value={value}
                      error={error}
                      countries={countryItems.get()}
                      onValueChange={onChange}
                    />
                  )}
                />
              )}
            </Field>
          </Box>
        </>
      )}

      {large && <Separator style={styles.separator} />}
      {!large && <Space height={16} />}

      {match(contactType.toLowerCase())
        .with("supplier", () => (
          <Box direction={large ? "row" : "column"} style={large && styles.row}>
            <Field name="paymentMethod">
              {({ value, onChange, error, ref }) => (
                <LakeLabel
                  label={t("contact.paymentMethod")}
                  render={id => (
                    <LakeSelect
                      id={id}
                      ref={ref}
                      value={value}
                      error={error}
                      items={paymentMethodItems}
                      onValueChange={value => {
                        onChange(value);
                        setPaymentMethodType(value);
                      }}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="iban">
              {({ value, onChange, error, ref }) => (
                <LakeLabel
                  label={t("contact.iban")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      ref={ref}
                      value={printIbanFormat(value ?? "")}
                      error={error}
                      hideErrors={error === undefined}
                      disabled={!["transferencia", "domiciliacion"].includes(paymentMethodType)}
                      onChangeText={onChange}
                    />
                  )}
                />
              )}
            </Field>
          </Box>
        ))
        .otherwise(() => null)}

      {children}

      <Box alignItems="end">
        <Button onPress={onSave} icon={<SaveIcon />} reverse={true}>
          {t("common.save")}
        </Button>
      </Box>
    </FormEnter>
  );
};
