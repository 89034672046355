import { LakeTextInput, LakeTextInputProps } from "@swan-io/lake/src/components/LakeTextInput";
import { useEffect, useRef, useState } from "react";
import { TextInput } from "react-native";
import { toDecimalPlaces, toNumber } from "../utils/decimal";

type Props = {
  onChangeDecimal?: (value: string) => void;
};

export const DecimalInput = (props: LakeTextInputProps & Props) => {
  const input = useRef<TextInput>(null);
  const [currentValue, setCurrentValue] = useState(props.value);

  useEffect(() => {
    if (document.activeElement === input.current) {
      return;
    }
    setCurrentValue(props.value);
  }, [props.value]);

  const valueCleanup = (value: string) => {
    const valueTrimmed = value.trim();
    const valueWithoutMinus = valueTrimmed.replace(/-/g, (match, offset) =>
      offset === 0 ? match : "",
    );
    const valueWithoutComma = valueWithoutMinus.replace(/,/g, ".");
    const valueWithoutMultipleDots = valueWithoutComma.replace(/(\.)(?=.*\.)/g, "");
    return valueWithoutMultipleDots;
  };

  const valueDecimal = (value: string) => {
    if (!value) {
      return "";
    }
    const numberValue = toNumber(valueCleanup(value));
    return toDecimalPlaces(numberValue);
  };

  return (
    <LakeTextInput
      {...props}
      ref={input}
      value={currentValue}
      inputMode="decimal"
      onKeyPress={e => {
        const key = e.nativeEvent.key;
        if (key.match(/Backspace|Delete|Arrow|Tab|Enter/)) {
          return;
        }
        if (!key.match(/[0-9,.-]/)) {
          e.preventDefault();
        }
      }}
      onChangeText={value => {
        setCurrentValue(valueCleanup(value));
        props.onChangeDecimal?.(valueDecimal(value));
      }}
      onBlur={e => {
        const value = e.nativeEvent.text;
        setCurrentValue(valueDecimal(value));
      }}
      hideErrors={props.error === undefined}
    />
  );
};
