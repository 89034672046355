import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import { FormEnter } from "@components/forms/FormEnter";
import Input from "@components/forms/Input";
import {
  OperationInfo,
  PaidPurchaseInvoiceDocument,
  PaidPurchaseInvoiceOutput,
  PurchaseInvoiceRelay,
} from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { validateOptional, validateRequired } from "../../../utils/validations";
import { PaymentMethodsForm } from "../../company/components/PaymentMethodsForm";
import { useLoading } from "../../context/LoadingContext";
import { useModal } from "../../context/ModalContext";
import { useUser } from "../../context/UserContext";

const styles = StyleSheet.create({
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  action: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 4,
  },
  actionText: {
    fontSize: 12,
    fontWeight: "500",
    color: fontColorVariants.neutral500,
  },
});

type Props = {
  visible: boolean;
  onPressClose: () => void;
  invoice: PurchaseInvoiceRelay | undefined;
};

type PaidForm = {
  amount: string;
  name: string;
  iban: string;
  paymentMethod: string;
  commission: string;
};

export const PurchaseUpdateStatusModal = ({ visible, onPressClose, invoice }: Props) => {
  const { company } = useUser();
  const { setModal, closeModal } = useModal();

  const [open, setOpen] = useState(visible);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const [, paidPurchaseInvoice] = useUrqlMutation(PaidPurchaseInvoiceDocument);
  const { Field, setFieldValue, submitForm } = useForm<PaidForm>({
    amount: { initialValue: "", validate: validateRequired },
    name: { initialValue: "", validate: validateRequired },
    iban: { initialValue: "", validate: validateOptional(validateIban) },
    paymentMethod: { initialValue: "", validate: validateRequired },
    commission: { initialValue: "0.00" },
  });

  useEffect(() => {
    setFieldValue("amount", String(invoice?.total) ?? "");
    setFieldValue("name", invoice?.issuer?.name ?? "");
    setFieldValue("iban", invoice?.issuer?.iban ?? "");
  }, [invoice]);

  const { setLoading } = useLoading();

  const handleSubmit = () => {
    submitForm(values => {
      setLoading(true);

      paidPurchaseInvoice({
        input: {
          invoiceId: invoice?.id as string,
          amount: values.amount ?? "",
          name: values.name ?? "",
          iban: values.iban ?? "",
          paymentMethodId: values.paymentMethod ?? "",
          commission: values.commission ?? "0",
        },
      }).mapOk(data => {
        match(data.paidPurchaseInvoice)
          .with({ __typename: "OperationInfo" }, () =>
            showToast({
              variant: "error",
              title: (data?.paidPurchaseInvoice as OperationInfo)?.messages[0]?.message ?? "",
              autoClose: true,
            }),
          )
          .otherwise(() => {
            const oauthUrl = Option.fromNullable(
              (data?.paidPurchaseInvoice as PaidPurchaseInvoiceOutput).oauthUrl,
            );
            if (oauthUrl.isSome() && oauthUrl.value !== "") {
              window.location.replace(oauthUrl.value);
            }
            const consentUrl = Option.fromNullable(
              (data?.paidPurchaseInvoice as PaidPurchaseInvoiceOutput).consentUrl,
            );
            consentUrl.match({
              Some: consentUrl => {
                setLoading(false);
                if (consentUrl !== "") {
                  window.location.replace(consentUrl);
                  return consentUrl;
                } else {
                  onPressClose();
                }
              },
              None: () =>
                showToast({
                  variant: "error",
                  title: (data?.paidPurchaseInvoice as PaidPurchaseInvoiceOutput).message ?? "",
                  autoClose: true,
                }),
            });
          });
        setLoading(false);
      });
    });
  };

  const paymentMethods = useMemo(
    () => company?.paymentMethods.filter(pm => !pm.archived) ?? [],
    [company],
  );

  return (
    <LakeModal visible={open} onPressClose={onPressClose} title={t("invoices.purchase.paidTitle")}>
      <ResponsiveContainer breakpoint={breakpoints.tiny}>
        {() => (
          <FormEnter onSubmit={handleSubmit}>
            <Box
              onTouchEnd={e => {
                e.stopPropagation();
              }}
            >
              <Field name="amount">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("common.amount")}
                    style={styles.column}
                    extra={() => <Text style={styles.red}>*</Text>}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={String(value)}
                        onChangeDecimal={nextValue => onChange(nextValue)}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="name">
                {Input({
                  label: t("invoices.purchase.paidBeneficiary"),
                  required: true,
                })}
              </Field>

              <Space height={12} />

              <LakeLabel
                label={t("contact.iban")}
                render={id => (
                  <Field name="iban">
                    {({ value, onChange, onBlur, error, validating, ref }) => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={printIbanFormat(value)}
                        validating={validating}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeText={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  </Field>
                )}
              />

              <Space height={12} />

              <Field name="paymentMethod">
                {({ value, onChange, error, ref }) => (
                  <LakeLabel
                    label={t("common.paymentMethod")}
                    extra={() => <Text style={styles.red}>*</Text>}
                    render={() => (
                      <LakeSelect
                        ref={ref}
                        items={paymentMethods.map(pm => ({ name: pm.name, value: pm.id })) ?? []}
                        value={value}
                        onValueChange={onChange}
                        error={error}
                        hideErrors={error == undefined}
                      />
                    )}
                    style={styles.actions}
                    actions={
                      <Box direction="row" justifyContent="spaceBetween">
                        <Pressable
                          style={styles.action}
                          onPress={() => {
                            setOpen(false);
                            setModal({
                              visible: true,
                              title: t("company.newPaymentMethod"),
                              children: (
                                <PaymentMethodsForm
                                  onCallback={value => {
                                    closeModal();
                                    setOpen(true);
                                    onChange(value.id);
                                  }}
                                />
                              ),
                              maxWidth: 650,
                              onPressClose: () => {
                                closeModal();
                                setOpen(true);
                              },
                            });
                          }}
                        >
                          <Icon name="add-filled" size={12} color={fontColorVariants.neutral500} />
                          <Text style={styles.actionText}>{t("company.newPaymentMethod")}</Text>
                        </Pressable>
                      </Box>
                    }
                  />
                )}
              </Field>

              <Space height={12} />

              <Field name="commission">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("common.bankCommission")}
                    style={styles.column}
                    render={id => (
                      <DecimalInput
                        id={id}
                        value={value}
                        onChangeDecimal={nextValue => onChange(nextValue)}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>

            <Space height={24} />

            <Box alignItems="end">
              <Button onPress={handleSubmit} icon={<SaveIcon />} reverse={true}>
                {t("common.save")}
              </Button>
            </Box>
          </FormEnter>
        )}
      </ResponsiveContainer>
    </LakeModal>
  );
};
