import { Heading } from "@components/Heading";
import { useNestedForm } from "@components/NestedForm";
import { UploadShowLogoCompany } from "@components/UploadShowLogoCompany";
import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import {
  CompanyAdmin,
  CompanyRepresentative,
  CompanyStatic,
  CompanyStaticDocument,
  OperationInfo,
  UpdateCompanyDocument,
} from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { MultiSelect } from "@swan-io/lake/src/components/MultiSelect";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { encodeShortDate } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { tapError, validateRequired } from "../../../utils/validations";
import { useUser } from "../../context/UserContext";
import { Admin } from "../components/Admin";
import { Representative } from "../components/Representative";
import { UpdateCompanyState } from "../types";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  tabs: {
    margin: 0,
    "--color-gray-100": backgroundColorVariants.gray400,
  },
  form: {
    maxWidth: 800,
    paddingHorizontal: 32,
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
  },
  row4: {
    gridTemplateColumns: ".4fr .2fr .2fr .2fr",
  },
  note: {
    color: fontColorVariants.neutral500,
  },
  multiselect: {
    marginBottom: -32,
  },
  gap: {
    gap: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: fontColorVariants.neutral900,
  },
  admins: {
    gap: 16,
    backgroundColor: backgroundColorVariants.sidebar,
    borderRadius: 8,
    paddingHorizontal: 48,
    paddingVertical: 32,
  },
});

const CONSTITUTION = {
  AUTONOMO: "autonomo",
  EMPRESA: "empresa",
};

export const CompanyForm = () => {
  const { company } = useUser();

  const [activeTabId, setActiveTabId] = useState("social");

  let staticData = {} as CompanyStatic;
  const { data } = useUrqlQuery({ query: CompanyStaticDocument }, []);
  data.mapOk(data => (staticData = data.companyStatic));

  const { onSubmit, setMainForm } = useNestedForm();
  const [submit, setSubmit] = useState(false);

  const onClick = () => {
    setSubmit(true);
    setMainForm(() => {
      return () => onSave();
    });
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
      setSubmit(false);
    }
  }, [submit]);

  const { Field, setFieldError, setFieldValue, getFieldState, submitForm } =
    useForm<UpdateCompanyState>({
      name: { initialValue: company?.name ?? "" },
      nif: { initialValue: company?.nif ?? "" },
      phone: {
        initialValue: company?.phone ?? "",
        validate: validateRequired,
      },
      email: { initialValue: company?.email ?? "" },
      isRoi: { initialValue: company?.isRoi ?? false },
      constitution: { initialValue: company?.constitution ?? "", validate: validateRequired },
      companyType: { initialValue: company?.companyType ?? "", validate: validateRequired },
      numEmployees: { initialValue: company?.numEmployees ?? "", validate: validateRequired },
      status: { initialValue: company?.status ?? "", validate: validateRequired },
      ccc: { initialValue: company?.ccc ?? "" },
      sequentialSize: { initialValue: company?.sequentialSize ?? undefined },
      sector: { initialValue: company?.sector ?? "" },
      iaes: { initialValue: company?.iaes?.map(item => item.iae.id) ?? [] },
      cnaes: { initialValue: company?.cnaes?.map(item => item.cnae.code) ?? [] },
      ivaRegime: { initialValue: company?.ivaRegime ?? "" },
      fiscalRegime: { initialValue: company?.fiscalRegime ?? "" },
      registryId: { initialValue: company?.registry?.pk ?? "" },
      volume: { initialValue: company?.volume ?? "" },
      folio: { initialValue: company?.folio ?? "" },
      sheet: { initialValue: company?.sheet ?? "" },
      admins: { initialValue: company?.admins },
      representatives: { initialValue: company?.representatives },
    });

  const [, updateCompany] = useUrqlMutation(UpdateCompanyDocument);

  const onSave = () => {
    submitForm(values => {
      updateCompany({
        input: {
          ...values,
          id: company?.id,
          name: company?.name ?? "",
          admins: values.admins?.map((admin: Partial<CompanyAdmin>) => {
            if (admin.id?.length === 36) {
              delete admin.id;
            }
            delete admin.__typename;
            return admin;
          }) as CompanyAdmin[],
          representatives: values.representatives?.map(
            (representative: Partial<CompanyRepresentative>) => {
              if (representative.id?.length === 36) {
                delete representative.id;
              }
              delete representative.__typename;
              return {
                ...representative,
                powerDate:
                  representative.powerDate != null
                    ? encodeShortDate(representative.powerDate)
                    : undefined,
              };
            },
          ) as CompanyRepresentative[],
          iaes: values.iaes ?? company?.iaes?.map(item => item.iae.id), // always send values
          registryId: values.registryId != null ? parseInt(values.registryId) : undefined,
        },
      })
        .tapError(tapError)
        .mapOk(data => {
          match(data.updateCompany)
            .with({ __typename: "OperationInfo" }, () => {
              for (const error of (data.updateCompany as OperationInfo).messages) {
                setFieldError(error?.field as keyof UpdateCompanyState, error?.message ?? "");
                showToast({
                  variant: "error",
                  title: error.message,
                  autoClose: true,
                });
              }
            })
            .otherwise(() => {
              showToast({
                variant: "success",
                title: t("common.savedSuccessfully"),
                autoClose: true,
              });
            });
        });
    });
  };

  const tabs = [
    {
      id: "social",
      label: t("company.socialData"),
    },
    {
      id: "fiscal",
      label: t("company.fiscalData"),
    },
  ];

  if (company?.constitution == CONSTITUTION.EMPRESA) {
    tabs.push({
      id: "adminsAndReps",
      label: t("company.adminsAndReps"),
    });
  }

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="end" style={styles.header}></Box>
      <Heading title={t("common.company")} />

      <View style={styles.form}>
        <View style={styles.tabs}>
          <TabView tabs={tabs} otherLabel="" activeTabId={activeTabId} onChange={setActiveTabId} />
        </View>

        <Space height={24} />

        {activeTabId === "social" && (
          <Box style={styles.gap}>
            <Box direction="row" alignItems="start" style={styles.row}>
              <View>
                <LakeLabel
                  label={t("user.loadImage")}
                  render={() => <UploadShowLogoCompany logoUrl={company?.logoUrl ?? ""} />}
                />
              </View>
            </Box>

            <Box direction="row" alignItems="start" style={styles.row}>
              <Field name="name">
                {Input({
                  label:
                    company?.constitution == CONSTITUTION.AUTONOMO
                      ? t("common.nameComplete")
                      : t("company.name"),
                  disabled: true,
                })}
              </Field>

              <Box direction="row" alignItems="center" style={styles.row}>
                <Field name="nif">
                  {Input({
                    label: t("contact.nif"),
                    disabled: true,
                  })}
                </Field>

                <Field name="isRoi">
                  {({ value, onChange, error }) => (
                    <Pressable onPress={() => onChange(value !== undefined ? false : true)}>
                      <Box direction="row" alignItems="center">
                        <LakeCheckbox value={value ?? false} />
                        <Space width={8} />
                        <Text>{t("common.roi")}</Text>
                      </Box>

                      {error != null && <Text>{error}</Text>}
                    </Pressable>
                  )}
                </Field>
              </Box>
            </Box>

            <Text style={styles.note}>{t("user.infoNotEditable")}</Text>

            <Box direction="row" alignItems="start" style={styles.row}>
              <Field name="email">
                {Input({
                  label: t("common.email"),
                })}
              </Field>

              <Field name="phone">
                {Input({
                  label: t("common.phone"),
                })}
              </Field>
            </Box>

            <Box direction="row" alignItems="start" style={styles.row}>
              {company?.constitution == CONSTITUTION.AUTONOMO && (
                <Field name="status">
                  {Select({
                    label: t("onboarding.status"),
                    items: staticData.status?.map(item => ({
                      value: item.id,
                      name: item.name,
                    })),
                  })}
                </Field>
              )}

              {company?.constitution == CONSTITUTION.EMPRESA && (
                <Field name="companyType">
                  {Select({
                    label: t("onboarding.companyType"),
                    items: staticData.types?.map(item => ({
                      value: item.id,
                      name: item.name,
                    })),
                  })}
                </Field>
              )}

              <Field name="numEmployees">
                {Select({
                  label: t("onboarding.numEmployees"),
                  items: staticData.employees?.map(item => ({
                    value: item.id,
                    name: item.name,
                  })),
                })}
              </Field>
            </Box>

            {company?.constitution == CONSTITUTION.EMPRESA && (
              <Box direction="row" alignItems="start" style={[styles.row, styles.row4]}>
                <Field name="registryId">
                  {Select({
                    label: t("company.registry"),
                    items: staticData.registries?.map(item => ({
                      value: item.id,
                      name: item.name,
                    })),
                  })}
                </Field>

                <Field name="volume">
                  {Input({
                    label: t("company.volume"),
                  })}
                </Field>

                <Field name="folio">
                  {Input({
                    label: t("company.folio"),
                  })}
                </Field>

                <Field name="sheet">
                  {Input({
                    label: t("company.sheet"),
                  })}
                </Field>
              </Box>
            )}
          </Box>
        )}

        {activeTabId === "fiscal" && (
          <Box style={styles.gap}>
            <Box direction="row" alignItems="start" style={styles.row}>
              <Field name="ccc">
                {Input({
                  label: t("user.ccc"),
                })}
              </Field>

              <Field name="sequentialSize">
                {({ value, error, onChange }) => (
                  <LakeLabel
                    label={t("user.sequentialSize")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value?.toString()}
                        onChangeText={value =>
                          isNaN(parseInt(value)) ? onChange(0) : onChange(parseInt(value))
                        }
                        inputMode="numeric"
                        error={error}
                        hideErrors={error == undefined}
                      />
                    )}
                  />
                )}
              </Field>
            </Box>

            <Field name="iaes">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("company.iae")}
                  style={styles.multiselect}
                  render={() => (
                    <MultiSelect
                      items={
                        staticData.iaes?.map(item => ({
                          value: item.id,
                          label: item.name.slice(0, 80),
                          group: t("company.iae"),
                        })) ?? []
                      }
                      values={value ?? []}
                      onValueChange={onChange}
                      error={error}
                    />
                  )}
                />
              )}
            </Field>

            {company?.constitution == CONSTITUTION.AUTONOMO && (
              <Box direction="row" alignItems="start" style={styles.row}>
                <Field name="sector">
                  {Select({
                    label: t("company.sector"),
                    items: staticData.sectors?.map(item => ({
                      value: item.id,
                      name: item.name,
                    })),
                  })}
                </Field>

                <Field name="fiscalRegime">
                  {Select({
                    label: t("company.fiscalRegime"),
                    items: staticData.fiscalRegimes?.map(item => ({
                      value: item.id,
                      name: item.name,
                    })),
                  })}
                </Field>
              </Box>
            )}

            {company?.constitution == CONSTITUTION.EMPRESA && (
              <Field name="cnaes">
                {({ value, onChange, error }) => (
                  <LakeLabel
                    label={t("company.cnae")}
                    style={styles.multiselect}
                    render={() => (
                      <MultiSelect
                        items={
                          staticData.cnaes?.map(item => ({
                            value: item.id,
                            label: item.name.slice(0, 80),
                            group: t("company.cnae"),
                          })) ?? []
                        }
                        values={value ?? []}
                        onValueChange={onChange}
                        error={error}
                      />
                    )}
                  />
                )}
              </Field>
            )}

            <Field name="ivaRegime">
              {Select({
                label: t("company.ivaRegime"),
                items: staticData.ivaRegimes?.map(item => ({
                  value: item.id,
                  name: item.name,
                })),
              })}
            </Field>
          </Box>
        )}

        {activeTabId === "adminsAndReps" && (
          <Box style={styles.gap}>
            <Box style={styles.admins}>
              <Text style={styles.title}>{t("company.admins")}</Text>

              <Field name="admins">
                {({ value, error }) => (
                  <>
                    {value?.map((item: CompanyAdmin) => (
                      <Admin
                        key={item.id}
                        value={item}
                        adminTypes={staticData.adminTypes}
                        onChange={item => {
                          const { value } = getFieldState("admins");
                          setFieldValue(
                            "admins",
                            value?.map((companyAdmin: CompanyAdmin) =>
                              companyAdmin.id === item.id
                                ? { ...companyAdmin, ...item }
                                : companyAdmin,
                            ),
                          );
                        }}
                        onDelete={() => {
                          const { value } = getFieldState("admins");
                          setFieldValue(
                            "admins",
                            value?.filter(
                              (companyAdmin: CompanyAdmin) => companyAdmin.id !== item.id,
                            ),
                          );
                        }}
                      />
                    ))}

                    {error != null && <Text style={styles.red}>{error}</Text>}
                  </>
                )}
              </Field>

              <Box alignItems="end">
                <Button
                  onPress={() => {
                    const { value } = getFieldState("admins");
                    setFieldValue("admins", [
                      ...(value as CompanyAdmin[]),
                      { id: crypto.randomUUID() },
                    ]);
                  }}
                  mode="secondary"
                  icon={<FaPlus />}
                  reverse={true}
                >
                  {t("company.addAdmin")}
                </Button>
              </Box>
            </Box>

            <Box style={styles.admins}>
              <Text style={styles.title}>{t("company.representatives")}</Text>

              <Field name="representatives">
                {({ value, error }) => (
                  <>
                    {value?.map((item: CompanyRepresentative) => (
                      <Representative
                        key={item.id}
                        value={item}
                        onChange={item => {
                          const { value } = getFieldState("representatives");
                          setFieldValue(
                            "representatives",
                            value?.map((companyRepresentative: CompanyRepresentative) =>
                              companyRepresentative.id === item.id
                                ? { ...companyRepresentative, ...item }
                                : companyRepresentative,
                            ),
                          );
                        }}
                        onDelete={() => {
                          const { value } = getFieldState("representatives");
                          setFieldValue(
                            "representatives",
                            value?.filter(
                              (companyRepresentative: CompanyRepresentative) =>
                                companyRepresentative.id !== item.id,
                            ),
                          );
                        }}
                      />
                    ))}

                    {error != null && <Text style={styles.red}>{error}</Text>}
                  </>
                )}
              </Field>

              <Box alignItems="end">
                <Button
                  onPress={() => {
                    const { value } = getFieldState("representatives");
                    setFieldValue("representatives", [
                      ...(value as CompanyRepresentative[]),
                      { id: crypto.randomUUID() },
                    ]);
                  }}
                  mode="secondary"
                  icon={<FaPlus />}
                  reverse={true}
                >
                  {t("company.addRepresentative")}
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        <Space height={16} />

        <Box direction="row" justifyContent="end">
          <Button onPress={onClick}>{t("common.save")}</Button>
        </Box>

        <Space height={16} />
      </View>
    </>
  );
};
