import { UploadShowLogoCompany } from "@components/UploadShowLogoCompany";
import { Button, ButtonGroup } from "@components/forms/Button";
import { DatePicker } from "@components/forms/DatePicker";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import {
  CalculateInvoiceDocument,
  ConceptType,
  CreateRectifiedInvoiceDocument,
  CustomerRelayEdge,
  CustomersDocument,
  GetRectifiedCurrentSeriesDocument,
  InvoiceTotals,
  SalesConceptInput,
  SalesInvoiceCreateStaticDocument,
  SalesRectificationInvoiceInitialData,
  SalesRectificationInvoiceInitialDataInput,
  UpdateRectifiedInvoiceDocument,
} from "@graphql/crm";
import { AsyncData, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useDebounce } from "@swan-io/lake/src/hooks/useDebounce";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { printIbanFormat, validateIban } from "@swan-io/shared-business/src/utils/validation";
import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { common } from "../../../styles/common";
import { backgroundColorVariants, fontColorVariants } from "../../../styles/constants";
import { encodeDate, encodeDateISO, getToday } from "../../../utils/date";
import { locale, t } from "../../../utils/i18n";
import { SaveIcon } from "../../../utils/icons";
import { Router } from "../../../utils/routes";
import {
  extractQueryData,
  handlerErrors,
  tapError,
  validateHasItems,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { CustomerCreate } from "../../contacts/components/CustomerCreate";
import { useInvoiceHistory } from "../hooks/useInvoiceHistory";
import { Concept, CreateRectificationInvoiceState } from "../types";
import { extractSalesStatic } from "../utils";
import { ExpirationDate } from "./ExpirationDate";
import { InvoiceTotal } from "./InvoiceTotal";
import { RectificationConceptItem } from "./RectificationConceptItem";
import { RectificationSuplidoConceptItem } from "./RectificationSuplidoConceptItem";
import { Tabs } from "./Tabs";

const styles = StyleSheet.create({
  contain: {
    backgroundColor: backgroundColorVariants.white,
    paddingHorizontal: 32,
    paddingVertical: 56,
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.10)",
    width: "96dvw",
    maxWidth: 900,
    marginBottom: 16,
  },
  title: {
    fontSize: 26,
    fontWeight: "500",
    marginBottom: 24,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 24,
  },
  columnMin: {
    width: 300,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
    minWidth: 190,
  },
  gap: {
    gap: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  uploadArea: {
    "--spacing-32": 16,
    display: "flex",
    gap: 24,
  },
  actions: {
    flexDirection: "column",
    alignItems: "stretch",
  },
});

type Props = {
  invoice?: SalesRectificationInvoiceInitialData;
  onRefreshRequest?: () => void;
};

export const RectificationEditor = ({ invoice, onRefreshRequest }: Props) => {
  const getNewConcept = () => ({
    id: crypto.randomUUID(),
    description: "",
    price: 0,
    expenseType: "corriente",
    type: "normal",
    vatId: "",
    recargoPercentageId: "",
    irpfId: "",
  });

  const conceptForm = () => {
    if (invoice === undefined) {
      return [getNewConcept()];
    }
    const concepts: Concept[] = [];
    invoice?.concepts?.forEach(concept => {
      concepts.push({
        id: concept.id ?? "",
        productId:
          concept.product?.pk !== undefined
            ? Number(concept.product?.pk)
            : concept.description !== ""
              ? -1
              : undefined,
        description: concept.description ?? "",
        unitType: concept.unitType ?? "",
        unitPrice: concept.unitPrice as string,
        price: concept.total as number,
        vatId: isNotNullish(concept.vatId) ? String(concept.vatId) : "",
        quantity: concept.quantity,
        irpfId: isNotNullish(concept.irpfId) ? String(concept.irpfId) : "",
        discountAmount: concept.discountAmount as number,
        discountPercentage: concept.discountPercentage as number,
        recargoPercentageId: isNotNullish(concept.recargoPercentageId)
          ? String(concept.recargoPercentageId)
          : "",
        recargoAmount: concept.recargoAmount as number,
        expenseType: concept.expenseType ?? "corriente",
        type: concept.type,
        subtotal: concept.subtotal as string,
        category: concept.category?.toString() ?? undefined,
        subcategory: concept.subcategory?.toString() ?? "",
        subsubcategory: concept.subcategory?.toString() ?? "",
        propertySituation: concept.propertySituation ?? undefined,
        cadastralReference: concept.cadastralReference ?? undefined,
      });
    });
    return concepts;
  };

  const { Field, setFieldValue, getFieldState, submitForm, listenFields } =
    useForm<CreateRectificationInvoiceState>({
      customerId: {
        initialValue: invoice?.customerId !== undefined ? Number(invoice?.customerId) : undefined,
        validate: validateNumericNullableRequired,
      },
      issueDate: {
        initialValue: invoice?.issueDate != null ? encodeDateISO(invoice?.issueDate) : getToday(),
        validate: validateRequired,
      },
      expirationDate: {
        initialValue: invoice?.expirationDate != null ? encodeDateISO(invoice?.expirationDate) : "",
        validate: validateRequired,
        strategy: "onSubmit",
      },
      concepts: { initialValue: conceptForm(), validate: validateHasItems },
      paymentMethod: { initialValue: invoice?.paymentMethod ?? "" },
      iban: { initialValue: invoice?.iban ?? "", validate: validateIban },
      notes: { initialValue: invoice?.notes ?? "" },
      rectificationReason: {
        initialValue: invoice?.rectificationReason ?? "",
        validate: validateRequired,
      },
      typeCode: { initialValue: invoice?.typeCode ?? "", validate: validateRequired },
    });

  const [, calculateInvoice] = useUrqlMutation(CalculateInvoiceDocument);

  const [paymentMethodType, setPaymentMethodType] = useState("");
  const [totals, setTotals] = useState<InvoiceTotals>();

  const callCalculateInvoice = () => {
    const { value } = getFieldState("concepts");

    calculateInvoice({
      input: {
        lineItems: value.map(concept => ({
          description: concept.description,
          quantity: concept.quantity ?? 1,
          unitPrice: concept.unitPrice === "" ? undefined : concept.unitPrice,
          subtotal: concept.subtotal === "" ? undefined : concept.subtotal,
          discountPercentage: concept.discountPercentage,
          discountAmount: concept.discountAmount,
          vatRate: isNotNullish(concept.vatId) ? String(concept.vatId) : null,
          irpfPercentage: isNotNullish(concept.irpfId) ? String(concept.irpfId) : null,
          total: concept.price,
          type: concept.type.toUpperCase() as ConceptType,
          recargoPercentage: isNotNullishOrEmpty(concept.recargoPercentageId)
            ? String(concept.recargoPercentageId)
            : null,
        })),
      },
    }).mapOk(data => {
      const lineItems = extractQueryData(data.calculateInvoice, "lineItems") as Concept[];

      let isCalculated = false;
      value.forEach((concept, index) => {
        const lineItem = lineItems[index];
        if (lineItem) {
          concept.total = lineItem.total as string;
          concept.discountAmount = lineItem.discountAmount;
          concept.irpfAmount = lineItem.irpfAmount as string;
          concept.vatAmount = lineItem.vatAmount as string;
          concept.taxBase = lineItem.taxBase as string;
          concept.price = Number(lineItem.total);
          concept.type = lineItem.type.toLowerCase() as ConceptType;

          if (concept.unitPrice != lineItem.unitPrice) {
            concept.unitPrice = lineItem.unitPrice as string;
            isCalculated = true;
          }
          if (concept.subtotal != lineItem.subtotal) {
            concept.subtotal = lineItem.subtotal as string;
            isCalculated = true;
          }
        }
      });
      if (isCalculated) {
        setFieldValue("concepts", [...value]);
      }

      const totals = extractQueryData(data.calculateInvoice, "totals") as InvoiceTotals;
      setTotals({
        ...totals,
        totalIrpf: -(totals.totalIrpf as number),
      });
    });
  };

  const refresh = useDebounce<void>(() => callCalculateInvoice(), 500);

  useEffect(() => {
    const removeResultsListener = listenFields(["concepts"], () => refresh());

    return () => removeResultsListener();
  }, [listenFields, refresh]);

  const [showCustomerCreate, setShowCustomerCreate] = useState(false);

  const { data: customersQuery, reload: reloadCustomers } = useUrqlQuery(
    { query: CustomersDocument, variables: { first: 100, filters: {} } },
    [],
  );
  const customers = extractQueryData(
    customersQuery,
    "value.value.customers.edges",
  ) as CustomerRelayEdge[];

  const { data } = useUrqlQuery({ query: SalesInvoiceCreateStaticDocument }, []);

  match(data).with(AsyncData.P.Done(Result.P.Error(P.select())), tapError);

  const paymentMethods = extractSalesStatic(data, "paymentMethods");
  const expirationDate = extractSalesStatic(data, "expirationDate");
  const statusType = extractSalesStatic(data, "statusType");
  const typeCodes = extractSalesStatic(data, "typeCodes");

  const [, createRectifiedInvoice] = useUrqlMutation(CreateRectifiedInvoiceDocument);
  const [, updateInvoice] = useUrqlMutation(UpdateRectifiedInvoiceDocument);

  const history = useInvoiceHistory({ salesInvoiceId: invoice?.id as string });

  const logoUrl = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(result => result.salesInvoiceCreateStatic.logoUrl)
    .getWithDefault("");

  const { data: serieData } = useUrqlQuery({ query: GetRectifiedCurrentSeriesDocument }, []);

  const currentRectifiedSeries = extractQueryData(
    serieData,
    "value.value.getRectifiedCurrentSeries.series",
  ) as string;

  useEffect(() => {
    callCalculateInvoice();
  }, []);

  const handleSubmit = () => {
    submitForm(values => {
      const input = {
        concepts: values?.concepts?.map(concept => ({
          description: concept.description,
          productId: concept.productId !== -1 ? concept.productId : undefined,
          quantity: concept.quantity ?? 1,
          unitPrice: concept.unitPrice === "" ? undefined : Number(concept.unitPrice),
          vatId: concept.vatId,
          vatAmount: Number(concept.vatAmount),
          irpfId: concept.irpfId,
          irpfAmount: Number(concept.irpfAmount),
          expenseType: concept.expenseType,
          type: concept.type,
          subtotal: Number(concept.subtotal),
          price: Number(concept.price),
          total: Number(concept.price),
          taxBase: Number(concept.taxBase),
          recargoAmount: Number(concept.recargoAmount),
          recargoPercentageId: concept.recargoPercentageId ?? "",
          id: concept.id,
          subsubcategory: concept.subsubcategory,
        })) as SalesConceptInput[],
        id: Number(invoice?.id) ?? "",
        rectifiedInvoiceId: Number(invoice?.rectifiedInvoiceId),
        customerId: values.customerId as number,
        issueDate: values.issueDate !== undefined ? encodeDate(values.issueDate) : "",
        expirationDate:
          values.expirationDate !== undefined ? encodeDate(values.expirationDate) : "",
        totalVat: Number(totals?.totalVat) ?? 0,
        totalTaxBase: Number(totals?.totalTaxBase) ?? 0,
        totalIrpf: Number(totals?.totalIrpf) ?? 0,
        subtotal: Number(totals?.subtotal) ?? 0,
        total: Number(totals?.total) ?? 0,
        retentionAmount: totals?.retention?.amount,
        rectificationReason: values.rectificationReason ?? "",
        notes: values.notes ?? "",
        typeCode: values.typeCode ?? "",
      } as SalesRectificationInvoiceInitialDataInput;
      if (!Boolean(invoice?.id)) {
        createRectifiedInvoice({ input })
          .mapOk(data => {
            match(data.createRectifiedInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesSalesList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      } else {
        updateInvoice({ input })
          .mapOk(data => {
            match(data.updateRectifiedInvoice)
              .with({ __typename: "OperationInfo" }, handlerErrors)
              .otherwise(() => {
                onRefreshRequest?.();
                Router.push("InvoicesSalesList");
              });
          })
          .tapError(tapError)
          .mapError(tapError);
      }
    });
  };

  return (
    <View>
      <Tabs history={history} status={statusType} />
      <Space height={12} />

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <View style={styles.contain}>
            <View>
              <Box
                direction={large ? "row" : "column"}
                alignItems={large ? "end" : "stretch"}
                justifyContent="spaceBetween"
                style={styles.gap}
              >
                <View style={styles.columnMin}>
                  <View style={styles.uploadArea}>
                    <UploadShowLogoCompany logoUrl={logoUrl} />
                  </View>

                  <Space height={48} />
                  <Text style={styles.title}>{t("invoice")}</Text>

                  <LakeText>
                    {t("invoices.rectificationReason.defaultText", {
                      parentInvoiceSerialNumber: invoice?.seriesCurrent ?? "",
                    })}
                  </LakeText>

                  <Space height={4} />

                  <LakeTextInput
                    style={styles.actions}
                    value={currentRectifiedSeries ?? invoice?.seriesCurrent}
                    disabled={true}
                    hideErrors={true}
                  />

                  <Space height={24} />
                </View>

                <View style={large && styles.column}>
                  <Box
                    direction="row"
                    justifyContent="spaceBetween"
                    style={[styles.gap, common.wrap]}
                  >
                    <View style={styles.column}>
                      <Field name="issueDate">
                        {({ value, onChange, error }) => (
                          <DatePicker
                            format={locale.dateFormat}
                            firstWeekDay={locale.firstWeekday}
                            value={value}
                            onChange={value => {
                              onChange(value);
                              setFieldValue("expirationDate", "");
                            }}
                            label={t("invoices.issueDate") + "*"}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>

                    <View style={styles.column}>
                      <Field name="expirationDate">
                        {({ value, onChange, error }) => (
                          <ExpirationDate
                            label={t("invoices.dueDate") + "*"}
                            items={expirationDate}
                            forceCalendar={invoice?.expirationDate != null}
                            issueDate={getFieldState("issueDate").value}
                            value={value}
                            onChange={onChange}
                            error={error}
                          />
                        )}
                      </Field>
                    </View>
                  </Box>

                  <Text style={styles.subtitle}>{t("invoices.clientData")}</Text>

                  <Field name="customerId">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("invoices.customer")}
                        render={() => (
                          <LakeSelect
                            disabled={true}
                            items={
                              customers?.map(item => ({
                                name: item.node.name,
                                value: parseInt(item.node.id as string),
                              })) ?? []
                            }
                            value={value}
                            onValueChange={onChange}
                            hideErrors={error === undefined}
                            error={error}
                          />
                        )}
                        style={styles.actions}
                      />
                    )}
                  </Field>

                  <CustomerCreate
                    visible={showCustomerCreate}
                    onClose={() => {
                      reloadCustomers();
                      setShowCustomerCreate(false);
                    }}
                  />

                  <Space height={24} />
                </View>
              </Box>

              <Space height={32} />

              <Field name="concepts">
                {({ value, error }) => (
                  <Box style={styles.gap}>
                    {value.map(concept =>
                      concept.type === "suplido" ? (
                        <RectificationSuplidoConceptItem
                          key={concept.id}
                          concept={concept}
                          deleteDisabled={value.length === 1}
                          onChange={concept => {
                            const { value } = getFieldState("concepts");
                            setFieldValue(
                              "concepts",
                              value.map(c =>
                                c.id === concept.id ? { ...c, ...concept } : c,
                              ),
                            );
                          }}
                          onDelete={() => {
                              const { value } = getFieldState("concepts");
                              setFieldValue(
                                "concepts",
                                value.filter(c => c.id !== concept.id),
                              );
                          }}
                        />
                      ) : (
                        <RectificationConceptItem
                          key={concept.id}
                          concept={concept}
                          concepts={value}
                          recargo={concept.type === "recargo_equivalencia"}
                          onChange={concept => {
                            const { value } = getFieldState("concepts");
                            setFieldValue(
                              "concepts",
                              value.map(c =>
                                c.id === concept.id ? { ...c, ...concept } : c,
                              ) ,
                            );
                          }}
                          onDelete={() => {
                              const { value } = getFieldState("concepts");
                              setFieldValue(
                                "concepts",
                                value.filter(c => c.id !== concept.id),
                              );
                          }}
                        />
                      ),
                    )}

                    <Text style={styles.red}>{error}</Text>
                  </Box>
                )}
              </Field>

              <Field name="rectificationReason">
                {Input({
                  label: t("invoices.rectificationReason"),
                })}
              </Field>

              <Field name="notes">
                {Input({
                  label: t("invoices.notes"),
                })}
              </Field>

              <Space height={24} />

              <Box
                direction="row"
                justifyContent="spaceBetween"
                alignItems="end"
                style={styles.gap}
              >
                <Box direction="column" style={[styles.column, styles.gap]}>
                  <Field name="typeCode">
                    {Select({
                      label: t("invoice.type"),
                      items: typeCodes.filter(item => item.value.includes("R")),
                    })}
                  </Field>

                  <Field name="paymentMethod">
                    {({ value, onChange, error, ref }) => (
                      <LakeLabel
                        label={t("contact.paymentMethod")}
                        render={id => (
                          <LakeSelect
                            disabled={true}
                            id={id}
                            ref={ref}
                            value={value}
                            hideErrors={error === undefined}
                            error={error}
                            items={paymentMethods}
                            onValueChange={value => {
                              onChange(value);
                              setPaymentMethodType(value);
                            }}
                          />
                        )}
                      />
                    )}
                  </Field>

                  {["transferencia", "domiciliacion"].includes(paymentMethodType) && (
                    <LakeLabel
                      label={t("contact.iban")}
                      render={id => (
                        <Field name="iban">
                          {({ value, onChange, onBlur, error, validating, ref }) => (
                            <LakeTextInput
                              disabled={true}
                              id={id}
                              ref={ref}
                              value={printIbanFormat(value)}
                              validating={validating}
                              hideErrors={error === undefined}
                              error={error}
                              onChangeText={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        </Field>
                      )}
                    />
                  )}
                </Box>

                <InvoiceTotal totals={totals} />
              </Box>
            </View>
          </View>
        )}
      </ResponsiveContainer>

      <Box direction="row" justifyContent="end">
        <Button
          mode="tertiary"
          size="large"
          disabled={false}
          onPress={() => Router.push("InvoicesSalesList", { visible: undefined })}
        >
          {t("common.cancel")}
        </Button>

        <ButtonGroup transparent={false}>
          <Button
            style="group"
            size="large"
            disabled={false}
            icon={<SaveIcon />}
            reverse={true}
            onPress={handleSubmit}
          >
            {t("common.save")}
          </Button>
        </ButtonGroup>
      </Box>
    </View>
  );
};
