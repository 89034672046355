import { ColumnConfig, PlainListView } from "@components/PlainListView";
import { DefaultCell } from "@components/forms/DefaultCell";
import { PriceCell } from "@components/forms/PriceCell";
import { Income } from "@graphql/crm";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactNode } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { common } from "../../../styles/common";
import { fontColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { ColumnHeader } from "../../invoices/components/ColumnHeader";

const styles = StyleSheet.create({
  header: {
    color: fontColorVariants.white,
    paddingRight: 0,
  },
  fixWidth: {
    width: 3300,
  },
});

type Props = {
  data: Income[];
  loading?: { isLoading: boolean; count: number };
  onEndReached: () => void;
  onActiveRowChange: (element: HTMLElement) => void;
  onRefreshRequest: () => void;
  activeRowId?: string;
  renderEmptyList: () => ReactNode;
};
type ExtraInfo = undefined;

export const IncomeList = ({
  data,
  loading,
  onEndReached,
  activeRowId,
  onActiveRowChange,
  renderEmptyList,
}: Props) => {
  const columns: ColumnConfig<Income, ExtraInfo>[] = [
    {
      id: "year",
      width: 70,
      title: t("common.year"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.year} />,
    },
    {
      id: "period",
      width: 70,
      title: t("hr.period"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.period} />,
    },
    {
      id: "codetype",
      width: 70,
      title: t("company.code"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={`${item.code}${item.type}`} />,
    },
    {
      id: "group",
      width: 60,
      title: t("company.group"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.group} />,
    },
    {
      id: "invoiceType",
      width: 80,
      title: t("invoice.type"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} style={styles.header} align="center" />
      ),
      renderCell: ({ item }) => <DefaultCell data={item.invoiceType} align="center" />,
    },
    {
      id: "incomeConcept",
      width: 80,
      title: t("reporting.incomeConcept"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.incomeConcept} />,
    },
    {
      id: "computableIncome",
      width: 80,
      title: t("reporting.computableIncome"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.computableIncome as string} />,
    },
    {
      id: "issueDate",
      width: 120,
      title: t("invoices.issueDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.issueDate} />,
    },
    {
      id: "operationDate",
      width: 120,
      title: t("reporting.operationDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.operationDate} />,
    },
    {
      id: "invoiceSerie",
      width: 120,
      title: t("invoices.serie"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.invoiceSerie} />,
    },
    {
      id: "recipientType",
      width: 80,
      title: t("reporting.recipientType"),
      renderTitle: ({ title }) => (
        <ColumnHeader text={title} style={styles.header} align="center" />
      ),
      renderCell: ({ item }) => <DefaultCell data={item.recipientType} align="center" />,
    },
    {
      id: "recipientCountry",
      width: 80,
      title: t("common.country"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.recipientCountry} />,
    },
    {
      id: "recipientNif",
      width: 100,
      title: t("contact.nif"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.recipientNif} />,
    },
    {
      id: "recipientName",
      width: 80,
      title: t("common.name"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.recipientName} />,
    },
    {
      id: "operationKey",
      width: 80,
      title: t("reporting.operationKey"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.operationKey} align="center" />,
    },
    {
      id: "operationRating",
      width: 130,
      title: t("reporting.operationRating"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.operationRating} />,
    },
    {
      id: "exemptOperation",
      width: 80,
      title: t("reporting.exemptOperation"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.exemptOperation} />,
    },
    {
      id: "totalInvoice",
      width: 120,
      title: t("invoices.totalInvoice"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.totalInvoice as number} align="right" />,
    },
    {
      id: "taxBase",
      width: 120,
      title: t("invoices.taxBase"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.taxBase as number} align="right" />,
    },
    {
      id: "vatRate",
      width: 80,
      title: t("reporting.vatRate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <DefaultCell data={item.vatRate as string} align="right" />,
    },
    {
      id: "vatAmount",
      width: 110,
      title: t("reporting.vatAmount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.vatAmount as number} align="right" />,
    },
    {
      id: "recargoRate",
      width: 120,
      title: t("invoices.recargo"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <DefaultCell data={item.recargoRate as string} align="right" />,
    },
    {
      id: "recargoAmount",
      width: 120,
      title: t("invoices.recargo"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.recargoAmount as number} align="right" />,
    },
    {
      id: "paymentDate",
      width: 120,
      title: t("invoices.paymentDate"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.paymentDate} />,
    },
    {
      id: "paymentAmount",
      width: 80,
      title: t("common.amount"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.paymentAmount as number} align="right" />,
    },
    {
      id: "paymentMethod",
      width: 80,
      title: t("reporting.paymentMethod"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.paymentMethod} align="center" />,
    },
    {
      id: "paymentValue",
      width: 100,
      title: t("contact.iban"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.paymentValue} />,
    },
    {
      id: "irpfRate",
      width: 80,
      title: t("invoices.irpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <DefaultCell data={item.irpfRate as string} align="right" />,
    },
    {
      id: "irpfAmount",
      width: 100,
      title: t("invoices.irpf"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} align="right" />,
      renderCell: ({ item }) => <PriceCell price={item.irpfAmount as number} align="right" />,
    },
    {
      id: "propertySituation",
      width: 150,
      title: t("reporting.propertySituation"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.propertySituation} />,
    },
    {
      id: "cadastralReference",
      width: 150,
      title: t("reporting.cadastralReference"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.cadastralReference} />,
    },
    {
      id: "externalReference",
      width: 150,
      title: t("reporting.externalReference"),
      renderTitle: ({ title }) => <ColumnHeader text={title} style={styles.header} />,
      renderCell: ({ item }) => <DefaultCell data={item.externalReference} />,
    },
  ];

  const smallColumns: ColumnConfig<Income, ExtraInfo>[] = [];

  return (
    <View style={common.scrollX}>
      <View style={styles.fixWidth}>
        <PlainListView
          withoutScroll={true}
          data={data}
          keyExtractor={(_, index) => index.toString()}
          headerHeight={36}
          rowHeight={63}
          headerStyle={styles.header as ViewStyle}
          headerBackgroundColor="#8F8E91"
          groupHeaderHeight={36}
          extraInfo={undefined}
          columns={columns}
          smallColumns={smallColumns}
          activeRowId={activeRowId}
          onActiveRowChange={onActiveRowChange}
          loading={loading}
          onEndReached={onEndReached}
          getRowLink={() => <></>}
          renderEmptyList={renderEmptyList}
          breakpoint={breakpoints.tiny}
        />
      </View>
    </View>
  );
};
