import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import {
  CreateCustomerAddressDocument,
  CustomerAddress,
  OperationInfo,
  UpdateCustomerAddressDocument,
} from "@graphql/crm";
import { Lazy } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlMutation } from "@swan-io/lake/src/hooks/useUrqlMutation";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { CountryCCA3, allCountries } from "@swan-io/shared-business/src/constants/countries";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { match } from "ts-pattern";
import { t } from "../../../utils/i18n";
import { CustomerAddressState } from "../../../utils/types";
import { handlerFieldErrors, tapError, validateRequired } from "../../../utils/validations";

const styles = StyleSheet.create({
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24,
    marginBottom: 16,
  },
  row3: {
    gridTemplateColumns: "1fr .5fr 1fr",
  },
});

const countryItems = Lazy(() => allCountries.filter(cca3 => cca3 !== "USA"));

type Props = {
  customerId: number;
  address?: CustomerAddress;
  onClose: () => void;
};

export const AddressForm = ({ customerId, address, onClose }: Props) => {
  const { Field, setFieldValue, setFieldError, submitForm } = useForm<CustomerAddressState>({
    address: { initialValue: address?.address ?? "", validate: validateRequired },
    locality: { initialValue: address?.locality ?? "", validate: validateRequired },
    postalCode: { initialValue: address?.postalCode ?? "", validate: validateRequired },
    country: {
      initialValue: (address?.country as CountryCCA3) ?? "ESP",
      validate: validateRequired,
    },
    default: { initialValue: address?.default ?? false },
  });

  const [, create] = useUrqlMutation(CreateCustomerAddressDocument);
  const [, update] = useUrqlMutation(UpdateCustomerAddressDocument);

  const onSave = () => {
    submitForm(values => {
      if (address?.id == null) {
        create({
          input: {
            ...values,
            customerId,
            type: address?.type as string,
            country: values.country as string,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.createCustomerAddress)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CustomerAddressState>(
                  data.createCustomerAddress as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                onClose();
              });
          });
      } else {
        update({
          input: {
            id: address?.id.toString(),
            ...values,
            customerId,
            type: address?.type,
            country: values.country as string,
          },
        })
          .tapError(tapError)
          .mapOk(data => {
            match(data.updateCustomerAddress)
              .with({ __typename: "OperationInfo" }, () =>
                handlerFieldErrors<CustomerAddressState>(
                  data.updateCustomerAddress as OperationInfo,
                  setFieldError,
                ),
              )
              .otherwise(() => {
                onClose();
              });
          });
      }
    });
  };

  return (
    <>
      <Field name="address">
        {({ value, onChange, error }) => (
          <LakeLabel
            label={t("contact.address")}
            render={id => (
              <PlacekitAddressSearchInput
                shouldDisplaySuggestions={true}
                id={id}
                apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                country={"ESP"}
                value={value}
                onValueChange={onChange}
                onSuggestion={suggestion => {
                  setFieldValue("address", suggestion.completeAddress);
                }}
                language="es"
                placeholder={t("contact.address.placeHolder")}
                emptyResultText={t("common.empty")}
                error={error}
              />
            )}
          />
        )}
      </Field>

      <Box direction="row" alignItems="start" style={[styles.row, styles.row3]}>
        <Field name="locality">
          {Input({
            label: t("company.locality"),
          })}
        </Field>

        <Field name="postalCode">
          {Input({
            label: t("contact.postalCode"),
            maxLength: 5,
          })}
        </Field>

        <Field name="country">
          {({ value, onChange, ref, error }) => (
            <LakeLabel
              label={t("common.country")}
              render={id => (
                <CountryPicker
                  id={id}
                  ref={ref}
                  value={value}
                  error={error}
                  countries={countryItems.get()}
                  onValueChange={onChange}
                  hideErrors={error == undefined}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Box direction="row" alignItems="center" justifyContent="spaceBetween">
        <Field name="default">
          {({ value, onChange, error }) => (
            <Pressable onPress={() => onChange(!value)}>
              <Box direction="row" alignItems="center">
                <LakeCheckbox value={value} />
                <Space width={8} />
                <Text>{t("company.mainAddress")}</Text>
              </Box>

              {error != null && <Text>{error}</Text>}
            </Pressable>
          )}
        </Field>

        <Box direction="row">
          <Button onPress={onClose} mode="tertiary">
            {t("common.cancel")}
          </Button>

          <Button onPress={onSave}>{t("common.save")}</Button>
        </Box>
      </Box>
    </>
  );
};
