import { DecimalInput } from "@components/DecimalInput";
import { RightPanel } from "@components/RightPanel";
import { Button } from "@components/forms/Button";
import Select from "@components/forms/Select";
import { PurchaseInvoiceCreateStaticDocument } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect, useState } from "react";
import { GestureResponderEvent, Pressable, StyleSheet, Text, View } from "react-native";
import { useForm } from "react-ux-form";
import { useNestedForm } from "../../../components/NestedForm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CloseIcon } from "../../../utils/icons";
import {
  passValidatorTrue,
  validateNullableRequired,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { Concept, CreatePurchaseConceptState, PurchaseConceptState } from "../types";
import { extractPurchaseStatic, extractPurchaseTax } from "../utils";
import { ConceptTotal } from "./ConceptTotal";
import { PurchaseRightPanel } from "./PurchaseRightPanel";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  lineSelected: {
    backgroundColor: backgroundColorVariants.primary100,
    borderWidth: 2,
    borderColor: borderColorVariants.primary500,
    borderRadius: 8,
    padding: 12,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  row: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1.5fr 1.5fr 1.5fr",
    gap: 12,
  },
  row2: {
    gridTemplateColumns: "2fr 2fr 1fr 1.5fr",
  },
  gap: {
    gap: 8,
  },
  options: {
    maxHeight: 0,
    overflow: "hidden",
    transitionDuration: "0.3s",
    transitionProperty: "max-height",
  },
  optionsOpened: {
    maxHeight: 800,
  },
  moreOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});

type Props = {
  large?: boolean;
  concept: PurchaseConceptState;
  concepts: PurchaseConceptState[];
  invoiceType?: string;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
  isActive: boolean;
  setCurrentId: () => void;
  validate: boolean;
};

export const PurchaseConceptItem = ({
  large = true,
  concept,
  concepts,
  onChange,
  onDelete,
  isActive,
  setCurrentId,
  invoiceType,
  validate,
}: Props) => {
  const [moreOptions, setMoreOptions] = useState(false);
  const [open, setOpen] = useState(false);

  const { Field, setFieldValue, getFieldState, listenFields, submitForm } =
    useForm<CreatePurchaseConceptState>({
      id: { initialValue: concept.id },
      description: {
        initialValue: concept.description,
        validate: passValidatorTrue(validateRequired, validate),
      },
      unitType: { initialValue: concept.unitType ?? "" },
      unitPrice: { initialValue: concept.unitPrice ?? "" },
      price: { initialValue: concept.price },
      vatId: {
        initialValue: isNotNullish(concept.vatId) ? String(concept.vatId) : "",
        validate: passValidatorTrue(validateRequired, validate || concept.vatPercentage != null),
      },
      vatPercentage: { initialValue: concept.vatPercentage },
      quantity: {
        initialValue: concept.quantity ?? 1,
        validate: passValidatorTrue(validateNumericNullableRequired, validate),
      },
      irpfId: { initialValue: String(concept.irpfId) ?? "" },
      discountAmount: { initialValue: concept.discountAmount },
      discountPercentage: { initialValue: concept.discountPercentage },
      expenseType: { initialValue: concept.expenseType ?? "" },
      type: { initialValue: concept.type },
      subtotal: { initialValue: concept.subtotal ?? "" },
      category: {
        initialValue: concept?.category,
        validate: passValidatorTrue(validateNullableRequired, validate),
      },
      subcategory: {
        initialValue: concept?.subcategory,
        validate: passValidatorTrue(validateNullableRequired, validate),
      },
      subsubcategory: {
        initialValue: concept?.subsubcategory,
        validate: passValidatorTrue(validateNullableRequired, validate),
      },
      usefulLife: { initialValue: (concept?.usefulLife as number) ?? 0 },
      amortizationRate: { initialValue: (concept?.amortizationRate as number) ?? 0 },
      propertySituation: { initialValue: concept?.propertySituation },
      cadastralReference: { initialValue: concept?.cadastralReference },
    });

  useEffect(() => {
    setFieldValue("subtotal", concept.subtotal ?? "");
    setSubtotalAux(concept.subtotal ?? "");
  }, [concept.subtotal]);

  useEffect(() => {
    setFieldValue("unitPrice", concept.unitPrice ?? "");
    setUnitPriceAux(concept.unitPrice ?? "");
  }, [concept.unitPrice]);

  const { data } = useUrqlQuery({ query: PurchaseInvoiceCreateStaticDocument }, []);

  const irpfType = extractPurchaseTax(data, "irpfType");
  const vatType = extractPurchaseTax(data, "vatType");
  const unitType = extractPurchaseStatic(data, "unitType");
  const discountType = extractPurchaseStatic(data, "discountType");

  const vatTypes = vatType.map(({ id, description }) => ({ name: description, value: id }));
  vatTypes.unshift({ name: "Manual", value: "-1" });

  useEffect(() => {
    const removeListener = listenFields(
      [
        "description",
        "quantity",
        "unitType",
        "vatId",
        "vatPercentage",
        "irpfId",
        "discountAmount",
        "discountPercentage",
        "category",
        "subcategory",
        "subsubcategory",
        "usefulLife",
        "amortizationRate",
      ],
      () => submitForm(values => onChange(values as Concept)),
    );

    return () => removeListener();
  }, [listenFields, onChange, submitForm]);

  const [discountTypeValue, setDiscountTypeValue] = useState("percentage");

  const [unitPriceAux, setUnitPriceAux] = useState<string>(String(concept.unitPrice ?? ""));
  const [subtotalAux, setSubtotalAux] = useState<string>(String(concept.subtotal ?? ""));

  useEffect(() => {
    if (!unitPriceAux && !subtotalAux) {
      return;
    }

    setFieldValue("unitPrice", unitPriceAux);
    setFieldValue("subtotal", subtotalAux);

    submitForm(values => onChange(values as Concept));
  }, [unitPriceAux, subtotalAux]);

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  useEffect(() => {
    submitForm(values => onChange(values as Concept));
  }, [validate]);

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as Concept),
        errors => {
          addError("error");
          if (
            errors.category != undefined ||
            errors.subcategory != undefined ||
            errors.subsubcategory != undefined
          ) {
            setCurrentId();
            setOpen(true);
          }
        },
      );
    });
  }, []);

  const stopPropagation = (event: GestureResponderEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <Pressable
        style={isActive ? styles.lineSelected : styles.line}
        onPress={() => {
          setCurrentId();
          setOpen(true);
        }}
      >
        <Field name="id">{() => null}</Field>
        <Field name="expenseType">{() => null}</Field>
        <Field name="category">{() => null}</Field>
        <Field name="subcategory">{() => null}</Field>
        <Field name="subsubcategory">{() => null}</Field>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={styles.row}
        >
          <Field name="description">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.description")}
                style={styles.column}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <LakeTextInput id={id} value={value} error={error} onChangeText={onChange} />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="quantity">
            {({ value, onChange, error }) => (
              <LakeLabel
                label={t("invoices.quantity")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={String(value)}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setFieldValue("subtotal", "");
                        onChange(Number(nextValue));
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="unitType">
            {Select({
              label: t("invoices.unitType"),
              items: unitType,
              hasEmptyRow: true,
            })}
          </Field>

          <Field name="unitPrice">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.unitPrice")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={unitPriceAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setSubtotalAux("");
                        setUnitPriceAux(nextValue);
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>

          <Field name="subtotal">
            {({ error }) => (
              <LakeLabel
                label={t("invoices.price")}
                style={styles.column}
                extra={() => <Text style={styles.red}>*</Text>}
                render={id => (
                  <Pressable onPress={stopPropagation}>
                    <DecimalInput
                      id={id}
                      value={subtotalAux}
                      error={error}
                      onChangeDecimal={nextValue => {
                        setUnitPriceAux("");
                        setSubtotalAux(nextValue);
                      }}
                    />
                  </Pressable>
                )}
              />
            )}
          </Field>
        </Box>

        <Box
          direction={large ? "row" : "column"}
          alignItems={large ? "start" : "stretch"}
          justifyContent="spaceBetween"
          style={[styles.row, styles.row2]}
        >
          <Field name="vatId">
            {props => {
              if (props.value != "-1" && getFieldState("vatPercentage").value == null) {
                return Select({
                  label: t("invoices.iva"),
                  items: vatTypes,
                  required: true,
                })(props);
              }
              return <></>;
            }}
          </Field>

          <Field name="vatPercentage">
            {({ value, onChange, error }) => {
              if (value != null || getFieldState("vatId").value === "-1") {
                return (
                  <LakeLabel
                    label={t("invoices.vat")}
                    style={styles.column}
                    render={id => (
                      <Pressable onPress={stopPropagation}>
                        <DecimalInput
                          id={id}
                          value={value?.toString()}
                          hideErrors={error === undefined}
                          error={error}
                          onChangeDecimal={value => {
                            isNaN(Number(value)) ? onChange(undefined) : onChange(Number(value));
                          }}
                        />
                      </Pressable>
                    )}
                  />
                );
              }
              return <></>;
            }}
          </Field>

          <Field name="irpfId">
            {Select({
              label: t("invoices.irpf"),
              items: irpfType.map(({ id, description }) => ({ name: description, value: id })),
              hasEmptyRow: true,
            })}
          </Field>

          {discountTypeValue === "amount" && (
            <Field name="discountAmount">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value?.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          isNaN(Number(value)) ? onChange(undefined) : onChange(Number(value));
                        }}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          {discountTypeValue === "percentage" && (
            <Field name="discountPercentage">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("invoices.discount")}
                  style={styles.column}
                  render={id => (
                    <Pressable onPress={stopPropagation}>
                      <DecimalInput
                        id={id}
                        value={value?.toString()}
                        hideErrors={error === undefined}
                        error={error}
                        onChangeDecimal={value => {
                          isNaN(Number(value)) ? onChange(undefined) : onChange(Number(value));
                        }}
                      />
                    </Pressable>
                  )}
                />
              )}
            </Field>
          )}

          <LakeLabel
            label={t("invoices.discountType")}
            render={() => (
              <LakeSelect
                items={discountType}
                value={discountTypeValue}
                onValueChange={value => {
                  setFieldValue("discountAmount", undefined);
                  setFieldValue("discountPercentage", undefined);
                  setDiscountTypeValue(value);
                }}
                hideErrors={true}
              />
            )}
          />
        </Box>

        <Box style={[styles.options, moreOptions && styles.optionsOpened]}>
          <Space height={24} />

          <Box style={styles.moreOptions}>
            <View></View>

            <View>
              <ConceptTotal concept={concept as Concept} />
            </View>
          </Box>
        </Box>

        <Space height={24} />

        <Box direction="row" justifyContent="end" style={styles.gap}>
          <Button
            onPress={() => setMoreOptions(!moreOptions)}
            mode="tertiary"
            icon={<Icon name={moreOptions ? "arrow-up-regular" : "arrow-down-regular"} size={16} />}
            reverse={true}
          >
            {t("invoices.viewTotal")}
          </Button>

          <Button
            onPress={onDelete}
            mode="tertiary"
            disabled={concepts.length === 1}
            icon={<Icon name="delete-regular" size={16} />}
            reverse={true}
          >
            {t("invoices.removeItem")}
          </Button>
        </Box>
      </Pressable>

      <RightPanel visible={isActive && open} onPressClose={() => setOpen(false)}>
        <View style={styles.rightpanel}>
          <Pressable onPress={() => setOpen(false)}>
            <CloseIcon />
          </Pressable>

          <PurchaseRightPanel
            invoiceType={invoiceType}
            form={{
              Field,
              setFieldValue,
              getFieldState,
              listenFields,
              onChange: () => submitForm(values => onChange(values as Concept)),
            }}
          />
        </View>
      </RightPanel>
    </>
  );
};
