import { Calendar } from "@components/Calendar";
import { Heading } from "@components/Heading";
import { Button } from "@components/forms/Button";
import { InputRounded } from "@components/forms/Input";
import { IncomeExpensesDocument, IncomeExpensesOutput } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useState } from "react";
import { FaArrowDown } from "react-icons/fa6";
import { StyleSheet, View } from "react-native";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { backgroundColorVariants } from "../../../styles/constants";
import { formatDate } from "../../../utils/date";
import { t } from "../../../utils/i18n";
import { COLORS } from "../../../values/colors";
import { useExport } from "../../invoices/hooks/useExport";
import { ExpensesList } from "../components/ExpensesList";
import { IncomeList } from "../components/IncomeList";
import { NonCurrentExpensesList } from "../components/NonCurrentExpenses";

const styles = StyleSheet.create({
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  heading: {
    zIndex: 1,
  },
  filters: {
    gap: 16,
  },
  container: {
    backgroundColor: backgroundColorVariants.white,
    padding: 8,
  },
  tabs: {
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
});

const NUM_TO_RENDER = 5;

export const IncomeExpenses = () => {
  const { from, to } = useDateFilter();

  const { data, nextData, reload } = useUrqlPaginatedQuery(
    {
      query: IncomeExpensesDocument,
      variables: {
        filters: {
          dateFrom: from,
          dateTo: to,
        },
      },
    },
    [from, to],
  );

  const incomeExpenses = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(row => Option.fromNullable(row.incomeExpenses))
    .getWithDefault({} as IncomeExpensesOutput);

  const [activeTabId, setActiveTabId] = useState("income");

  const { handleExportInvoices } = useExport();

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween" style={styles.header}>
        <InputRounded>
          <LakeSearchField
            placeholder={t("common.search")}
            initialValue={""}
            onChangeText={() => {}}
          />
        </InputRounded>

        <Box direction="row" style={styles.filters}>
          <Calendar />

          <Box direction="row" justifyContent="end" alignItems="center">
            <Button
              icon={<FaArrowDown size={16} />}
              reverse={true}
              mode="tertiary"
              style="outlined"
              onPress={() => {
                handleExportInvoices({
                  dateFrom: formatDate(from, "YYYY-MM-DD"),
                  dateTo: formatDate(to, "YYYY-MM-DD"),
                });
              }}
            >
              {t("common.export")}
            </Button>
          </Box>
        </Box>
      </Box>

      <ResponsiveContainer breakpoint={breakpoints.medium}>
        {({ large }) => (
          <Box
            direction={large ? "row" : "column"}
            alignItems="center"
            justifyContent="spaceBetween"
            style={styles.heading}
          >
            <Heading title={t("reports.incomeExpenses")} />

            <View>
              <Space height={12} />
            </View>
          </Box>
        )}
      </ResponsiveContainer>

      <View style={styles.container}>
        <View style={styles.tabs}>
          <TabView
            tabs={[
              {
                id: "income",
                label: t("common.income"),
              },
              {
                id: "expenses",
                label: t("common.expenses"),
              },
              {
                id: "nonCurrent",
                label: t("common.investmentAssets"),
              },
            ]}
            otherLabel=""
            activeTabId={activeTabId}
            onChange={setActiveTabId}
          />
        </View>

        {activeTabId === "income" && (
          <IncomeList
            data={incomeExpenses.income ?? []}
            onActiveRowChange={() => {}}
            onRefreshRequest={reload}
            onEndReached={() => {}}
            loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER * 2 }}
            renderEmptyList={() => (
              <FixedListViewEmpty
                icon="lake-transfer"
                borderedIcon={true}
                title={t("common.empty")}
              />
            )}
          />
        )}

        {activeTabId === "expenses" && (
          <ExpensesList
            data={incomeExpenses.expenses ?? []}
            onActiveRowChange={() => {}}
            onRefreshRequest={reload}
            onEndReached={() => {}}
            loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER * 2 }}
            renderEmptyList={() => (
              <FixedListViewEmpty
                icon="lake-transfer"
                borderedIcon={true}
                title={t("common.empty")}
              />
            )}
          />
        )}

        {activeTabId === "nonCurrent" && (
          <NonCurrentExpensesList
            data={incomeExpenses.nonCurrent ?? []}
            onActiveRowChange={() => {}}
            onRefreshRequest={reload}
            onEndReached={() => {}}
            loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER * 2 }}
            renderEmptyList={() => (
              <FixedListViewEmpty
                icon="lake-transfer"
                borderedIcon={true}
                title={t("common.empty")}
              />
            )}
          />
        )}
      </View>
    </>
  );
};
