import { Calendar } from "@components/Calendar";
import { ErrorView } from "@components/ErrorView";
import { Heading } from "@components/Heading";
import { InputRounded } from "@components/forms/Input";
import { TicketRelay, TicketsDocument } from "@graphql/crm";
import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "@swan-io/lake/src/components/FixedListView";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useUrqlPaginatedQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useCallback, useMemo, useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { toSnake } from "ts-case-convert";
import { match } from "ts-pattern";
import { useDateFilter } from "../../../hooks/useDateFilter";
import { ManageParamsOauthSwan } from "../../../utils/auth";
import { t } from "../../../utils/i18n";
import { Router } from "../../../utils/routes";
import { COLORS } from "../../../values/colors";
import { TicketCreate } from "../components/TicketCreate";
import { DetailsList } from "../components/TicketDetailsList";
import { TicketFiltersState } from "../components/TicketListFilter";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  header: {
    position: "relative",
    zIndex: 1,
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.WHITE,
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: 8,
  },
  calendar: {
    right: 0,
  },
});

const NUM_TO_RENDER = 7;

export const TicketsList = () => {
  ManageParamsOauthSwan("InvoicesTicketList");

  const { from, to } = useDateFilter();

  const route = Router.useRoute(["InvoicesTicketList"]);
  const params = match(route)
    .with({ name: "InvoicesTicketList" }, ({ params }) => params)
    .otherwise(() => {});

  const filters: TicketFiltersState = useMemo(() => {
    return {
      search: params?.search,
      status: params?.status,
    } as TicketFiltersState;
  }, [params]);

  const sort = useMemo(() => {
    return {
      field: params?.sortBy ?? "issue_date",
      direction: params?.sortOrder ?? "-",
    };
  }, [params?.sortBy, params?.sortOrder]);

  const { data, nextData, reload, setAfter } = useUrqlPaginatedQuery(
    {
      query: TicketsDocument,
      variables: {
        first: NUM_TO_RENDER,
        filters: {
          search: params?.search,
          issueDate: { gte: from, lte: to },
        },
        orderBy: `${sort.direction}${sort.field}`,
      },
    },
    [filters, from, to, sort],
  );

  const [activeInvoiceId, setActiveInvoiceId] = useState<string | null>(null);
  const [ticket, setTicket] = useState<TicketRelay | undefined>(undefined);

  const onActiveRowChange = useCallback(() => {}, []);

  const tickets = data
    .toOption()
    .flatMap(data => data.toOption())
    .flatMap(({ tickets }) => Option.fromNullable(tickets))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const onViewDetails = useCallback(
    (currentId: string) => {
      setTicket(tickets.filter(({ id }) => id === currentId)?.[0] as TicketRelay);
      Router.push("InvoicesTicketList", { ...filters, visible: "1" });
    },
    [filters, tickets],
  );

  return (
    <View style={styles.root}>
      <View style={commonStyles.fill} role="main">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          style={styles.header}
        >
          <InputRounded>
            <LakeSearchField
              placeholder={t("common.search")}
              initialValue={""}
              onChangeText={search =>
                Router.push("InvoicesTicketList", {
                  ...filters,
                  search,
                })
              }
            />
          </InputRounded>

          <Box direction="row" justifyContent="end" alignItems="center">
            <Calendar style={styles.calendar} />
          </Box>
        </Box>

        <Heading title={t("invoices.tickets")} />

        <Box style={styles.container}>
          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <DetailsList
                    tickets={tickets as TicketRelay[]}
                    onActiveRowChange={onActiveRowChange}
                    activeRowId={activeInvoiceId ?? undefined}
                    onRefreshRequest={reload}
                    onEndReached={() => {
                      if (data?.tickets.pageInfo.hasNextPage === true) {
                        setAfter(data?.tickets?.pageInfo.endCursor ?? undefined);
                      }
                    }}
                    loading={{ isLoading: nextData.isLoading(), count: NUM_TO_RENDER }}
                    getRowLink={({ item }) => (
                      <Pressable
                        onPress={() => {
                          setActiveInvoiceId(item.id as string);
                          onViewDetails(item.id as string);
                        }}
                      />
                    )}
                    renderEmptyList={() => (
                      <FixedListViewEmpty
                        icon="lake-transfer"
                        borderedIcon={true}
                        title={t("common.empty")}
                      />
                    )}
                    sortBy={`${sort.direction}${sort.field}`}
                    setSort={columnId => {
                      const sortBy = toSnake(columnId);
                      const sortOrder = sort.field === sortBy && sort.direction === "-" ? "" : "-";
                      Router.push("InvoicesTicketList", {
                        ...filters,
                        sortBy,
                        sortOrder,
                      });
                    }}
                  />
                ),
              }),
          })}
        </Box>
      </View>

      <TicketCreate
        visible={params?.visible !== undefined}
        ticket={ticket}
        onRefreshRequest={reload}
      />
    </View>
  );
};
