import { Box } from "@swan-io/lake/src/components/Box";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { ChartOptions } from "chart.js";
import Chart, { ChartDataset } from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { common } from "../../styles/common";
import { backgroundColorVariants, fontColorVariants } from "../../styles/constants";
import { formatCurrency } from "../../utils/i18n";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    minHeight: 230,
    gap: 24,
    overflow: "hidden",
  },
  containerMobile: {
    flexDirection: "column",
    height: "auto",
    minHeight: 230,
  },
  chart: {
    alignItems: "center",
    position: "relative",
    maxHeight: 230,
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: -1,
  },
  total: {
    color: fontColorVariants.white,
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  white: {
    color: fontColorVariants.white,
  },
  legend: {
    height: "8px",
    width: "100%",
    minWidth: 200,
    backgroundColor: backgroundColorVariants.gray700,
    borderRadius: 4,
    overflow: "hidden",
  },
  bar: {
    flex: 1,
    gap: 16,
  },
  progressBar: {
    width: "100%",
    gap: 6,
  },
  gap: {
    gap: 6,
  },
});

type Props = {
  labels: string[];
  datasets: ChartDataset[];
  totalTitle: string;
  total: number;
};

export const Doughnut = ({ labels, datasets = [], totalTitle, total }: Props) => {
  const { desktop } = useResponsive(breakpoints.medium);

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current == null) {
      return;
    }

    const chart = new Chart(chartRef.current, {
      type: "doughnut",
      data: {
        labels,
        datasets: [
          {
            data: datasets?.[0]?.data ?? [],
            backgroundColor: ["#FDE68A", "#FBBF24", "#F59E0B", "#FEF3C7"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        cutout: "75%",
        plugins: {
          legend: {
            display: false,
          },
        },
      } as ChartOptions,
    });
    setChart(chart);
  }, []);

  const backgroundColors = ["#FDE68A", "#FBBF24", "#F59E0B", "#FEF3C7"];

  useEffect(() => {
    if (chart != null) {
      chart.data = {
        labels,
        datasets: [
          {
            data: datasets?.[0]?.data ?? [],
            backgroundColor: backgroundColors,
            borderWidth: 0,
          },
        ],
      };
      chart.update();
    }
  }, [datasets]);

  const legend = labels.map((label, index) => ({
    label,
    percentage: (datasets?.[0]?.data[index] as number) ?? 0,
    color: backgroundColors[index] ?? "#000",
  }));

  return (
    <Box
      direction="row"
      alignItems="center"
      style={[styles.container, !desktop && styles.containerMobile]}
    >
      <View style={styles.chart}>
        <canvas ref={chartRef} />

        {total > 0 && (
          <Box alignItems="center" style={styles.center}>
            <Text style={styles.white}>{totalTitle}</Text>
            <Text style={styles.total}>{formatCurrency(total, "EUR")}</Text>
          </Box>
        )}
      </View>

      <Box style={styles.bar}>
        {legend.map((item, index) => (
          <ProgressBar
            key={index}
            label={item.label}
            percentage={item.percentage}
            color={item.color}
          />
        ))}
      </Box>
    </Box>
  );
};

type ProgressBarProps = {
  label: string;
  percentage: number;
  color: string;
};

const ProgressBar = ({ label, percentage, color }: ProgressBarProps) => {
  return (
    <Box style={styles.progressBar}>
      <Box direction="row" alignItems="center" justifyContent="spaceBetween" style={styles.gap}>
        <Text style={[styles.white, common.truncate]}>{label}</Text>
        <Text style={styles.white}>{percentage}%</Text>
      </Box>

      <View style={styles.legend}>
        <Box
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            height: "100%",
            width: `${percentage}%`,
            backgroundColor: color,
          }}
        />
      </View>
    </Box>
  );
};
