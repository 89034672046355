import { DecimalInput } from "@components/DecimalInput";
import { Button } from "@components/forms/Button";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect } from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { useForm } from "react-ux-form";
import { useNestedForm } from "../../../components/NestedForm";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import {
  passValidatorTrue,
  validateNumericNullableRequired,
  validateRequired,
} from "../../../utils/validations";
import { Concept, CreateSalesSuplidoConceptState } from "../types";

const styles = StyleSheet.create({
  line: {
    backgroundColor: backgroundColorVariants.neutral50,
    borderWidth: 1,
    borderColor: borderColorVariants.neutral200,
    borderRadius: 8,
    padding: 12,
  },
  lineSelected: {
    backgroundColor: backgroundColorVariants.primary100,
    borderWidth: 2,
    borderColor: borderColorVariants.primary500,
    borderRadius: 8,
    padding: 12,
  },
  red: {
    color: fontColorVariants.destructive500,
  },
  column: {
    flexBasis: "calc(50% - 12px)",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    gap: 12,
  },
  gap: {
    gap: 8,
  },
});

type Props = {
  large?: boolean;
  concept: Concept;
  deleteDisabled: boolean;
  onChange: (concept: Concept) => void;
  onDelete: () => void;
  disabled?: boolean;
  isActive: boolean;
  setCurrentId: () => void;
  validate: boolean;
};

export const SalesSuplidoConceptItem = ({
  large = true,
  concept,
  deleteDisabled,
  onChange,
  onDelete,
  disabled,
  isActive,
  setCurrentId,
  validate,
}: Props) => {
  const { Field, listenFields, submitForm } = useForm<CreateSalesSuplidoConceptState>({
    id: { initialValue: concept.id },
    description: {
      initialValue: concept.description,
      validate: passValidatorTrue(validateRequired, validate),
    },
    price: {
      initialValue: concept.price,
      validate: passValidatorTrue(validateNumericNullableRequired, validate),
    },
  });

  useEffect(() => {
    const removeListener = listenFields(["description", "price"], values => {
      const data = {
        id: concept.id,
        description: values.description.value,
        price: values.price.value,
      };
      onChange(data as unknown as Concept);
    });
    return () => {
      removeListener();
    };
  }, [listenFields, onChange]);

  useEffect(() => {
    submitForm(values => onChange(values as Concept));
  }, [validate]);
  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as Concept),
        () => addError("error"),
      );
    });
  }, []);

  return (
    <Pressable style={isActive ? styles.lineSelected : styles.line} onPress={() => setCurrentId()}>
      <Field name="id">{() => null}</Field>

      <Box
        direction={large ? "row" : "column"}
        alignItems={large ? "start" : "stretch"}
        justifyContent="spaceBetween"
        style={styles.grid}
      >
        <Field name="description">
          {({ value, onChange, error }) => {
            return (
              <LakeLabel
                label={t("invoices.descriptionSuplido")}
                style={styles.column}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    error={error}
                    onChangeText={onChange}
                    disabled={disabled}
                  />
                )}
              />
            );
          }}
        </Field>

        <Field name="price">
          {({ value, onChange, error }) => (
            <LakeLabel
              label={t("invoices.price")}
              style={styles.column}
              extra={() => <Text style={styles.red}>*</Text>}
              render={id => (
                <DecimalInput
                  id={id}
                  value={value.toString()}
                  error={error}
                  onChangeDecimal={nextValue => {
                    onChange(Number(nextValue));
                  }}
                  disabled={disabled}
                />
              )}
            />
          )}
        </Field>
      </Box>

      <Space height={24} />

      <Box direction="row" justifyContent="end" style={styles.gap}>
        <Button
          onPress={onDelete}
          mode="tertiary"
          disabled={deleteDisabled}
          icon={<Icon name="delete-regular" size={16} />}
          reverse={true}
        >
          {t("invoices.removeItem")}
        </Button>
      </Box>
    </Pressable>
  );
};
