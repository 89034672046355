import { CompanyStaticDocument, Static } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { FixedListViewEmpty } from "@swan-io/lake/src/components/FixedListView";
import { Space } from "@swan-io/lake/src/components/Space";
import { useUrqlQuery } from "@swan-io/lake/src/hooks/useUrqlQuery";
import { useMemo } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Heading } from "../../../components/Heading";
import { RightPanel } from "../../../components/RightPanel";
import {
  backgroundColorVariants,
  borderColorVariants,
  fontColorVariants,
} from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { CloseIcon } from "../../../utils/icons";
import { Router } from "../../../utils/routes";
import { extractQueryData } from "../../../utils/validations";
import { useUser } from "../../context/UserContext";
import { PaymentMethodsForm } from "../components/PaymentMethodsForm";
import { PaymentMethodsList } from "../components/PaymentMethodsList";

const styles = StyleSheet.create({
  header: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
  },
  container: {
    backgroundColor: backgroundColorVariants.white,
    padding: 8,
  },
  rightpanel: {
    paddingTop: 40,
    paddingLeft: 56,
    paddingRight: 27,
    width: 620,
    maxWidth: "100vw",
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    color: fontColorVariants.label,
    paddingLeft: 24,
  },
});

export const PaymentMethods = () => {
  const route = Router.useRoute(["CompanyPaymentMethods"]);
  const { id } = route?.params ?? {};

  const { company, reloadCompany } = useUser();

  const { data: staticData } = useUrqlQuery({ query: CompanyStaticDocument }, []);

  const paymentMethods = extractQueryData(
    staticData,
    "value.value.companyStatic.paymentMethods",
  ) as Static[];

  const paymentMethod = company?.paymentMethods?.find(a => a.id.toString() === id);

  const paymentMethodsActives = useMemo(() => {
    return company?.paymentMethods?.filter(a => !a.archived) ?? [];
  }, [company]);

  const paymentMethodsArchived = useMemo(() => {
    return company?.paymentMethods?.filter(a => a.archived) ?? [];
  }, [company]);

  return (
    <>
      <Box direction="row" alignItems="center" justifyContent="end" style={styles.header}></Box>
      <Heading title={t("common.paymentMethods")} />

      <View style={styles.container}>
        <PaymentMethodsList
          data={paymentMethodsActives}
          onActiveRowChange={() => null}
          activeRowId={undefined}
          onRefreshRequest={reloadCompany}
          onEndReached={() => null}
          getRowLink={({ item }) => (
            <Pressable
              onPress={() => {
                Router.push("CompanyPaymentMethods", { id: item.id.toString() });
              }}
            />
          )}
          renderEmptyList={() => (
            <FixedListViewEmpty
              icon="lake-transfer"
              borderedIcon={true}
              title={t("common.empty")}
            />
          )}
          paymentMethods={paymentMethods}
        />
      </View>

      {paymentMethodsArchived.length > 0 && (
        <>
          <Space height={24} />
          <Text style={styles.title}>{t("common.archived")}</Text>
          <Space height={24} />

          <View style={styles.container}>
            <PaymentMethodsList
              data={paymentMethodsArchived}
              onActiveRowChange={() => null}
              activeRowId={undefined}
              onRefreshRequest={reloadCompany}
              onEndReached={() => null}
              getRowLink={({ item }) => (
                <Pressable
                  onPress={() => {
                    Router.push("CompanyPaymentMethods", { id: item.id.toString() });
                  }}
                />
              )}
              renderEmptyList={() => null}
              paymentMethods={paymentMethods}
            />
          </View>
        </>
      )}

      <RightPanel
        visible={id != null}
        overlay={true}
        onPressClose={() => Router.push("CompanyPaymentMethods")}
        style={styles.rightpanel}
      >
        <View style={styles.rightpanel}>
          <Pressable onPress={() => Router.push("CompanyPaymentMethods")}>
            <CloseIcon />
          </Pressable>

          <Space height={24} />

          <PaymentMethodsForm
            data={paymentMethod}
            onCallback={() => Router.push("CompanyPaymentMethods")}
          />
        </View>
      </RightPanel>
    </>
  );
};
