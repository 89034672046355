import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useModal } from "../features/context/ModalContext";

export const Modal = () => {
  const { props, setModal } = useModal();

  return (
    <LakeModal onPressClose={() => setModal({ ...props, visible: false })} {...props}>
      {props.children}
    </LakeModal>
  );
};
