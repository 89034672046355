import { createGroup, createRouter } from "@swan-io/chicane";

const routes = {
  DesignSystemPage: "/design-system",
  SigninPage: "/signin?:sessionExpired",
  SignupPage: "/signup",
  RecoveryPage: "/recovery",
  ChangePassword: "/change-password/:uid/:token",
  ...createGroup("Activate", "/activate", {
    Area: "/*",
    Root: "/",
    Email: "/:uid/:token",
  }),

  PlansPage: "/plans",
  OnboardingPage: "/onboarding",
  ProjectRootRedirect: "/?:to&:source",

  ...createGroup("Summary", "/home", {
    Area: "/*",
    Root: "/",
  }),

  ...createGroup("User", "/user", {
    Area: "/*",
    Root: "/",
    Edit: "/edit",
    Subscription: "/subscription",
    ChangePassword: "/change-password",
  }),

  ...createGroup("Company", "/company", {
    Area: "/*",
    Root: "/",
    Edit: "/edit",
    Addresses: "/addresses/?:id",
    PaymentMethods: "/payment-methods/?:id",
  }),

  ...createGroup("Crm", "/crm", {
    Area: "/*",
    Root: "/",
    ...createGroup("Contacts", "/contacts", {
      Area: "/*",
      List: "/?:search&:new&:import&:type[]",
      Upload: "/upload",
      Review: "/review",
      Import: "/import",
    }),
  }),

  ...createGroup("Invoices", "/invoices", {
    Area: "/*",
    Root: "/",
    List: "/?:search&:create&:status[]&:sendStatus[]&:category[]&:visible",
    ...createGroup("Sales", "/sales", {
      Area: "/*",
      Root: "/",
      List: "/?:search&:create&:status[]&:sendStatus[]&:category[]&:visible&:isRectified[]&:typeCode[]&:code&:state&:sortBy&:sortOrder&:previousId",
      Create: "/create",
      Rectify: "/rectify?:invoiceId",
      RectifyDetail: "/rectify/:rectifiedId",
      ...createGroup("Simplified", "/simplified", {
        Create: "/create",
      }),
      ...createGroup("Proforma", "/proforma", {
        Create: "/create",
        Detail: "/:proformaId",
      }),
      ...createGroup("Quote", "/quote", {
        Create: "/create",
        Detail: "/:quoteId",
      }),
      ...createGroup("Delivery", "/delivery", {
        Create: "/create/?:quoteId",
        Detail: "/:deliveryId",
      }),
    }),
    ...createGroup("Documents", "/documents", {
      Area: "/*",
      Root: "/",
      List: "/?:search&:create&:status[]&:sendStatus[]&:category[]&:visible&:isRectified[]&:code&:state&:sortBy&:sortOrder&:previousId",
    }),
    ...createGroup("Purchase", "/purchase", {
      Area: "/*",
      Root: "/",
      List: "/?:search&:create&:status[]&:category[]&:visible&:typeCode[]&:code&:state&:sortBy&:sortOrder",
      Create: "/create",
      ...createGroup("Simplified", "/simplified", {
        Create: "/create",
      }),
    }),
    ...createGroup("Ticket", "/ticket", {
      Create: "/create",
      List: "/?:search&:status[]&:visible&:code&:state&:sortBy&:sortOrder",
    }),
    ...createGroup("Products", "/products", {
      Area: "/*",
      Root: "/",
      List: "/?:search&:create&:unitType[]&:irpf[]&:vat[]",
    }),
  }),

  ...createGroup("Reports", "/reports", {
    Area: "/*",
    Root: "/",
    ...createGroup("ProfitAndLoss", "/profitandloss", {
      Area: "/*",
      Root: "/",
    }),
    ...createGroup("IncomeExpenses", "/income-expenses", {
      Area: "/*",
      List: "/",
    }),
  }),

  ...createGroup("Accounting", "/accounting", {
    Area: "/*",
    Root: "/",
    ...createGroup("Ledger", "/ledger", {
      List: "/?:search&:type[]&:import",
      Upload: "/upload",
      Review: "/review",
      Import: "/import",
    }),
  }),

  ...createGroup("Hr", "/hr", {
    Area: "/*",
    Root: "/",
    ...createGroup("Employees", "/employees", {
      Area: "/*",
      Root: "/?:search&:create&:import&:startDateFrom&:startDateTo&:endDateFrom&:endDateTo&:category[]&:contractType[]",
    }),
    ...createGroup("Payrolls", "/payrolls", {
      Area: "/*",
      Root: "/",
      List: "/?:search&:isBeforeEmitedAt&:isAfterEmitedAt&:status[]&:code&:state",
      Create: "/:employeeId/create",
      Update: "/:payrollId",
    }),
  }),

  ...createGroup("Projects", "/projects", {
    Area: "/*",
    Root: "/?:search&:create",
  }),
} as const;

export type RouteName = keyof typeof routes;
export const oauthSwanRoutes = [
  "HrPayrollsList",
  "InvoicesPurchaseList",
  "InvoicesSalesList",
  "InvoicesDocumentsList",
  "InvoicesTicketList",
] as const;

export const Router = createRouter(routes);
