import { useNestedForm } from "@components/NestedForm";
import { Button } from "@components/forms/Button";
import Input from "@components/forms/Input";
import Select from "@components/forms/Select";
import { CompanyAdmin, Static } from "@graphql/crm";
import { Box } from "@swan-io/lake/src/components/Box";
import { Space } from "@swan-io/lake/src/components/Space";
import { useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { StyleSheet } from "react-native";
import { useForm } from "react-ux-form";
import { borderColorVariants } from "../../../styles/constants";
import { t } from "../../../utils/i18n";
import { validateRequired } from "../../../utils/validations";

const styles = StyleSheet.create({
  row: {
    borderBottomWidth: 1,
    borderBottomColor: borderColorVariants.white,
    paddingBottom: 8,
  },
  gap: {
    display: "grid",
    gridTemplateColumns: "1fr .5fr",
    gap: 24,
  },
});

type Props = {
  value?: CompanyAdmin;
  adminTypes: Static[];
  onChange: (value: CompanyAdmin) => void;
  onDelete: () => void;
};

export const Admin = ({ value, adminTypes, onChange, onDelete }: Props) => {
  const { Field, submitForm, listenFields } = useForm<CompanyAdmin>({
    __typename: { initialValue: "CompanyAdmin" },
    id: { initialValue: value?.id ?? crypto.randomUUID() },
    name: { initialValue: value?.name ?? "", validate: validateRequired },
    nif: { initialValue: value?.nif ?? "", validate: validateRequired },
    type: { initialValue: value?.type ?? "", validate: validateRequired },
  });

  const { addForm, addError } = useNestedForm();

  useEffect(() => {
    addForm(() => {
      submitForm(
        values => onChange(values as CompanyAdmin),
        () => {
          addError("error");
        },
      );
    });

    const removeListener = listenFields(["name", "nif", "type"], () =>
      submitForm(values => onChange(values as CompanyAdmin)),
    );

    return () => removeListener();
  }, []);

  return (
    <Box style={styles.row}>
      <Field name="id">{() => <></>}</Field>

      <Box alignItems="start" style={styles.gap}>
        <Field name="name">
          {Input({
            label: t("company.fullNameCompanyName"),
          })}
        </Field>

        <Field name="nif">
          {Input({
            label: t("contact.nif"),
          })}
        </Field>
      </Box>

      <Space height={12} />

      <Field name="type">
        {Select({
          label: t("hr.type"),
          items: adminTypes?.map(item => ({
            value: item.id,
            name: item.name,
          })),
        })}
      </Field>

      <Box alignItems="end">
        <Space height={8} />

        <Button onPress={onDelete} mode="tertiary" icon={<FaRegTrashCan />} reverse={true}>
          {t("common.delete")}
        </Button>
      </Box>
    </Box>
  );
};
